import { LinkButton } from '@bswing/uhc-core';

const LearnAboutPlansSection = (props) => {
  return (
    <div className="bg-gray-2 py-60 py-lg-100">
      <div className="container container-lg">
        <div className="row mb-60 mb-lg-80">
          <div className="column column-12 text-center">
            <div className="heading-1 mb-60">
              Learn about plans from UnitedHealthcare
            </div>
            <img
              src="/assets/images/content-medicare-101-state-default-background-2@2x.png"
              alt=""
              style={{ width: 450, height: 'auto', maxWidth: '100%' }}
            />
          </div>
        </div>
        <div className="row mb-0 mb-lg-30">
          <div className="column column-12 column-lg-6">
            <div className="d-flex align-items-start mb-30 mb-lg-0">
              <img
                src="/assets/icons/full-brand-icon-library-plans-icon-medicare-c-1-c@2x.png"
                alt=""
                style={{ width: 48, height: 48 }}
              />
              <div className="ml-20">
                <LinkButton className="text-large mb-10">
                  <span className="d-flex align-items-center gap-10">
                    <span>Medicare Advantage Plans</span>
                    <img
                      src="/assets/icons/full-icon-library-all-24-px-navigation-ic-arrow-forward@2x.png"
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                  </span>
                </LinkButton>
                <p>
                  Most Medicare Advantage plans combine Medicare Part A, Part B
                  and Part D coverage into one plan. With these plans, members
                  can take advantage of additional benefits, like hearing,
                  fitness, and more.
                </p>
              </div>
            </div>
          </div>
          <div className="column column-12 column-lg-6">
            <div className="d-flex align-items-start mb-30 mb-lg-0">
              <img
                src="/assets/icons/full-brand-icon-library-plans-icon-med-supp-1-c@2x.png"
                alt=""
                style={{ width: 48, height: 48 }}
              />
              <div className="ml-20">
                <LinkButton className="text-large mb-10">
                  <span className="d-flex align-items-center gap-10">
                    <span>Medicare Supplement Insurance Plans</span>
                    <img
                      src="/assets/icons/full-icon-library-all-24-px-navigation-ic-arrow-forward@2x.png"
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                  </span>
                </LinkButton>
                <p>
                  Also known as Medigap, these plans help pay some of the
                  out-of-pocket costs not paid by Original Medicare Parts A and
                  B. Medicare Supplement Plans allow you to go to any doctor or
                  hospital that accepts Medicare patients.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column column-12 column-lg-6">
            <div className="d-flex align-items-start mb-30 mb-lg-0">
              <img
                src="/assets/icons/full-brand-icon-library-plans-icon-medicare-d-bot-1-c@2x.png"
                alt=""
                style={{ width: 48, height: 48 }}
              />
              <div className="ml-20">
                <LinkButton className="text-large mb-10">
                  <span className="d-flex align-items-center gap-10">
                    <span>Medicare Prescription Drug Plans</span>
                    <img
                      src="/assets/icons/full-icon-library-all-24-px-navigation-ic-arrow-forward@2x.png"
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                  </span>
                </LinkButton>
                <p>
                  A Part D plan offers prescription drug coverage, which helps
                  pay for some Medicare-covered prescription drugs. This plan
                  may be used along with Original Medicare and with Medicare
                  supplement insurance plans.
                </p>
              </div>
            </div>
          </div>
          <div className="column column-12 column-lg-6">
            <div className="d-flex align-items-start mb-30 mb-lg-0">
              <img
                src="/assets/icons/full-brand-icon-library-plans-icon-medicare-c-1-c@2x.png"
                alt=""
                style={{ width: 48, height: 48 }}
              />
              <div className="ml-20">
                <LinkButton className="text-large mb-10">
                  <span className="d-flex align-items-center gap-10">
                    <span>Medicare Special Needs Plans</span>
                    <img
                      src="/assets/icons/full-icon-library-all-24-px-navigation-ic-arrow-forward@2x.png"
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                  </span>
                </LinkButton>
                <p>
                  Special Needs plans are Medicare Advantage plans that help
                  meet the needs of people who have unique financial or health
                  care needs. These include Dual Special Needs Plans, Chronic
                  Special Needs Plans and Institutional Special Needs Plans.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnAboutPlansSection;
