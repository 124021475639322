import { LinkButton } from '@bswing/uhc-core';
import PrintAndEmail from 'components/print-and-email/print-and-email';
import SPBanner from 'components/sp-banner/sp-banner';
import { Link } from 'react-router-dom';

const ShopperProfileHeader = ({ isAuthenciated = false }) => {
  return (
    <div className="bg-gray-2 pt-20 pt-lg-30">
      <div className="container container-lg">
        <div className="row mb-20">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
              <LinkButton as={Link} to="/plan-summary">
                <span className="d-flex align-items-center gap-10">
                  <img
                    src="/assets/icons/chevron-left-icon@2x.png"
                    alt=""
                    style={{ height: 12, width: 8 }}
                  />
                  <span>Back to all plans</span>
                </span>
              </LinkButton>
              <div className="d-flex flex-column flex-lg-row items-center">
                <div className="d-none d-lg-block">
                  <PrintAndEmail emailLabel="Email" printLabel="Print" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column column-12">
            <div className="mb-n60">
              <SPBanner isAuthenticated={isAuthenciated} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopperProfileHeader;
