import { useState, useEffect, useRef } from 'react';
import FilterButtonDrop from 'components/filter-button/filter-button-drop';
import FilterDrop from 'components/filter-drop/filter-drop';
import { MAHZFilters } from 'components/filter-group/filter-group-data';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, A11y, Navigation } from 'swiper/modules';
import { v4 as uuidv4 } from 'uuid';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'components/carousel/slick.scss';
import './plan-list-filters.scss';

let topMenu = [
  {
    label: 'Plan Type',
    selections: [],
    order: 0,
    isChecked: false
  },
  {
    label: 'Coverage Type',
    selections: [],
    order: 1,
    isChecked: false
  },
  {
    label: 'Special Needs',
    selections: [],
    order: 2,
    isChecked: false
  },
  {
    label: 'Additional Benefits',
    selections: [],
    order: 3,
    isChecked: false
  },
  {
    label: 'Cost Savings',
    selections: [],
    order: 4,
    isChecked: false
  }
];

const dropMenu = [
  {
    label: 'Plan Type',
    order: 1,
    isChecked: false,
    type: 'radio',
    items: [
      {
        label: 'HMO',
        checked: false
      },
      {
        label: 'PPO',
        checked: false
      }
    ]
  },
  {
    label: 'Coverage Type',
    order: 2,
    isChecked: false,
    type: 'radio',
    items: [
      {
        label: 'Medical and prescription',
        checked: false
      },
      {
        label: 'Medical only',
        checked: false
      }
    ]
  },
  {
    label: 'Additional Benefits',
    order: 3,
    isChecked: false,
    type: 'check',
    items: [
      {
        label: 'OTC credit',
        checked: false
      },
      {
        label: 'Comprehensive dental',
        checked: false
      },
      {
        label: 'Eyewear allowance',
        checked: false
      },
      {
        label: 'Hearing aids',
        checked: false
      },
      {
        label: 'Fitness program',
        checked: false
      },
      {
        label: 'Transportation',
        checked: false
      }
    ]
  },
  {
    label: 'Cost Savings',
    order: 4,
    isChecked: false,
    type: 'check',
    items: [
      {
        label: '$0 PCP copay',
        checked: false
      },
      {
        label: '$0 premium',
        checked: false
      }
    ]
  },
  {
    label: 'Special Needs',
    order: 5,
    isChecked: false,
    type: 'radio',
    items: [
      {
        label: 'For people with Medicaid',
        checked: false
      },
      {
        label:
          'For people with diabetes, cardiovascular disorders, or chronic heart failure',
        checked: false
      },
      {
        label: 'For people in a nursing home level of care',
        checked: false
      }
    ]
  }
];

const PlanListFiltersGrouped = (props) => {
  const {
    onDropFilterChange = () => {},
    resetAll = () => {},
    clearAllDropItems = () => {}
  } = props;

  const copyButtons = JSON.parse(JSON.stringify(topMenu));

  const [topButtons, setTopButtons] = useState([...copyButtons]);
  const [selectedNumber, setSelectedNumber] = useState(-1);
  const [currentDrop, setCurrentDrop] = useState('');
  const [dropLocation, setDropLocation] = useState();
  const [swiper, setSwiper] = useState(null);

  const resetFilters = () => {
    setTopButtons(JSON.parse(JSON.stringify(copyButtons)));
    setSelectedNumber(-1);
    resetAll();
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      const target = e.target;
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        currentDrop !== '' &&
        !target.classList.contains('filter-drop') &&
        !target.closest('.filter-drop') &&
        !target.classList.contains('filter-button-drop')
      ) {
        closeDrops();
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [currentDrop]);

  useEffect(() => {
    function handleResize() {
      manageFilters(currentDrop);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [currentDrop]);

  const manageFilters = (event) => {
    if (!event) {
      return;
    }
    const theTarget =
      event.target || document.querySelector(`[data-label="${event}"]`);

    const xLocation = theTarget.offsetParent.offsetLeft;
    const targetLabel = theTarget.dataset.label;
    const rect = theTarget.getBoundingClientRect();
    const browserWidth = window.innerWidth;
    const dropWidth = 271;

    setCurrentDrop(targetLabel);

    if (rect.left + dropWidth >= browserWidth) {
      setDropLocation(swiper.width - dropWidth);
    } else {
      // location of the button + the amount the swiper carousel has scrolled to the left
      setDropLocation(xLocation + swiper.translate);
    }
  };

  const closeDrops = () => {
    setCurrentDrop('');
    //reorderButtons();
  };

  const clearDropButton = (section) => {
    let changedButtons = [...topButtons];
    let changedNumbers = 0;

    changedButtons.forEach((button) => {
      if (button.label === section) {
        button.selections = [];
        button.isChecked = false;
        changedNumbers = changedNumbers + 1;
      }
    });

    setSelectedNumber(selectedNumber - changedNumbers);

    setTopButtons(changedButtons);
  };

  const reorderButtons = () => {
    let changedButtons = [...topButtons];

    changedButtons.forEach((button, index) => {
      if (button.label === currentDrop) {
        console.log(button);
        if (button.selections.length && button.isChecked === false) {
          const pulledButton = changedButtons.splice(index, 1)[0];

          // put object to the right of any currently selected items
          const checkedIndex = changedButtons.findLastIndex(
            (el) => el.isChecked === true
          );

          if (checkedIndex < 0) {
            changedButtons.unshift(pulledButton);
          } else {
            changedButtons.splice(checkedIndex + 1, 0, pulledButton);
          }

          button.isChecked = true;
        }

        //NEED TO ADD SOMETHING HERE FOR WHEN ITEMS ARE REMOVED AND PUTTING BACK TO ORIGINAL POSITION.

        if (!button.selections.length) {
          console.log('sciz');
          const buttonIndex = index;
          //const pulledButton = changedButtons.splice(index, 1)[0];

          button.isChecked = false;

          for (let i = 0; i < changedButtons.length; i++) {
            //console.log(changedButtons[i]);
            //console.log(changedButtons[i].order > button.order);

            if (
              !changedButtons[i].isChecked &&
              changedButtons[i].order > button.order
            ) {
              const pulledButton = changedButtons.splice(buttonIndex, 1)[0];
              //console.log(i);
              changedButtons.splice(i, 0, pulledButton);
              return;
            }
          }
        }
      }
    });

    setTopButtons(changedButtons);
  };

  const handleDropButtonUpdate = (event, section, item, type) => {
    let changedButtons = [...topButtons];

    if (type === 'radio') {
      changedButtons.forEach((button) => {
        if (button.label === section) {
          button.selections = [item];
        }
      });

      setTopButtons(changedButtons);
      setSelectedNumber(0);
      return;
    }

    // add or remove selection from section
    if (event.target.checked) {
      changedButtons.forEach((button) => {
        if (button.label === section) {
          if (!button.isChecked) {
            setSelectedNumber(selectedNumber + 1);
          }

          button.selections.push(item);
        }
      });
    } else {
      changedButtons.forEach((button) => {
        if (button.label === section) {
          button.selections = button.selections.filter(
            (items) => items !== item
          );

          // Uncheck main drop button if there's no more selections in section
          if (button.selections.length === 0) {
            setSelectedNumber(selectedNumber - 1);
          }
        }
      });
    }

    setTopButtons(changedButtons);
  };

  return (
    <fieldset className="filter-button-list">
      <legend className="heading-4 text-blue-primary mb-10">Filter by:</legend>
      <div className="filter-contain">
        <Swiper
          slidesPerView={'auto'}
          modules={[FreeMode, A11y, Navigation]}
          centeredSlides={false}
          spaceBetween={8}
          navigation={true}
          onSwiper={setSwiper}
        >
          {topButtons?.map((menu) => (
            <SwiperSlide key={uuidv4()}>
              <FilterButtonDrop
                key={menu.label}
                label={menu.label}
                checked={menu.isChecked}
                selections={menu.selections}
                activeDrop={currentDrop}
                manageFilters={(event) => manageFilters(event)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {selectedNumber > -1 && (
          <button
            className="filters-clear uhc-link-button"
            onClick={resetFilters}
          >
            Clear all
          </button>
        )}

        {dropMenu?.map((menu) => (
          <FilterDrop
            heading={menu.label}
            type={menu.type}
            items={menu.items}
            key={menu.label}
            activeDrop={currentDrop}
            dropLocation={dropLocation}
            closeDrops={closeDrops}
            clearDropButton={(section) => clearDropButton(section)}
            onDropFilterChange={(value, checked, type) =>
              onDropFilterChange(value, checked, type)
            }
            clearAllDropItems={(clearedItems) =>
              clearAllDropItems(clearedItems)
            }
            handleDropButtonUpdate={(event, section, item, type) =>
              handleDropButtonUpdate(event, section, item, type)
            }
          />
        ))}
      </div>
    </fieldset>
  );
};

export default PlanListFiltersGrouped;
