import { Button, LinkButton, Menu, Tag } from '@bswing/uhc-core';
import UtilityBar from 'components/utility-bar/utility-bar';
import classNames from 'classnames';
import PlanCard from 'components/plan-card/plan-card';
import PrintAndEmail from 'components/print-and-email/print-and-email';
import Tabs from 'components/tabs/tabs';
import tabsData from 'components/tabs/tabs-data.js';
// import UtilityBar from 'components/utility-bar/utility-bar';
import AutoEnrollmentModal from 'partials/auto-enrollment-modal/auto-enrollment-modal';
import HelpSection from 'partials/help-section/help-section';
import MedicareAdvantagePlansSection from 'partials/medicare-advantage-plans-section/medicare-advantage-plans-section';
import PlanRecommendationsSection from 'partials/plan-recommendations-section/plan-recommendations-section';
import { Fragment, useState, useEffect } from 'react';
import Pluralize from 'react-pluralize';
import PlanListFiltersHZ from './plan-list-filters/plan-list-filters-hz';
import PlanListFiltersGrouped from './plan-list-filters/plan-list-filters-grouped';
import PlanListHeader from './plan-list-header/plan-list-header';
import Skeleton from 'components/skeleton/skeleton';
import Sam1 from 'components/sam/sam1.js';
import Sam2 from 'components/sam/sam2.js';
import Sam3 from 'components/sam/sam3.js';

const PlanList = (props) => {
  const {
    availablePlans = [],
    isAuthenticated = false,
    savedDoctors = [],
    savedDrugs = [],
    savedPlans = [],
    showAppliedFilters,
    showPlanToggle,
    showPlanYearDescription,
    version = 'MAPD',
    onAddDoctorsClick = () => {},
    onAddDrugsClick = () => {},
    onRemoveDoctorClick = () => {},
    onRemoveDrugClick = () => {},
    onPlanSaveToggle = () => {},
    isSecondary = true,
    hasFilter = false,
    samType
  } = props;

  const [loading, setLoading] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState(availablePlans);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isAutoEnrollModalShown, setIsAutoEnrollModalShown] = useState(false);
  const [sortItem, setSortItem] = useState('Featured');
  const currentYear = new Date().getFullYear();

  const sortPlans = (e, newPlans = filteredPlans) => {
    const sortItem = e;
    let sortedPlans;

    setLoading(true);

    if (sortItem === 'Lowest out-of-pocket maximum') {
      sortedPlans = newPlans.sort((a, b) => {
        const priceA = a.outOfPocketMax;
        const priceB = b.outOfPocketMax;

        if (priceA < priceB) {
          return -1;
        }

        if (priceA > priceB) {
          return 1;
        }

        return 0;
      });
    }

    if (sortItem === 'Lowest monthly premium') {
      sortedPlans = newPlans.sort((a, b) => {
        const priceA = a.monthlyPremium;
        const priceB = b.monthlyPremium;

        if (priceA < priceB) {
          return -1;
        }

        if (priceA > priceB) {
          return 1;
        }

        return 0;
      });
    }

    if (sortItem === 'Featured') {
      sortedPlans = newPlans.sort((a, b) => {
        const priceA = a.featured;
        const priceB = b.featured;

        if (priceA < priceB) {
          return -1;
        }

        if (priceA > priceB) {
          return 1;
        }

        return 0;
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, '1000');

    setSortItem(e);
    setFilteredPlans(sortedPlans);
  };

  useEffect(() => {
    // run something every time activeFilters changes
    const updatedPlans = availablePlans.filter((plan) => {
      return activeFilters?.every((element) => {
        return plan.filters.includes(element);
      });
    });

    sortPlans(sortItem, updatedPlans);

    // This is just fake to show what the loading might look like if API call took a while
    setTimeout(() => {
      setLoading(false);
    }, '1000');
  }, [activeFilters, availablePlans]);

  const uncheckEverything = () => {
    const allDrops = document.querySelectorAll('.filter-drop__options');

    allDrops.forEach((drop) => {
      const allChecks = drop.querySelectorAll(
        'input[type="checkbox"], input[type="radio"]'
      );

      allChecks.forEach((checkbox) => {
        checkbox.checked = false;
      });
    });
  };

  const resetAll = () => {
    setLoading(true);
    uncheckEverything();
    setActiveFilters([]);
    setFilteredPlans(availablePlans);
  };

  const collectFilters = (event, theValue, isChecked, type) => {
    const filteredValue = event?.target.value || theValue;
    const filteredDirection = event?.target.checked || isChecked;
    let addedFilters = [];

    // This is thrown together as there wasn't time for a thought out system
    if (type === 'radio') {
      if (filteredValue === 'HMO') {
        addedFilters = activeFilters.filter((e) => e !== 'PPO');
      }

      if (filteredValue === 'PPO') {
        addedFilters = activeFilters.filter((e) => e !== 'HMO');
      }

      if (filteredValue === 'Medical and prescription') {
        addedFilters = activeFilters.filter((e) => e !== 'Medical only');
      }

      if (filteredValue === 'Medical only') {
        addedFilters = activeFilters.filter(
          (e) => e !== 'Medical and prescription'
        );
      }

      addedFilters.push(filteredValue);

      setActiveFilters(addedFilters);

      return;
    }

    if (filteredDirection) {
      console.log(`added - ${filteredValue}`);
      addedFilters.push(filteredValue);
      setActiveFilters([...activeFilters, ...addedFilters]);
    } else {
      console.log(`removed - ${filteredValue}`);
      addedFilters = activeFilters.filter((e) => e !== filteredValue);
      setActiveFilters(addedFilters);
    }

    console.log(activeFilters);
  };

  const onFilterChange = (event) => {
    setLoading(true);
    collectFilters(event);
  };

  const onDropFilterChange = (value, checked, type) => {
    setLoading(true);
    collectFilters(null, value, checked, type);
  };

  const clearAllDropItems = (clearedItems) => {
    let updatedFilters = [...activeFilters];

    setLoading(true);

    clearedItems.forEach((element) => {
      updatedFilters = updatedFilters.filter((e) => e !== element);
    });

    setActiveFilters(updatedFilters);
  };

  return (
    <Fragment>
      {samType === 'one' && <Sam1 />}
      {samType === 'two' && <Sam2 />}
      {samType === 'three' && <Sam3 />}
      <div className="container container-lg d-none d-lg-block">
        <div className="row my-20 my-lg-30">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row items-center justify-content-between">
              <div className="d-flex items-center gap-5">
                <img
                  src="/assets/icons/map-pin@2x.png"
                  alt=""
                  style={{ height: 21, width: 21 }}
                />
                <span>
                  Plans for{' '}
                  <LinkButton>
                    <span className="d-flex align-items-center gap-5">
                      <span>77001 Harris County</span>
                      <img
                        src="/assets/icons/pencil-copy@2x.png"
                        alt=""
                        style={{ height: 12, width: 12 }}
                      />
                    </span>
                  </LinkButton>
                </span>
              </div>
              <div className="d-none d-lg-block">
                <PrintAndEmail emailLabel="Email" printLabel="Print" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tabs tabs={tabsData} />
      <PlanListHeader
        showPlanToggle={showPlanToggle}
        showPlanYearDescription={showPlanYearDescription}
      />
      <div className="container container-lg">
        <div className="row">
          <div className="column column-12">
            <div className="heading-1 mb-20">
              Medicare Advantage Plans available for 2024
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="column column-12">
            {hasFilter && (
              <div>
                <PlanListFiltersGrouped
                  onDropFilterChange={onDropFilterChange}
                  clearAllDropItems={clearAllDropItems}
                  resetAll={resetAll}
                />
              </div>
            )}

            {showAppliedFilters && version !== 'PDP' && (
              <div className="mb-20">
                <div className="d-flex align-items-center gap-15">
                  <div className="d-none d-lg-block text-bold">
                    Applied Filters:
                  </div>
                  <Tag isDismissable={true}>
                    Nationwide network + out-of-network access
                  </Tag>
                  <LinkButton className="d-none d-lg-block">
                    Clear All
                  </LinkButton>
                </div>
              </div>
            )}
            <div className="d-flex align-items-center justify-content-between gap-20 gap-lg-30 mb-20">
              <div>
                Showing{' '}
                <span className="text-bold">
                  <Pluralize singular={'plan'} count={filteredPlans.length} />
                </span>
              </div>
              <Menu
                isBorderless={true}
                items={[
                  'Featured',
                  'Lowest monthly premium',
                  'Lowest out-of-pocket maximum'
                ]}
                label={
                  <div>
                    <span className="text-bold">Sort:</span> {sortItem}
                  </div>
                }
                position="right"
                onItemSelect={sortPlans}
              />
            </div>
            <div className="d-flex flex-column gap-30">
              {loading ? (
                <>
                  <Skeleton height="800px" />
                  <Skeleton height="800px" />
                </>
              ) : filteredPlans.length === 0 ? (
                <div
                  style={{
                    padding: '50px 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: 700,
                      marginBottom: 16
                    }}
                  >
                    No matching results
                  </div>
                  <div>Try changing or removing some of your filters.</div>
                </div>
              ) : (
                filteredPlans.map((plan, index) => (
                  <PlanCard
                    id={plan.id}
                    cardNumber={index + 1}
                    cardTotal={filteredPlans.length}
                    key={plan.id}
                    isExpanded={true}
                    isSaved={!!savedPlans.find((p) => p.id === plan.id)}
                    planData={plan}
                    savedDoctors={savedDoctors}
                    savedDrugs={savedDrugs}
                    onAddDoctorsClick={onAddDoctorsClick}
                    onAddDrugsClick={onAddDrugsClick}
                    onLearnClick={() => setIsAutoEnrollModalShown(true)}
                    onRemoveDoctorClick={onRemoveDoctorClick}
                    onRemoveDrugClick={onRemoveDrugClick}
                    onSave={onPlanSaveToggle}
                    samType={samType}
                    showEnroll={plan.showEnroll}
                  />
                ))
              )}
            </div>
            <div className="d-flex justify-content-center d-lg-none py-30">
              <PrintAndEmail emailLabel="Email" printLabel="Print" />
            </div>
          </div>
        </div>
      </div>
      <PlanRecommendationsSection />
      <MedicareAdvantagePlansSection />
      <HelpSection />
      <AutoEnrollmentModal
        isShown={isAutoEnrollModalShown}
        onCloseComplete={() => setIsAutoEnrollModalShown(false)}
      />
    </Fragment>
  );
};

export default PlanList;
