const MSPlanDetailBenefits = (props) => {
  const { data } = props;

  return (
    <div className="container container-lg">
      <div className="row mb-40 mb-lg-60">
        <div className="column column-12 column-lg-6 pt-40">
          <h2 className="heading-2 text-dark">
            Discover why {data.name} may be a good fit
          </h2>
          <div>
            {data.benefits.map((benefit, index) => (
              <div className="d-flex align-items-center mt-30" key={index}>
                <div className="mr-20">
                  <img src={benefit.image} alt="" width="48" height="48" />
                </div>
                <div className="text-lg-large">
                  <div dangerouslySetInnerHTML={{ __html: benefit.content }} />
                </div>
              </div>
            ))}
            <div className="mt-40">
              * From a report prepared for UnitedHealthcare Insurance Company by
              Gongos, Inc., "Substantiation of Advertising Claims concerning
              AARP Medicare Supplement Insurance Plans," July 2021,
              www.uhcmedsupstats.com{' '}
              <a href="#link">
                <img
                  src="/assets/icons/external-blue-icon@2x.png"
                  alt="external icon"
                  width="14"
                  height="14"
                />
              </a>{' '}
              or call 1-877-629-1893 to request a copy of the full report.
            </div>
          </div>
        </div>
        <div className="column column-12 column-lg-6">
          <img
            className="mt-30 mt-lg-n100 width-full"
            src="/assets/images/plan-details-benefits-placeholder@2x.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default MSPlanDetailBenefits;
