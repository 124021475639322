import { Button } from '@bswing/uhc-core';
import { withBreakpoints } from 'react-breakpoints';

const PlanRecommendationsSection = (props) => {
  const { currentBreakpoint } = props;

  return (
    <div className="py-40 py-lg-100">
      <div className="container container-lg">
        <div className="row">
          <div className="column column-12 column-lg-10 column-lg-shift-1">
            <div className="d-flex flex-column-reverse flex-lg-row align-items-center">
              <div className="text-center text-lg-left flex-grow-1">
                <div className="text-large text-bold text-blue-primary mb-10">
                  Your plan recommendations
                </div>
                <div className="heading-2 text-dark mb-30">
                  Answer a few questions and get a personalized list of plans
                </div>
                <div>
                  <Button isOutlined={true} isSecondary={true}>
                    Get Started{' '}
                    <img
                      className="ml-5"
                      src="/assets/icons/chevron-right-darkblue-icon@2x.png"
                      alt=""
                      style={{ width: 8, height: 12 }}
                    />
                  </Button>
                </div>
              </div>
              <div className=" ml-lg-100 mb-20 mb-lg-0 flex-grow-1 flex-shrink-0">
                <img
                  className="d-block height-auto"
                  src="/assets/images/plan-recommendations-illustration@2x.png"
                  style={
                    currentBreakpoint === 'mobile'
                      ? { width: 200, maxWidth: '100%' }
                      : { width: 360, maxWidth: '100%' }
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withBreakpoints(PlanRecommendationsSection);
