const MSHomeImportantInformation = (props) => {
  return (
    <div className="container container-lg py-40 py-lg-60">
      <div className="row">
        <div className="column column-12">
          <div className="border border-thick rounded-xl">
            <div className="uhc-vpp-scroll-pane" tabIndex={0}>
              <div className="d-flex flex-column gap-20 p-30">
                <h2 className="heading-3">Important Information</h2>
                <p id="footnote-1">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">1</sup> From a
                  report prepared for UnitedHealthcare Insurance Company by
                  Gongos, Inc., "Substantiation of Advertising Claims concerning
                  AARP Medicare Supplement Insurance Plans," July 2021,
                  www.uhcmedsupstats.com{' '}
                  <a href="#link">
                    <img
                      src="/assets/icons/external-blue-icon@2x.png"
                      alt="external icon"
                      width="14"
                      height="14"
                    />
                  </a>{' '}
                  or call 1-877-629-1893 to request a copy of the full report.
                </p>
                <p id="footnote-2">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">2</sup> The
                  doctor or specialist must accept Medicare patients.
                </p>
                <p id="footnote-3">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">3</sup> As long
                  as you pay your premium when due and you have made no material
                  misrepresentations when you apply for this plan.
                </p>
                <p id="footnote-4">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">4</sup> These
                  offers are only available to insured members covered under an
                  AARP Medicare Supplement Plan from UnitedHealthcare Insurance
                  Company. These are additional insured member services apart
                  from the AARP Medicare Supplement Plan benefits, are not
                  insurance programs, are subject to geographical availability
                  and may be discontinued at any time. None of these services
                  should be used for emergency or urgent care needs. In an
                  emergency, call 911 or go to the nearest emergency room. Note
                  that certain services are provided by Affiliates of
                  UnitedHealthcare Insurance Company or other third parties not
                  affiliated with UnitedHealthcare.
                </p>
                <p id="footnote-5">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">5</sup> Need
                  citation for TX.
                </p>
                <p id="footnote-6">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">6</sup> Only
                  applicants first eligible for Medicare before 2020 can apply
                  Plans C and F.
                </p>
                <p id="footnote-7">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">7</sup> If you
                  choose to apply outside of your Medicare Supplement Open
                  Enrollment period or a Guaranteed Issue period, you may be
                  underwritten and not accepted into the plan.
                </p>
                <p id="footnote-8">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">8</sup> As long
                  as you pay your premiums when due and you do not make any
                  material misrepresentation when you apply for this plan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSHomeImportantInformation;
