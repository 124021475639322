import React, { Fragment, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import classNames from 'classnames';
import './slide-table.scss';

const SlideTableCell = (props) => {
  const {
    children,
    className,
    index,
    isHCentered,
    isVCentered,
    isFrozen,
    isHeader,
    isMobileHeading,
    offset,
    style,
    ...rest
  } = props;
  const componentClassName = classNames(
    'uhc-slide-table__cell',
    {
      'uhc-slide-table__cell--hcentered': isHCentered,
      'uhc-slide-table__cell--vcentered': isVCentered,
      'uhc-slide-table__cell--frozen': isFrozen,
      'uhc-slide-table__cell--header': isHeader,
      'uhc-slide-table__cell--mobile-heading': isMobileHeading
    },
    className
  );

  const CellComponent = isHeader ? 'th' : 'td';

  const prevOffsetRef = useRef();
  useEffect(() => {
    prevOffsetRef.current = offset;
  });
  const prevOffset = prevOffsetRef.current;
  const direction = prevOffset < offset ? 'left' : 'right';
  const columnNumber = window.innerWidth >= 768 ? 3 : 2;
  const visible =
    offset >= index + 1 || columnNumber + offset <= index ? 'false' : 'true';

  const cellEl = useRef(null);

  return (
    <CellComponent
      className={componentClassName}
      data-direction={direction}
      data-index={index}
      data-visible={visible}
      ref={cellEl}
      style={{
        transform: `translate3d(${
          offset > 0 ? `${offset * -100}%` : '0'
        }, 0, 0)`,
        ...style
      }}
      {...rest}
    >
      {children}
    </CellComponent>
  );
};

const mobileDrop = (e) => {
  const row = e.currentTarget;
  if (row.classList.contains('mobile-row-drop')) {
    row.querySelector('th').classList.toggle('is-active');
  }
};

const SlideTableRow = (props) => {
  const { children, className, offset, isMobileHeading, isHeader, ...rest } =
    props;
  const componentClassName = classNames(
    'uhc-slide-table__row hl-row',
    {
      'mobile-row-drop': isMobileHeading
    },
    className
  );
  const childrenArray = React.Children.toArray(children);
  const cells = childrenArray.map((child, index) => {
    const childProps = child.props.isFrozen ? {} : { offset, index: index - 1 };
    return React.cloneElement(child, childProps);
  });

  return (
    <tr className={componentClassName} {...rest} onClick={mobileDrop}>
      {cells}
    </tr>
  );
};

const SlideTable = (props) => {
  const {
    children,
    className,
    header,
    offset,
    isRounded,
    showNextPreview,
    onSwiped = () => {},
    theadClassName,
    tbodyClassName,
    ...rest
  } = props;
  const containerClassname = classNames('uhc-slide-table__container', {
    'uhc-slide-table__container--rounded': isRounded,
    'uhc-slide-table__container--preview': showNextPreview
  });
  const componentClassName = classNames('uhc-slide-table', {}, className);
  let headerArray, headRows;

  if (header) {
    headerArray = React.Children.toArray(
      header.type === Fragment ? header.props.children : header
    );
    headRows = headerArray.map((child, index) => {
      const childProps = child.props.isFrozen ? {} : { offset };
      return React.cloneElement(child, childProps);
    });
  }

  const childrenArray = React.Children.map(children, (child) =>
    child && child.type === Fragment ? child.props.children : child
  );
  const bodyRows = childrenArray
    ? childrenArray.map((child, index) => {
        const childProps = child.props.isFrozen ? {} : { offset };
        return React.cloneElement(child, childProps);
      })
    : null;

  const swipeHandlers = useSwipeable({
    // onSwiped: onSwiped,
    // delta: 200
  });

  return (
    <div className={containerClassname} {...swipeHandlers}>
      <table className={componentClassName} {...rest}>
        {headRows && <thead className={theadClassName}>{headRows}</thead>}
        <tbody className={tbodyClassName}>{bodyRows}</tbody>
      </table>
    </div>
  );
};

SlideTable.Cell = SlideTableCell;
SlideTable.Row = SlideTableRow;

export default SlideTable;
