import { Button, Checkbox, LinkButton } from '@bswing/uhc-core';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import classNames from 'classnames';
import AutoEnroll from 'components/auto-enroll/auto-enroll';
import FlyoutPanel from 'components/flyout-panel/flyout-panel';
import UtilityTag from 'components/utility-tag/utility-tag';
import useOnClickOutside from 'hooks/useOnClickOutside';
import PropTypes from 'prop-types';
import { Fragment, useRef, useState } from 'react';
import Pluralize from 'react-pluralize';
import { Link, useNavigate } from 'react-router-dom';
import './plan-card.scss';

const PlanCard = (props) => {
  const {
    id,
    version,
    cardNumber,
    cardTotal,
    compareCount,
    className,
    drugCoverageStatus,
    planData,
    isExpandable,
    isExpanded: isDefaultExpanded,
    isSaved,
    providerCoverageStatus,
    savedDoctors,
    savedDrugs,
    showAutoEnroll,
    showCompare,
    showCompareCheckbox,
    showEnroll,
    showFiveStar,
    showRemove,
    showPersonalization,
    onAddDoctorsClick,
    onAddDrugsClick,
    onLearnClick,
    onRemoveDoctorClick,
    onRemoveDrugClick,
    onCompare,
    onRemove,
    onSave,
    samType,
    ...rest
  } = props;

  const doctorsMenuButtonRef = useRef(null);
  const drugsMenuButtonRef = useRef(null);

  const [isDoctorsMenuActive, setIsDoctorsMenuActive] = useState(false);
  const [isDrugsMenuActive, setIsDrugsMenuActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);

  let navigate = useNavigate();

  const componentClassName = classNames(
    'uhc-plan-card',
    {
      'uhc-plan-card--expandable': isExpandable,
      'uhc-plan-card--expanded': isExpanded,
      'uhc-plan-card--personalized': showPersonalization
    },
    className
  );

  const wrapperClassName = classNames(
    'uhc-plan-card__wrapper',
    {
      'uhc-dsnp':
        id.includes('DSNP') || id.includes('CSNP') || id.includes('ISNP')
    },
    className
  );

  const handleMenuAddDoctorsClick = () => {
    setIsDoctorsMenuActive(false);
    onAddDoctorsClick();
  };

  const handleMenuRemoveDoctorClick = (doctorId) => {
    setIsDoctorsMenuActive(false);
    onRemoveDoctorClick(doctorId);
  };

  const handleMenuAddDrugsClick = () => {
    setIsDrugsMenuActive(false);
    onAddDrugsClick();
  };

  const handleMenuRemoveDrugClick = (drugId) => {
    setIsDrugsMenuActive(false);
    onRemoveDrugClick(drugId);
  };

  const handleOnCompareChange = (value, planName) => {
    return (event) => {
      onCompare(event, value, planName);
    };
  };

  const handleOnRemove = (planName) => {
    return (event) => {
      onRemove(event, planName);
    };
  };

  const handleOnSave = (value, plan) => {
    return (event) => {
      onSave(event, value, plan);
    };
  };

  useOnClickOutside(doctorsMenuButtonRef, () => setIsDoctorsMenuActive(false));
  useOnClickOutside(drugsMenuButtonRef, () => setIsDrugsMenuActive(false));

  return (
    <div className={wrapperClassName}>
      <div className={componentClassName} {...rest}>
        <div className="uhc-plan-card__main">
          {id.includes('DSNP') && <div className="uhc-dsnp__header">Medicaid required</div> }
          {id.includes('CSNP') && <div className="uhc-dsnp__header">Chronic condition required</div> }
          {id.includes('ISNP') && <div className="uhc-dsnp__header">Nursing home level of care required</div> }
          <div className="heading-2 text-blue mb-5 mb-lg-20">
            <span className="valign-middle">{planData?.name}</span>
          </div>
          <div className="uhc-plan-card__description mb-20">
            <div
              dangerouslySetInnerHTML={{
                __html: planData?.planCard?.summary
              }}
            />
          </div>
          <div className="d-flex align-items-start gap-40 mb-10 mb-lg-20">
            {planData.monthlyPremium && (
              <div className="flex-1">
                <div>Monthly premium</div>
                <div className="heading-2 text-dark">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: planData.monthlyPremium
                    }}
                  />
                </div>
              </div>
            )}

            {planData.outOfPocketMax && (
              <div className="d-none d-lg-block flex-1">
                <div>
                  {showPersonalization
                    ? 'Over-the-counter credit'
                    : 'Out-of-pocket maximum'}
                </div>
                {!showPersonalization && (
                  <div className="heading-2 text-dark">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: planData.outOfPocketMax
                      }}
                    />
                  </div>
                )}
              </div>
            )}

            {planData?.planCard?.hasEstimatedDrug !== false && (
              <div className="d-none d-lg-block flex-1">
                <div>Estimated annual drug cost</div>
                {savedDrugs.length > 0 && (
                  <div className="heading-2 text-dark">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: planData.annualDrugCost
                      }}
                    />{' '}
                    <LinkButton className="ml-5" href="#link">
                      See drug pricing
                    </LinkButton>
                  </div>
                )}
                {savedDrugs.length === 0 && (
                  <LinkButton
                    className="mt-0 mt-lg-10"
                    onClick={onAddDrugsClick}
                  >
                    <span className="d-flex align-items-center gap-5">
                      <span>
                        <IconSymbol icon="add_box" size="24px" />
                      </span>
                      <span>Add your drugs</span>
                    </span>
                  </LinkButton>
                )}
              </div>
            )}

            <div className="d-none d-lg-block flex-1">
              <div style={{ opacity: 0 }}>Doctors covered</div>
            </div>
            {!showAutoEnroll && (
              <Button className="d-block d-lg-none">Enroll in plan</Button>
            )}
            {showAutoEnroll && (
              <div
                className="d-flex d-lg-none align-items-start gap-10 mb-10"
                style={{ maxWidth: '50%' }}
              >
                <div className="text-bold text-small">
                  You will be automatically enrolled in this plan for 2024.
                </div>
              </div>
            )}
          </div>
          {showFiveStar && (
            <div className="d-block d-lg-none mb-20">
              <div className="d-flex align-items-center gap-10">
                <div className="d-flex gap-5">
                  {[...Array(5)].map((e, i) => (
                    <img
                      src="/assets/icons/star@2x.png"
                      key={i}
                      alt="star"
                      style={{ width: 21, height: 19 }}
                    />
                  ))}
                </div>
                <div className="text-small text-bold text-blue-secondary">
                  CMS Rating
                </div>
              </div>
            </div>
          )}

          <div className="row row-collapse">
            <div className="column column-12 column-lg-7">
              <div className="border border-rounded-sm">
                <table>
                  <tbody>
                    <tr className="d-flex d-lg-none justify-content-between p-10">
                      <td className="p-0 pr-10 border-none">
                        {showPersonalization && (
                          <svg
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            focusable="false"
                            style={{ verticalAlign: 'middle', marginRight: 5 }}
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.49288 2.15015C4.58288 2.15015 1.41663 5.32348 1.41663 9.23348C1.41663 13.1435 4.58288 16.3168 8.49288 16.3168C12.41 16.3168 15.5833 13.1435 15.5833 9.23348C15.5833 5.32348 12.41 2.15015 8.49288 2.15015ZM11.4962 13.4835L8.49996 11.6772L5.50371 13.4835L6.29704 10.0764L3.65496 7.78848L7.13996 7.49098L8.49996 4.27515L9.85996 7.4839L13.345 7.7814L10.7029 10.0693L11.4962 13.4835Z"
                              fill="#187BD0"
                            />
                          </svg>
                        )}
                        {showPersonalization
                          ? 'Over-the-counter benefit per quarter'
                          : 'Out-of-pocket maximum'}
                      </td>
                      <td className="p-0 border-none text-bold">
                        {showPersonalization ? '$100' : '$7,550'}
                      </td>
                    </tr>
                    <tr className="d-flex d-lg-none justify-content-between border-top p-10">
                      <td className="p-0 border-none">
                        Est. Annual Drug Cost{' '}
                        {savedDrugs.length > 0 && (
                          <Fragment>
                            <br />
                            <LinkButton className="mt-5">
                              See drug pricing
                            </LinkButton>
                          </Fragment>
                        )}
                      </td>
                      {savedDrugs.length > 0 && (
                        <td className="p-0 border-none text-bold">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: planData?.annualDrugCostAlt
                            }}
                          />
                        </td>
                      )}
                      {savedDrugs.length === 0 && (
                        <td className="p-0 border-none text-bold">
                          <a href="#link">Add drugs</a>
                        </td>
                      )}
                    </tr>
                    {/* START FROM DATA */}
                    {planData?.planCard?.lineItems &&
                      planData?.planCard?.lineItems.map((lineItem, index) => (
                        <tr
                          className={
                            'd-flex justify-content-between p-10 border-top' +
                            (index === 0 ? ' border-lg-none' : '')
                          }
                          key={index}
                        >
                          <td className="p-0 border-none">{lineItem.label}</td>
                          <td className="p-0 border-none text-bold text-right">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: lineItem.value
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {(planData?.planCard?.hasDoctorsMenu ||
                planData?.planCard?.hasDrugsMenu) && (
                <div className="d-flex flex-column gap-10 mt-20">
                  {planData?.planCard?.hasDoctorsMenu &&
                    savedDoctors.length === 0 && (
                      <UtilityTag as="button" onClick={onAddDoctorsClick}>
                        <span className="d-block mr-5">
                          <IconSymbol icon="add_box" size="24px" />
                        </span>
                        <span>
                          <span className="text-blue-secondary text-bold">
                            Add your doctors & dentists
                          </span>
                        </span>
                      </UtilityTag>
                    )}
                  {planData?.planCard?.hasDoctorsMenu &&
                    savedDoctors.length > 0 && (
                      <div
                        className="position-relative"
                        ref={doctorsMenuButtonRef}
                      >
                        <UtilityTag
                          className="width-100"
                          as="button"
                          onClick={() =>
                            setIsDoctorsMenuActive(!isDoctorsMenuActive)
                          }
                        >
                          {providerCoverageStatus === 'covered' && (
                            <img
                              className="d-block mr-10"
                              src="/assets/icons/check-circle@2x.png"
                              alt=""
                              style={{ height: 20, width: 20 }}
                            />
                          )}
                          {providerCoverageStatus === 'not-covered' && (
                            <img
                              className="d-block mr-10"
                              src="/assets/icons/ic_cancel@2x.png"
                              alt=""
                              style={{ height: 20, width: 20 }}
                            />
                          )}
                          {providerCoverageStatus === 'partial' && (
                            <img
                              className="d-block mr-10"
                              src="/assets/icons/ic_warning@2x.png"
                              alt=""
                              style={{ height: 20, width: 20 }}
                            />
                          )}
                          <span className="d-flex align-items-center justify-content-between flex-1">
                            <span>
                              <span className="text-bold">Your doctors</span>
                              <span className="ml-5">
                                {
                                  savedDoctors.filter((x) => x.isInNetwork)
                                    .length
                                }{' '}
                                of {savedDoctors.length} covered
                              </span>
                            </span>
                            <img
                              className="d-block mr-10"
                              src="/assets/icons/chevron-down-icon@2x.png"
                              alt=""
                              style={{
                                width: 12,
                                height: 8,
                                transform: isDoctorsMenuActive
                                  ? 'scaleY(-1)'
                                  : 'none'
                              }}
                            />
                          </span>
                        </UtilityTag>
                        <FlyoutPanel
                          aria-expanded={isDoctorsMenuActive}
                          aria-hidden={!isDoctorsMenuActive}
                          style={{ marginTop: 4 }}
                        >
                          {savedDoctors.map((doctor, index) => (
                            <div
                              className={`p-15${
                                index % 2 !== 0 ? ' bg-gray-2' : ''
                              }`}
                              key={index}
                            >
                              <div className="d-flex flex-column flex-lg-row align-items-start gap-10 gap-lg-40">
                                <div className="flex">
                                  <div className="text-bold mb-5">
                                    {doctor.name}
                                  </div>
                                  <div>{doctor.address}</div>
                                </div>
                                <div>
                                  {doctor.isInNetwork && (
                                    <img
                                      className="mr-5"
                                      src="/assets/icons/check-icon-alt@2x.png"
                                      alt="In-network"
                                      style={{ width: 17, height: 11 }}
                                    />
                                  )}
                                  {!doctor.isInNetwork && (
                                    <img
                                      className="mr-5"
                                      src="/assets/icons/not-covered-x@2x.png"
                                      alt="In-network"
                                      style={{ width: 17, height: 13 }}
                                    />
                                  )}
                                  <span>
                                    {doctor.isInNetwork
                                      ? 'In-network'
                                      : 'Out-of-network'}
                                  </span>
                                </div>
                              </div>
                              <div className="mt-10">
                                <LinkButton
                                  className="underline text-small"
                                  onClick={() =>
                                    handleMenuRemoveDoctorClick(doctor.id)
                                  }
                                >
                                  Remove
                                </LinkButton>
                              </div>
                            </div>
                          ))}
                          <div className="p-15">
                            <LinkButton
                              className="d-block"
                              onClick={handleMenuAddDoctorsClick}
                            >
                              <span className="d-flex align-items-center">
                                <img
                                  className="d-block mr-10"
                                  src="/assets/icons/circle-add-icon@2x.png"
                                  alt=""
                                  style={{ height: 20, width: 20 }}
                                />
                                <span>Add doctors &amp; dentists</span>
                              </span>
                            </LinkButton>
                          </div>
                        </FlyoutPanel>
                      </div>
                    )}
                  {planData?.planCard?.hasDrugsMenu &&
                    savedDrugs.length === 0 && (
                      <UtilityTag as="button" onClick={onAddDrugsClick}>
                        <span className="d-block mr-5">
                          <IconSymbol icon="add_box" size="24px" />
                        </span>
                        <span>
                          <span className="text-blue-secondary text-bold">
                            Add your drugs
                          </span>
                        </span>
                      </UtilityTag>
                    )}
                  {planData?.planCard?.hasDrugsMenu &&
                    savedDrugs.length > 0 && (
                      <div
                        className="position-relative"
                        ref={drugsMenuButtonRef}
                      >
                        <UtilityTag
                          className="width-100"
                          as="button"
                          onClick={() =>
                            setIsDrugsMenuActive(!isDrugsMenuActive)
                          }
                        >
                          {drugCoverageStatus === 'covered' && (
                            <img
                              className="d-block mr-10"
                              src="/assets/icons/check-circle@2x.png"
                              alt=""
                              style={{ height: 20, width: 20 }}
                            />
                          )}
                          {drugCoverageStatus === 'not-covered' && (
                            <img
                              className="d-block mr-10"
                              src="/assets/icons/ic_cancel@2x.png"
                              alt=""
                              style={{ height: 20, width: 20 }}
                            />
                          )}
                          {drugCoverageStatus === 'partial' && (
                            <img
                              className="d-block mr-10"
                              src="/assets/icons/ic_warning@2x.png"
                              alt=""
                              style={{ height: 20, width: 20 }}
                            />
                          )}
                          <span className="d-flex align-items-center justify-content-between flex-1">
                            <span>
                              <span className="text-bold">
                                <Pluralize
                                  singular={'Drug'}
                                  count={savedDrugs.length}
                                />{' '}
                                added
                              </span>
                              <span className="ml-5">
                                {savedDrugs.filter((x) => x.isCovered).length}{' '}
                                of {savedDrugs.length} covered
                              </span>
                            </span>
                            <img
                              className="d-block mr-10"
                              src="/assets/icons/chevron-down-icon@2x.png"
                              alt=""
                              style={{
                                width: 12,
                                height: 8,
                                transform: isDrugsMenuActive
                                  ? 'scaleY(-1)'
                                  : 'none'
                              }}
                            />
                          </span>
                        </UtilityTag>
                        <FlyoutPanel
                          aria-expanded={isDrugsMenuActive}
                          aria-hidden={!isDrugsMenuActive}
                          style={{ marginTop: 4 }}
                        >
                          {savedDrugs.map((drug, index) => (
                            <div
                              className={`p-15${
                                index % 2 !== 0 ? ' bg-gray-2' : ''
                              }`}
                              key={index}
                            >
                              <div className="d-flex flex-column flex-lg-row align-items-start gap-10 gap-lg-40">
                                <div className="flex">
                                  <div className="text-bold mb-5">
                                    {drug.name}
                                  </div>
                                  <div>{drug.dosage}</div>
                                </div>
                                <div>
                                  {drug.isCovered && (
                                    <img
                                      className="mr-5"
                                      src="/assets/icons/check-icon-alt@2x.png"
                                      alt="Covered"
                                      style={{ width: 17, height: 11 }}
                                    />
                                  )}
                                  {!drug.isCovered && (
                                    <img
                                      className="mr-5"
                                      src="/assets/icons/not-covered-x@2x.png"
                                      alt="Covered"
                                      style={{ width: 17, height: 13 }}
                                    />
                                  )}
                                  <span>
                                    {drug.isCovered ? 'Covered' : 'Not covered'}
                                  </span>
                                </div>
                              </div>
                              <div className="mt-10">
                                <LinkButton
                                  className="underline text-small"
                                  onClick={() =>
                                    handleMenuRemoveDrugClick(drug.id)
                                  }
                                >
                                  Remove
                                </LinkButton>
                              </div>
                            </div>
                          ))}
                          <div className="p-15">
                            <LinkButton
                              className="d-block"
                              onClick={handleMenuAddDrugsClick}
                            >
                              View drug summary
                            </LinkButton>
                          </div>
                        </FlyoutPanel>
                      </div>
                    )}
                </div>
              )}
            </div>
            <div className="column column-12 column-lg-5 mt-20 mt-lg-0">
              <div className="text-bold mb-20">Additional Benefits</div>
              <ul className="m-0 p-0">
                {planData?.planCard?.benefits &&
                  planData?.planCard?.benefits.map((benefit, index) => (
                    <li className="d-flex align-items-start mb-15" key={index}>
                      <div className="mr-10">
                        <img
                          src="/assets/icons/check-icon-alt@2x.png"
                          alt=""
                          style={{ width: 17, height: 11 }}
                        />
                      </div>
                      <div>{benefit}</div>
                    </li>
                  ))}
              </ul>
              {/* <div className="mt-30">
                Optional rider:{' '}
                <span className="text-bold">Dental Platinum</span>
                <br />
                (add $40 to your monthly premium){' '}
                <a href="#link">
                  <img
                    src="/assets/icons/info-icon@2x.png"
                    alt="info icon"
                    style={{ width: 24, height: 24, verticalAlign: 'middle' }}
                  />
                  <span className="visually-hidden">Rider info</span>
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="uhc-plan-card__footer">
          <div className="uhc-plan-card__footer-inner">
            {isExpandable && (
              <button
                className="uhc-plan-card__expand-button"
                onClick={() => setIsExpanded(!isExpanded)}
                aria-hidden={true}
                tabIndex={-1}
              >
                {isExpanded ? 'Show less' : 'Show more'}
                <img
                  className="ml-10"
                  src="/assets/icons/chevron-down-icon@2x.png"
                  alt=""
                  style={{
                    width: 12,
                    height: 8,
                    transform: isExpanded ? 'scaleY(-1)' : 'none'
                  }}
                />
              </button>
            )}

            {/* START BOTTOM ROW - NORMAL */}
            {!showAutoEnroll && (
              <div className="d-flex flex-column flex-lg-row align-items-center">
                {showEnroll && (
                  <Button className="d-none d-lg-block width-100 width-lg-auto mb-20 mb-lg-0 mr-lg-20">
                    Enroll in plan
                  </Button>
                )}
                <div className="d-flex flex-column flex-lg-row align-items-lg-center width-100">
                  <div className="d-flex flex-row-reverse flex-lg-row align-items-center mb-20 mb-lg-0 width-100">
                    {/* to={`/details/${planData?.id}`} - removed below */}
                    <LinkButton as={Link} to="">
                      <span className="d-flex align-items-center gap-5">
                        View plan details
                        <img
                          src="/assets/icons/chevron-right-icon@2x.png"
                          alt=""
                          style={{ width: 8, height: 12 }}
                        />
                      </span>
                    </LinkButton>
                    <span className="flex"></span>
                    <Button
                      className="mr-20"
                      isOutlined={true}
                      isSecondary={true}
                      onClick={handleOnSave(!isSaved, planData)}
                    >
                      <span className="d-flex align-items-center">
                        {isSaved && (
                          <>
                            <img
                              className="d-block mr-10 ml-n5"
                              src="/assets/icons/heart-icon-small-filled@2x.png"
                              alt=""
                              style={{
                                height: 16,
                                width: 16
                              }}
                            />
                            <span>Saved</span>
                          </>
                        )}
                        {!isSaved && (
                          <>
                            <img
                              className="d-block mr-10 ml-n5"
                              src="/assets/icons/heart-icon-small@2x.png"
                              alt=""
                              style={{
                                height: 16,
                                width: 16
                              }}
                            />
                            <span>Save</span>
                          </>
                        )}
                      </span>
                    </Button>
                  </div>
                  <div className="d-flex align-items-center no-wrap">
                    {showCompareCheckbox && (
                      <Checkbox
                        checked={showCompare}
                        onClick={() => {
                          //navigate('/plan-compare/');
                        }}
                        label={
                          showCompare ? (
                            <span className="visually-hidden">
                              Add to compare
                            </span>
                          ) : (
                            'Add to compare'
                          )
                        }
                        onChange={handleOnCompareChange(
                          !showCompare,
                          planData?.name
                        )}
                      />
                    )}
                    {showCompare && (
                      <LinkButton>
                        <span className="d-flex align-items-center">
                          <span>Compare Plans</span>
                          <img
                            className="ml-10"
                            src="/assets/icons/chevron-right-icon@2x.png"
                            alt=""
                            style={{ width: 8, height: 12 }}
                          />
                        </span>
                      </LinkButton>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* END BOTTOM ROW - NORMAL */}

            {/* START BOTTOM ROW - AUTO ENROLL */}
            {showAutoEnroll && (
              <div className="d-flex align-items-center justify-content-between gap-20">
                <div className="d-none d-lg-block flex">
                  <div
                    className="d-flex align-items-start gap-10"
                    style={{ maxWidth: 235 }}
                  >
                    <svg
                      className="flex-shrink-0"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.2334 7H17.2334V10L21.2334 6L17.2334 2V5H5.2334V11H7.2334V7ZM17.2334 17H7.2334V14L3.2334 18L7.2334 22V19H19.2334V13H17.2334V17Z"
                        fill="#002677"
                      />
                    </svg>
                    <div className="text-bold text-small">
                      You will be automatically enrolled in this plan for 2024.
                    </div>
                  </div>
                </div>
                {/* to={`/details/${planData?.id}`} - removed below */}
                <LinkButton as={Link} to="">
                  <span className="d-flex align-items-center gap-5">
                    View plan details
                    <img
                      src="/assets/icons/chevron-right-icon@2x.png"
                      alt=""
                      style={{ width: 8, height: 12 }}
                    />
                  </span>
                </LinkButton>
                <div className="d-flex align-items-center no-wrap">
                  {showCompareCheckbox && (
                    <Checkbox
                      checked={showCompare}
                      label={
                        showCompare ? (
                          <span className="visually-hidden">
                            Add to compare
                          </span>
                        ) : (
                          'Add to compare'
                        )
                      }
                      onChange={handleOnCompareChange(
                        !showCompare,
                        planData?.name
                      )}
                    />
                  )}
                  {showCompare && (
                    <LinkButton>
                      <span className="d-flex align-items-center">
                        <span>Compare Plans</span>
                        <img
                          className="ml-10"
                          src="/assets/icons/chevron-right-icon@2x.png"
                          alt=""
                          style={{ width: 8, height: 12 }}
                        />
                      </span>
                    </LinkButton>
                  )}
                </div>
              </div>
            )}
            {/* END BOTTOM ROW - AUTO ENROLL */}

            {!showEnroll && (
              <div className="border-top mt-20 pt-20">
                <div className="text-bold text-blue-primary mb-10">
                  Enroll by phone: 1-877-659-9664 (TTY 711)
                </div>
                <div>
                  Call a UnitedHealthcare sales agent to enroll, 7 a.m. - 7 p.m.
                  CT, 7 days a week.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PlanCard.propTypes = {
  version: PropTypes.oneOf(['MAPD', 'PDP', 'SNP']),
  cardNumber: PropTypes.number,
  cardTotal: PropTypes.number,
  className: PropTypes.string,
  compareCount: PropTypes.number,
  drugCoverageStatus: PropTypes.oneOf(['covered', 'not-covered', 'partial']),
  isExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isSaved: PropTypes.bool,
  planData: PropTypes.object,
  providerCoverageStatus: PropTypes.oneOf([
    'covered',
    'not-covered',
    'partial'
  ]),
  savedDoctors: PropTypes.array,
  savedDrugs: PropTypes.array,
  showAutoEnroll: PropTypes.bool,
  showCompare: PropTypes.bool,
  showCompareCheckbox: PropTypes.bool,
  showEnroll: PropTypes.bool,
  showFiveStar: PropTypes.bool,
  showRemove: PropTypes.bool,
  showPersonalization: PropTypes.bool,
  onAddDoctorsClick: PropTypes.func,
  onAddDrugsClick: PropTypes.func,
  onLearnClick: PropTypes.func,
  onRemoveDoctorClick: PropTypes.func,
  onRemoveDrugClick: PropTypes.func,
  onCompare: PropTypes.func,
  onRemove: PropTypes.func,
  onSave: PropTypes.func
};

PlanCard.defaultProps = {
  version: 'MAPD',
  cardNumber: 0,
  cardTotal: 0,
  drugCoverageStatus: 'covered',
  isExpandable: true,
  isExpanded: false,
  isSaved: false,
  planData: {},
  providerCoverageStatus: 'covered',
  savedDoctors: [],
  savedDrugs: [],
  showAutoEnroll: false,
  showCompare: false,
  showCompareCheckbox: true,
  showEnroll: true,
  showFiveStar: false,
  showRemove: false,
  showPersonalization: false,
  onAddDoctorsClick: () => {},
  onAddDrugsClick: () => {},
  onLearnClick: () => {},
  onRemoveDoctorClick: () => {},
  onRemoveDrugClick: () => {},
  onCompare: () => {},
  onRemove: () => {},
  onSave: () => {}
};

export default PlanCard;
