import { Button } from '@bswing/uhc-core';

const MSHomeGuide = (props) => {
  return (
    <div className="bg-peach-primary text-center text-lg-left">
      <div className="container container-lg">
        <div className="row">
          <div className="column column-12">
            <div className="d-flex flex-column-reverse flex-lg-row gap-40">
              <div className="d-flex align-items-end flex width-lg-50">
                <img
                  className="d-block width-100 height-auto mt-n50"
                  src="/assets/images/guide-illustration@2x.png"
                  alt=""
                />
              </div>
              <div className="flex my-40">
                <div className="text-blue-primary text-large text-bold mb-10">
                  Information at your fingertips
                </div>
                <div className="heading-1 mb-20">
                  Get your free Medicare Supplement Insurance guide.
                </div>
                <p className="text-large text-blue-primary mb-20">
                  Learn more about how Medicare Supplement insurance works,
                  coverage options, and when to apply.
                </p>
                <Button
                  className="bg-none"
                  isOutlined={true}
                  isSecondary={true}
                >
                  Get your free guide
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSHomeGuide;
