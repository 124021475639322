const MSPlanDetailImportantInformation = (props) => {
  return (
    <div className="container container-lg py-40 py-lg-60">
      <div className="row">
        <div className="column column-12">
          <div className="border border-thick rounded-xl">
            <div className="uhc-vpp-scroll-pane" tabIndex={0}>
              <div className="d-flex flex-column gap-20 p-30">
                <h2 className="heading-3">Important Information</h2>
                <p>The amounts shown on this page are applicable for 2022.</p>
                <p>
                  If applicable, the “You Pay” amount indicates what you will
                  pay for Medicare covered services once the annual Part B
                  deductible is met.
                </p>
                <p id="footnote-1">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">1</sup> The
                  premium (including discounts) is for a person who is an age 65
                  female non-tobacco user who can answer "no" to all of the
                  health questions on the Application Form when applying. The
                  answers to questions on your Application Form will be used to
                  determine which premium applies to you and your actual monthly
                  premium will be determined when you apply. All premiums are
                  subject to change. Any premium change will apply to all
                  members of the same class insured under your Plan who reside
                  in your state. The premium is based on the information you
                  entered, is for the current month and includes discounts, if
                  applicable. The answers to questions on your Application Form
                  will be used to determine which premium applies to you and
                  your actual monthly premium will be determined when you apply.
                  All premiums are subject to change. Any premium change will
                  apply to all members of the same class insured under your plan
                  who reside in your state. These premiums are not for current
                  insured members. If you are already an insured member, please
                  call for information.
                </p>
                <p id="footnote-7">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">7</sup> A
                  benefit period begins on the first day you receive service as
                  an inpatient in a hospital and ends after you have been out of
                  the hospital and have not received skilled care in any other
                  facility for 60 days in a row.
                </p>
                <p id="footnote-8">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">8</sup> Notice:
                  When your Medicare Part A hospital benefits are exhausted, the
                  insurer stands in place of Medicare and will pay whatever
                  amount Medicare would have paid for up to an additional 365
                  days as provided in the policy's "Core Benefits." During this
                  time, the hospital is prohibited from billing you for the
                  balance based on any difference between its billed charges and
                  the amount Medicare would have paid.
                </p>
                <p id="footnote-9">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">9</sup> Not
                  subject to the Part B deductible and is paid at 100% by
                  Medicare.
                </p>
                <p id="footnote-10">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">10</sup>{' '}
                  <b>
                    These offers are only available to insured members covered
                    under an AARP Medicare Supplement Plan from UnitedHealthcare
                    Insurance Company. These are additional insured member
                    services apart from the AARP Medicare Supplement Plan
                    benefits, are not insurance programs, are subject to
                    geographical availability and may be discontinued at any
                    time.
                  </b>{' '}
                  Certain offerings are provided by third parties not affiliated
                  with UnitedHealthcare Insurance Company. None of these
                  services are a substitute for the advice of a doctor or should
                  be used for emergency or urgent care needs. In an emergency,
                  call 911 or go to the nearest emergency room.
                </p>
                <p className="text-bold">Dentegra Dental Discount</p>
                <p id="footnote-11">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">11</sup>{' '}
                  Dentegra Fee Schedules vs. Fair Health Mean Data
                </p>
                <p>
                  <span className="text-bold">THIS IS NOT INSURANCE</span> and
                  not intended to replace insurance. All decisions about
                  medications and dental care are between you and your dentist
                  or health care provider. The Dentegra dental discount is not a
                  Qualified Health Plan under the Affordable Care Act. Products
                  or services that are reimbursable by federal programs
                  including Medicare and Medicaid are not available on a
                  discounted or complimentary basis. The Dentegra dental
                  discount provides discounts at certain health care providers
                  for dental services. The range of discounts will vary
                  depending on the type of provider, geographic region and
                  service. The Dentegra dental discount does not make payments
                  to the providers of dental services. Individuals who utilize
                  the Dentegra dental discount are obligated to pay for all
                  health care services but will receive a discount from those
                  health care providers who have contracted with Dentegra
                  Insurance Company. Dentegra Insurance Company, 560 Mission
                  Street, San Francisco, CA 94105, is the Discount Plan
                  Organization.
                </p>
                <p className="text-bold">
                  AARP Vision Discounts provided by EyeMed
                </p>
                <p>
                  EyeMed Vision Care LLC (EyeMed) is the network administrator
                  of AARP Vision provided by EyeMed. These discounts cannot be
                  combined with any other discounts, promotions, coupons, or
                  vision care plans unless noted herein. All decisions about
                  medications and vision care are between you and your health
                  care provider. Products or services that are reimbursable by
                  federal programs including Medicare and Medicaid are not
                  available on a discounted or complimentary basis. EyeMed pays
                  a royalty fee to AARP for use of the AARP intellectual
                  property. Amounts paid are used for the general purposes of
                  AARP and its members.
                </p>
                <p id="footnote-12">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">12</sup> Offer
                  valid at participating providers. Eye exam discount applies
                  only to comprehensive eye exams and does not include contact
                  lens exams or fitting. Contact lens purchase requires valid
                  contact lens prescription.
                </p>
                <p id="footnote-13">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">13</sup>{' '}
                  Present offer to receive a bonus $50 off in addition to your
                  AARP Vision Discount of 50% off lenses or best in-store offer
                  when you purchase a frame and progressive lenses. Complete
                  pair required. Frame and lens purchase cannot be combined with
                  any other offers, discounts, past purchases, readers or
                  non-prescription sunglasses. Valid doctor's prescription
                  required and the cost of an eye exam is not included.
                  Eyeglasses priced from $218.29 to $2,423.33. Cartier®,
                  Lindberg®, Oakley® Kato, Oliver Peoples, and Maui Jim® frames
                  excluded. Additional frame and lens exclusions and
                  restrictions may apply, see store associate for details. Void
                  where prohibited. Discounts are off tag price. No cash value.
                  Offer expires 12/31/2023. Code 755453.
                </p>
                <p className="text-bold">
                  AARP Hearing Solutions provided by UnitedHealthcare Hearing
                </p>
                <p>
                  The $100 discount and 4-year extended warranty applies to
                  hearing aids offered in the Premier or Classic technology
                  levels. One complimentary hearing test is only available from
                  UnitedHealthcare Hearing providers, for purposes of
                  determining hearing aid candidacy. These discounts cannot be
                  combined with any other discounts, promotions, coupons or
                  hearing aid benefit plans unless noted herein. Products or
                  services that are reimbursable by federal programs including
                  Medicare and Medicaid are not available on a discounted or
                  complimentary basis. AARP commercial member benefits are
                  provided by third parties, not by AARP or its affiliates.
                  Providers pay a royalty fee to AARP for the use of its
                  intellectual property. These fees are used for the general
                  purposes of AARP. Some provider offers are subject to change
                  and may have restrictions. Please contact the provider
                  directly for details.
                </p>
                <p className="text-bold">Renew Active Fitness Program</p>
                <p>
                  Participation in the Renew Active® program is voluntary. Renew
                  Active includes standard fitness membership and other
                  offerings. Fitness membership equipment, classes, personalized
                  fitness plans, caregiver access and events may vary by
                  location. Consult your doctor prior to beginning an exercise
                  program or making changes to your lifestyle or health care
                  routine. The Renew Active program varies by plan/area. Gym
                  network may vary in local market.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSPlanDetailImportantInformation;
