import { Button } from '@bswing/uhc-core';

const ShopperProfileFindPlanCTA = () => {
  return (
    <div className="mb-30">
      <div className="bg-teal-quaternary border-rounded-md d-flex flex-column flex-lg-row align-items-center gap-40 p-20 p-lg-40">
        <img
          className="d-none d-lg-block"
          src="/assets/images/desk-illustration@2x.png"
          alt=""
          style={{ height: 'auto', width: 200 }}
        />
        <div className="flex">
          <h2 className="heading-3 mb-10">
            Get the help you need finding the right plan
          </h2>
          <p className="mb-20">
            Finding the right plan starts with knowing your needs. We help make
            it easier.
          </p>
          <Button isSecondary={true}>Find your plan</Button>
        </div>
      </div>
    </div>
  );
};

export default ShopperProfileFindPlanCTA;
