const ShopperProfileDisclaimers = (props) => {
  return (
    <div className="container container-lg mb-40">
      <div className="row">
        <div className="column column-12">
          <div className="border border-thick rounded-xl">
            <div className="uhc-vpp-scroll-pane" tabIndex={0}>
              <div className="d-flex flex-column gap-20 p-30">
                <h2 className="heading-3">Scroll for Important Disclaimers</h2>
                <p>
                  <b>
                    UnitedHealthcare pays royalty fees to AARP for the use of
                    its intellectual property. These fees are used for the
                    general purposes of AARP. AARP and its affiliates are not
                    insurers. AARP does not employ or endorse agents, brokers or
                    producers.
                  </b>
                </p>
                <p>
                  AARP encourages you to consider your needs when selecting
                  products and does not make product recommendations for
                  individuals.
                </p>
                <p>
                  Please note that each Insurer has sole financial
                  responsibility for its products.
                </p>
                <p>
                  <b>AARP® Medicare Supplement Insurance Plans</b>
                </p>
                <p>
                  AARP endorses the AARP Medicare Supplement Plans insured by
                  UnitedHealthcare Insurance Company of New York, 2950
                  Expressway Drive South, Suite 240, Islandia, NY 11749. Policy
                  Form No. GRP 79171 GPS-1 (G-36000-4).
                </p>
                <p>
                  <b>
                    Plans are available to persons under age 65 who are eligible
                    for Medicare by reason of disability or End-Stage Renal
                    Disease.
                  </b>
                </p>
                <p>
                  Not connected with or endorsed by the U.S. Government or the
                  federal Medicare program.
                </p>
                <p>
                  This is a solicitation of insurance. A licensed insurance
                  agent/producer may contact you.
                </p>
                <p>
                  You must be an AARP member to enroll in an AARP Medicare
                  Supplement Plan.
                </p>
                <p>
                  THESE PLANS HAVE ELIGIBILITY REQUIREMENTS, EXCLUSIONS AND
                  LIMITATIONS. FOR COSTS AND COMPLETE DETAILS (INCLUDING
                  OUTLINES OF COVERAGE), CALL A LICENSED INSURANCE
                  AGENT/PRODUCER AT THE TOLL-FREE NUMBER ABOVE.
                </p>
                <p>
                  <b>
                    Medicare Advantage plans and Medicare Prescription Drug
                    plans
                  </b>
                </p>
                <p>
                  Plans are insured through UnitedHealthcare Insurance Company
                  or one of its affiliated companies, a Medicare Advantage
                  organization with a Medicare contract and a Medicare-approved
                  Part D sponsor. Enrollment in these plans depends on the
                  plan's contract renewal with Medicare. You do not need to be
                  an AARP member to enroll in a Medicare Advantage plan or
                  Medicare Prescription Drug plan.
                </p>
                <p>
                  You must continue to pay your Medicare Part B premium if not
                  otherwise paid for under Medicaid or by another third party.
                </p>
                <p>
                  The Formulary, pharmacy network, and /or provider network may
                  change at any time. You will receive notice when necessary.
                  This information is not a complete description of benefits.
                  Contact the plan for more information. Limitations,
                  co-payments, and restrictions may apply. Benefits, premium
                  and/or co-payments/co-insurance may change on January 1 of
                  each year.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-40">
        <div className="column column-12">WBXXXXXNY</div>
      </div>
    </div>
  );
};

export default ShopperProfileDisclaimers;
