import Checkbox from 'components/checkbox/checkbox';
import Radio from 'components/radio/radio';
import classNames from 'classnames';
import './filter-drop.scss';

const FilterDrop = (props) => {
  const {
    heading,
    type,
    activeDrop,
    closeDrops,
    dropLocation,
    items,
    onDropFilterChange = () => {},
    clearAllDropItems = () => {},
    handleDropButtonUpdate = () => {},
    clearDropButton = () => {}
  } = props;

  const componentClassName = classNames('filter-drop', {
    isActive: activeDrop === heading
  });

  const clearDrop = () => {
    let clearedItems = [];
    items.forEach((element) => {
      document.querySelector(`input[value="${element.label}"]`).checked = false;
      clearedItems.push(element.label);
    });

    clearDropButton(heading);
    clearAllDropItems(clearedItems);
  };

  const onCheckboxChange = (event) => {
    onDropFilterChange(event.target.value, event.target.checked, type);
  };

  const updateDropButtonState = (event) => {
    const itemTarget = event.target.value;
    const section = heading;

    handleDropButtonUpdate(event, section, itemTarget, type);
  };

  return (
    <div className={componentClassName} style={{ left: dropLocation }}>
      <div className="filter-drop__heading">{heading}</div>
      <div className="filter-drop__options">
        {type === 'check' &&
          items?.map((item) => (
            <Checkbox
              key={item.label}
              value={item.label}
              checked={item.checked}
              label={<span className="text-regular">{item.label}</span>}
              onCheckboxChange={(e) => onCheckboxChange(e)}
              updateDropButtonState={(e) => updateDropButtonState(e)}
            />
          ))}
        {type === 'radio' &&
          items?.map((item) => (
            <Radio
              key={item.label}
              value={item.label}
              checked={item.checked}
              name={heading}
              label={<span className="text-regular">{item.label}</span>}
              onCheckboxChange={(e) => onCheckboxChange(e)}
              updateDropButtonState={(e) => updateDropButtonState(e)}
            />
          ))}
      </div>
      <div className="filter-drop__footer">
        <button className="uhc-link-button" onClick={clearDrop}>
          <span className="uhc-button__text">Clear</span>
        </button>
        <button
          className="uhc-button uhc-button--secondary"
          onClick={closeDrops}
        >
          <span className="uhc-button__text">Done</span>
        </button>
      </div>
    </div>
  );
};

export default FilterDrop;
