const planSectionsWithoutDetails = [
  {
    name: 'Plan Costs',
    id: 'plan-costs',
    tables: [
      {
        rows: [
          {
            headline: `<span class="hl-cat">Annual medical deductible</span>`,
            description: `<div class="text-small text-regular mt-5">This is the amount you have to pay before your plan starts paying its share of your medical costs.</div>`
          },
          {
            headline: `<span class="hl-cat">Annual out-of-pocket maximum</span>`,
            description: `<div class="text-small text-regular mt-5">This is the most you pay for covered services from network providers during the year.</div>`
          },
          {
            headline: `<span class="hl-cat">Medicare Part B premium giveback</span>`,
            description: `<div class="text-small text-regular mt-5">UnitedHealthcare pays part of your Medicare Part B premium each month.</div>`
          },
          {
            headline: `<span class="hl-cat">Travel</span>`,
            description: `<div class="text-small text-regular mt-5">Get network care when you travel across the country.</div>`
          }
        ]
      }
    ]
  },
  {
    name: 'Doctor Visits',
    id: 'doctor-visits',
    tables: [
      {
        rows: [{ headline: `Your doctors` }]
      },
      {
        rows: [
          {
            headline: `<span class="hl-cat">Primary care provider (PCP)</span>`,
            description: `<div class="text-small text-regular mt-5">A doctor or other health care professional you select to be your main health care provider.</div>`
          },
          {
            headline: `<span class="hl-cat">Specialist</span>`,
            description: `<div class="text-small text-regular mt-5">A doctor who provides health care services for a specific disease or part of the body.</div>`
          },
          {
            headline: `<span class="hl-cat">Virtual visits</span>`,
            description: `<div class="text-small text-regular mt-5">UnitedHTalk with a network health care provider using your computer, smart phone, or tablet.</div>`
          },
          {
            headline: `<span class="hl-cat">Annual routine physical</span>`,
            description: `<div class="text-small text-regular mt-5">An annual physical and wellness visit to review your medical history and check your health and fitness.</div>`
          },
          {
            headline: `<span class="hl-cat">Preventive services</span>`,
            description: `<div class="text-small text-regular mt-5">Services that may help find health problems early, when treatment works best, and can help keep you from getting certain diseases.</div>`
          },
          {
            headline: `<span class="hl-cat">Group therapy visit</span>`,
            description: `<div class="text-small text-regular mt-5">A mental health visit where one or more licensed therapists or psychologists meet with a group of patients.</div>`
          },
          {
            headline: `<span class="hl-cat">Individual therapy visit</span>`,
            description: `<div class="text-small text-regular mt-5">A one-on-one mental health visit with a licensed therapist or psychologist.</div>`
          },
          {
            headline: `<span class="hl-cat">Opioid treatment services</span>`,
            description: `<div class="text-small text-regular mt-5">Services that help make sure you know how to safely use your prescription opioid drugs and other prescription drugs that are frequently misused.</div>`
          }
        ]
      }
    ]
  },
  {
    name: 'Prescription Drug Benefits',
    id: 'drug-benefits',
    tables: [
      {
        rows: [{ headline: `Your drugs` }]
      },
      {
        rows: [
          {
            headline: `<span class="hl-cat">Annual prescription deductible</span>`,
            description: `<div class="text-small text-regular mt-5">You pay the full cost of your drugs until you reach the annual deductible amount.</div>`
          },
          {
            headline: `<span class="hl-cat">Preferred pharmacy</span>`,
            description: `<div class="text-small text-regular mt-5">Lower your prescription drug costs when you use the plan’s preferred pharmacy.</div>`
          }
        ]
      }
    ]
  },
  {
    name: 'Dental Coverage',
    id: 'dental-coverage',
    tables: [
      {
        rows: [{ headline: `Your dentists` }]
      },
      {
        rows: [
          {
            headline: `<span class="hl-cat">Dental allowance</span>`,
            description: `<div class="text-small text-regular mt-5">The most your plan will pay for covered dental services during the plan year.</div>`
          },
          {
            headline: `<span class="hl-cat">Preventive dental</span>`,
            description: `<div class="text-small text-regular mt-5">Coverage for oral exams, routine cleanings, X-rays and fluoride.</div>`
          },
          {
            headline: `<span class="hl-cat">Comprehensive dental</span>`,
            description: `<div class="text-small text-regular mt-5">Coverage for bridges and dentures.</div>`
          },
          {
            headline: `<span class="hl-cat">Comprehensive dental</span>`,
            description: `<div class="text-small text-regular mt-5">Coverage for filings, crowns, root canals and extractions.</div>`
          },
          {
            headline: `<span class="hl-cat">Optional rider</span>`,
            description: `<div class="text-small text-regular mt-5">Get additional dental coverage for an extra monthly fee.</div>`
          }
        ]
      }
    ]
  },
  {
    name: 'Medical Benefit Information',
    id: 'medical-benefits',
    tables: [
      {
        rows: [
          {
            headline: `<span class="hl-cat">Urgent care</span>`,
            description: `<div class="text-small text-regular mt-5">When you need immediate treatment for minor injuries or illnesses such as sprains, fractures, minor burns or infections, earaches and strep throat.</div>`
          },
          {
            headline: `<span class="hl-cat">Emergency care</span>`,
            description: `<div class="text-small text-regular mt-5">A medical emergency is when you need immediate treatment for life-threatening injuries or illnesses and other serious conditions.</div>`
          },
          {
            headline: `<span class="hl-cat">Ambulance</span>`,
            description: `<div class="text-small text-regular mt-5">Ground or air transportation to a nearby hospital or skilled nursing facility when you need emergency care.</div>`
          },
          {
            headline: `<span class="hl-cat">Inpatient hospital care</span>`,
            description: `<div class="text-small text-regular mt-5">Medical services and care provided when your doctor admits you to a hospital or other inpatient facility for at least one night.</div>`
          },
          {
            headline: `<span class="hl-cat">Ambulatory surgical center</span>`,
            description: `<div class="text-small text-regular mt-5">A medical facility that specializes in same-day outpatient surgeries and procedures.</div>`
          },
          {
            headline: `<span class="hl-cat">Outpatient hospital services</span>`,
            description: `<div class="text-small text-regular mt-5">Medical services and care that don’t require an overnight hospital stay.</div>`
          },
          {
            headline: `<span class="hl-cat">Diagnostic radiology services</span>`,
            description: `<div class="text-small text-regular mt-5">Tests and scans used by your doctor to see inside your body and diagnose health conditions.</div>`
          },
          {
            headline: `<span class="hl-cat">Diagnostic tests and procedures, non-radiological</span>`,
            description: `<div class="text-small text-regular mt-5">Tests and procedures that look for changes in your health to help your doctor diagnose potential conditions and illnesses.</div>`
          },
          {
            headline: `<span class="hl-cat">Lab services</span>`,
            description: `<div class="text-small text-regular mt-5">Blood, urine, or tissue tests used by your doctor to diagnose and monitor medical conditions.</div>`
          },
          {
            headline: `<span class="hl-cat">Outpatient X-rays</span>`,
            description: `<div class="text-small text-regular mt-5">Scans of your body mainly used to look at broken bones but may also be used to diagnose problems with your joints and internal organs.</div>`
          },
          {
            headline: `<span class="hl-cat">Diabetes monitoring supplies</span>`,
            description: `<div class="text-small text-regular mt-5">Supplies used to check your blood glucose level.</div>`
          },
          {
            headline: `<span class="hl-cat">Physical, speech, and occupational therapy</span>`,
            description: `<div class="text-small text-regular mt-5">Treatment and services that can help improve your every day life.</div>`
          },
          {
            headline: `<span class="hl-cat">Home health care</span>`,
            description: `<div class="text-small text-regular mt-5">Health care services and supplies you get in your home under your doctor’s orders.</div>`
          },
          {
            headline: `<span class="hl-cat">Skilled nursing facility</span>`,
            description: `<div class="text-small text-regular mt-5">Health care services you get in a nursing home from licensed nurses and therapists.</div>`
          }
        ]
      }
    ]
  },
  {
    name: 'Extra Benefits and Programs',
    id: 'benefits-programs',
    tables: [
      {
        rows: [
          {
            headline: `<span class="hl-cat">Routine eye exam</span>`,
            description: `<div class="text-small text-regular mt-5">One eye exam per year to help monitor and maintain your overall eye health.</div>`
          },
          {
            headline: `<span class="hl-cat">Routine eyewear allowance</span>`,
            description: `<div class="text-small text-regular mt-5">Use this allowance to buy frames or contacts.</div>`
          },
          {
            headline: `<span class="hl-cat">Routine hearing exam</span>`,
            description: `<div class="text-small text-regular mt-5">One hearing exam per year to help monitor and maintain your overall hearing health.</div>`
          },
          {
            headline: `<span class="hl-cat">Hearing aids</span>`,
            description: `<div class="text-small text-regular mt-5">Prescription or OTC hearing aids provided through UnitedHealthcare Hearing.</div>`
          },
          {
            headline: `<span class="hl-cat">OTC credit</span>`,
            description: `<div class="text-small text-regular mt-5">Use this credit to buy OTC products like vitamins and supplements, first aid items, and more.</div>`
          },
          {
            headline: `<span class="hl-cat">Fitness program</span>`,
            description: `<div class="text-small text-regular mt-5">Stay active, focused and connected either at a gym or from home.</div>`
          },
          {
            headline: `<span class="hl-cat">Routine transportation</span>`,
            description: `<div class="text-small text-regular mt-5">Trips to or from plan-approved locations.</div>`
          },
          {
            headline: `<span class="hl-cat">Routine foot care</span>`,
            description: `<div class="text-small text-regular mt-5">Covered services include preventive treatment of the foot like cutting or removal of corns, warts, calluses or nails.</div>`
          },
          {
            headline: `<span class="hl-cat">Personal emergency response</span>`,
            description: `<div class="text-small text-regular mt-5">A monitoring device that can quickly connect you to a trained emergency operator 24 hours a day.</div>`
          },
          {
            headline: `<span class="hl-cat">Nurse Hotline</span>`,
            description: `<div class="text-small text-regular mt-5">Nurses are available to you 24/7 to talk to you about your medical concerns and answer your questions.</div>`
          },
          {
            headline: `<span class="hl-cat">Home support services</span>`,
            description: `<div class="text-small text-regular mt-5">Choose from a variety of services like companion care, personal care, housekeeping, yard care, home safety devices and more.</div>`
          },
          {
            headline: `<span class="hl-cat">Meal benefit</span>`,
            description: `<div class="text-small text-regular mt-5">Fresh, healthy meals after you’re discharged from the hospital or a skilled nursing facility.</div>`
          },
          {
            headline: `<span class="hl-cat">Routine chiropractic</span>`,
            description: `<div class="text-small text-regular mt-5">Visits for relief of nausea or nerve, muscle, or joint pain to help keep your body at its best.</div>`
          },
          {
            headline: `<span class="hl-cat">Routine acupuncture</span>`,
            description: `<div class="text-small text-regular mt-5">Visits to correct body imbalances and conditions such as lower back pain, sprains, strains, nausea, headaches, carpal tunnel and menstrual cramps.</div>`
          },
          {
            headline: `<span class="hl-cat">Naturopathy services</span>`,
            description: `<div class="text-small text-regular mt-5">Alternate medicine visits that focus on self healing, as well as treating and preventing diseases without the use of drugs.</div>`
          }
        ]
      }
    ]
  }
  // {
  //   name: 'Plan Documents',
  //   id: 'plan-documents',
  //   tables: [
  //     {
  //       rows: [
  //         'Summary of Benefits',
  //         'Evidence of Coverage',
  //         'Benefit Highlights'
  //       ]
  //     }
  //   ]
  // }
];

const planSectionsWithDetails = [
  {
    name: 'Plan Costs',
    id: 'plan-costs',
    tables: [
      {
        rows: [
          'Plan Overview',
          'Part B Premium Reduction',
          'Annual Medical Deductible',
          'Out-of-Pocket Maximum'
        ]
      }
    ]
  },
  {
    name: 'Doctor Visits',
    id: 'doctor-visits',
    tables: [
      {
        rows: [
          '<div class="mb-5">Mary Nasso DDS</div><div class="text-regular mb-5">4222 3rd Ave, Bronx, NY 10457<br />(718) 616-3880</div><div><a class="uhc-link-button text-small underline" href="#link">Edit</a></div>',
          '<div class="mb-5">Melvin Leeds MD</div><div class="text-regular mb-5">12 Prince St, Bronx, NY 10457<br />(718) 616-4545</div><div><a class="uhc-link-button text-small underline" href="#link">Edit</a></div>'
        ]
      },
      {
        rows: [
          'Your Primary Care Physician',
          'Specialist',
          'Referral to Specialist required?',
          'National Network'
        ]
      }
    ]
  },
  {
    name: 'Prescription Drug Benefits',
    id: 'drug-benefits',
    tables: [
      {
        rows: [
          '<div class="mb-5">Estimated Annual Drug Cost</div><div><a class="uhc-link-button text-small underline" href="#link">View drug cost details</a></div>',
          '<div class="mb-5">atorvastatin calcium TAB 10MG</div><div><a class="uhc-link-button text-small underline" href="#link">Edit</a></div>',
          '<div class="mb-5">atomoxetine</div><div><a class="uhc-link-button text-small underline" href="#link">Edit</a></div>'
        ]
      },
      {
        rows: [
          'Annual Prescription Deductible',
          'Tier 1: Preferred Generic Drugs',
          'Tier 2: Generic Drugs',
          'Tier 3: Preferred Brand Drugs',
          'Tier 3: Select Insulin Drugs',
          'Tier 4: Non-Preferred Drugs',
          'Tier 5: Specialty Tier Drugs',
          'Initial Coverage Stage - No LIS',
          'Initial Coverage Stage (LIS)',
          'Coverage Gap Stage',
          'Catastrophic Coverage Stage',
          'Walgreens Preferred Retail Pharmacy',
          'Preferred Mail Home Delivery through OptumRx'
        ]
      }
    ]
  },
  {
    name: 'Dental Coverage',
    id: 'dental-coverage',
    tables: [
      {
        rows: [
          '<div class="mb-5">Ian Pasch DDS</div><div class="text-regular mb-5">4222 3rd Ave, Bronx, NY 10457<br />(718) 616-3880</div><div><a class="uhc-link-button text-small underline" href="#link">Edit</a></div>'
        ]
      },
      {
        rows: ['Routine/Comprehensive Dental', 'Optional Rider']
      }
    ]
  },
  {
    name: 'Medical Benefits',
    id: 'medical-benefits',
    tables: [
      {
        rows: [
          'Ambulatory Surgical Center',
          'Emergency Care',
          'Diabetes Monitoring Supplies',
          'Home Health Care',
          'Inpatient Hopsital Care',
          'Lab Services',
          'Mental Health (outpatient)',
          'Opioid Treatment Services',
          'Outpatient Hospital Services',
          'Preventative Services',
          'UnitedHealth Passport',
          'Urgent Care'
        ]
      }
    ]
  },
  {
    name: 'Extra Benefits and Programs',
    id: 'benefits-programs',
    tables: [
      {
        rows: [
          'Eye Exam',
          'Eyewear',
          'Hearing Aids - All Types',
          'Fitness',
          'Acupuncture',
          'Chiropractic Care',
          'Over-the-Counter Items Health Products Debit Card',
          'Over-the-Counter (OTC) Products Catalog',
          'Transportation',
          'Virtual Medical Visits',
          'Virtual Mental Health Visits'
        ]
      }
    ]
  },
  {
    name: 'Plan Documents',
    id: 'plan-documents',
    tables: [
      {
        rows: [
          'Summary of Benefits',
          'Evidence of Coverage',
          'Benefit Highlights'
        ]
      }
    ]
  }
];

const planSectionsPreferredPharmacy = [
  {
    name: 'Preferred retail network pharmacy',
    id: 'preferred-retail-network-pharmacy',
    tables: [
      {
        rows: [
          {
            headline: `<span class="hl-cat">Tier 1: Preferred Generic Drugs</span>`
          },
          {
            headline: `<span class="hl-cat">Tier 2: Generic Drugs</span>`
          },
          {
            headline: `<span class="hl-cat">Tier 3: Preferred Brand Drugs</span>`
          },
          {
            headline: `<span class="hl-cat">Tier 4: Non-Preferred Drugs</span>`
          },
          {
            headline: `<span class="hl-cat">Tier 5: Specialty Drugs</span>`
          },
          {
            headline: `<span class="hl-cat">Insulin Drugs</span>`
          }
        ]
      }
    ]
  }
];

export {
  planSectionsWithoutDetails,
  planSectionsWithDetails,
  planSectionsPreferredPharmacy
};
