import { Accordion } from '@bswing/uhc-core';
import faqData from './ms-plan-list-faqs.data';

const MSPlanListFAQs = (props) => {
  return (
    <div className="container container-lg mb-60 mb-lg-100">
      <div className="row">
        <div className="column column-12 column-lg-10 column-lg-shift-1">
          <div className="border-bottom">
            {faqData.map((faq, index) => (
              <Accordion
                className="border-top"
                id={`med-supp-faq-${index + 1}`}
                arrowColorOverride="#196ecf"
                header={
                  <div className="heading-5 text-blue-primary">
                    {faq.question}
                  </div>
                }
                isMinimal={true}
                key={index}
              >
                <div className="text-lg-large mb-20">{faq.answer}</div>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSPlanListFAQs;
