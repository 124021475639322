const savedPlansFaqData = [
  {
    question: 'Can I talk to someone if I have questions?',
    answer: (
      <p>
        You can call our Medicare sales experts toll-free, 7 a.m. to 11 p.m. ET
        Monday - Friday, 9 a.m. to 5 p.m. ET Saturday: 1-855-939-4744 (TTY 711).
        You can find out more about Medicare and your coverage options in{' '}
        <a className="text-bold" href="#link">
          Medicare Made Clear
        </a>
        .
      </p>
    )
  },
  {
    question: 'How do I save my progress?',
    answer: (
      <p>
        Creating an account allows you to save the information you enter so you
        can save your shopping progress. Creating an account is easy. It only
        requires an email address, username and password. If you choose to
        become a member, your login information will remain the same.
      </p>
    )
  },
  {
    question: 'What happens with my information?',
    answer: (
      <p>
        If you create an account, your information will be secured using your
        HealthSafe ID. If you haven't created an account, your information will
        be stored on your personal computer and will be removed when you clear
        your cache and cookies.
      </p>
    )
  },
  {
    question: 'How do I complete an in-progress application?',
    answer: (
      <p>
        When you visit your account, you will see any in-progress applications
        on your plans page, and you will be able to resume where you left off.
      </p>
    )
  },
  {
    question: 'How do I see all plans?',
    answer: (
      <p>
        You can see all plans by clicking the "Browse all plans" button on the
        plans page. You can also{' '}
        <a className="text-bold" href="#link">
          click here
        </a>{' '}
        to view all plans.
      </p>
    )
  }
];

const doctorsFaqData = [
  {
    question: 'Can I talk to someone if I have questions?',
    answer: (
      <p>
        You can call our Medicare sales experts toll-free, 7 a.m. to 11 p.m. ET
        Monday - Friday, 9 a.m. to 5 p.m. ET Saturday: 1-855-939-4744 (TTY 711).
        You can find out more about Medicare and your coverage options in{' '}
        <a className="text-bold" href="#link">
          Medicare Made Clear
        </a>
        .
      </p>
    )
  },
  {
    question: 'How do I save my progress?',
    answer: (
      <p>
        Creating an account allows you to save the information you enter so you
        can save your shopping progress. Creating an account is easy. It only
        requires an email address, username and password. If you choose to
        become a member, your login information will remain the same.
      </p>
    )
  },
  {
    question: 'What happens with my information?',
    answer: (
      <p>
        If you create an account, your information will be secured using your
        HealthSafe ID. If you haven't created an account, your information will
        be stored on your personal computer and will be removed when you clear
        your cache and cookies.
      </p>
    )
  },
  {
    question: 'How do I complete an in-progress application?',
    answer: (
      <p>
        When you visit your account, you will see any in-progress applications
        on your plans page, and you will be able to resume where you left off.
      </p>
    )
  },
  {
    question: 'How do I see all plans?',
    answer: (
      <p>
        You can see all plans by clicking the "Browse all plans" button on the
        plans page. You can also{' '}
        <a className="text-bold" href="#link">
          click here
        </a>{' '}
        to view all plans.
      </p>
    )
  }
];

const drugsFaqData = [
  {
    question: 'Can I talk to someone if I have questions?',
    answer: (
      <p>
        You can call our Medicare sales experts toll-free, 7 a.m. to 11 p.m. ET
        Monday - Friday, 9 a.m. to 5 p.m. ET Saturday: 1-855-939-4744 (TTY 711).
        You can find out more about Medicare and your coverage options in{' '}
        <a className="text-bold" href="#link">
          Medicare Made Clear
        </a>
        .
      </p>
    )
  },
  {
    question: 'How do I save my progress?',
    answer: (
      <p>
        Creating an account allows you to save the information you enter so you
        can save your shopping progress. Creating an account is easy. It only
        requires an email address, username and password. If you choose to
        become a member, your login information will remain the same.
      </p>
    )
  },
  {
    question: 'What happens with my information?',
    answer: (
      <p>
        If you create an account, your information will be secured using your
        HealthSafe ID. If you haven't created an account, your information will
        be stored on your personal computer and will be removed when you clear
        your cache and cookies.
      </p>
    )
  },
  {
    question: 'How do I complete an in-progress application?',
    answer: (
      <p>
        When you visit your account, you will see any in-progress applications
        on your plans page, and you will be able to resume where you left off.
      </p>
    )
  },
  {
    question: 'How do I see all plans?',
    answer: (
      <p>
        You can see all plans by clicking the "Browse all plans" button on the
        plans page. You can also{' '}
        <a className="text-bold" href="#link">
          click here
        </a>{' '}
        to view all plans.
      </p>
    )
  }
];

export { doctorsFaqData, drugsFaqData, savedPlansFaqData };
