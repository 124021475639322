import { Modal } from '@bswing/uhc-core';

const AutoEnrollmentModal = ({
  isShown = false,
  onCloseComplete = () => {}
}) => {
  return (
    <Modal
      isFullscreen={false}
      isShown={isShown}
      title={<div className="heading-2">Auto enrollment</div>}
      onCloseComplete={onCloseComplete}
      onOpenComplete={() => {}}
      aria-label="Auto enrollment"
    >
      {({ close }) => (
        <div className="container">
          <div className="row pb-20">
            <div className="column column-12">
              <p className="mb-10">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <p className="mb-10">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <p>
                Note: This plan name has been updated from AARP Medicare
                Advantage Plan 1 to AARP Medicare Advantage Plan 1 from
                UnitedHealthcare.
              </p>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AutoEnrollmentModal;
