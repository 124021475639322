import { Toggle } from '@bswing/uhc-core';

const PlanYearToggle = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-20">
      <Toggle
        options={[`View ${currentYear} plans`, `View ${currentYear + 1} plans`]}
        onChange={() => {}}
        style={{ minWidth: 320 }}
      />
      <div>
        Plans starting in{' '}
        <span className="text-bold">January {currentYear} or later.</span>
      </div>
    </div>
  );
};

export default PlanYearToggle;
