import classNames from 'classnames';
import './sp-card.scss';

const SPCard = ({ children, className }) => {
  const componentClassName = classNames('uhc-sp-card', {}, className);

  return <div className={componentClassName}>{children}</div>;
};

export default SPCard;
