import { LinkButton } from '@bswing/uhc-core';

const CoverageRiders = (props) => {
  return (
    <>
      <div className="text-lg-large">Optional Services (Riders)</div>
      <div className="bg-gray-2 border border-rounded-sm p-20">
        <div className="text-bold text-lg-large">
          High Optional Dental: Add $12 to your monthly premium
        </div>
        <ul className="text-lg-large">
          <li className="mb-10">HMO Plan</li>
          <li className="mb-10">
            Savings on covered general dental procedures.
          </li>
          <li>
            Specialty services are available, with an annual maximum of $1,000.
          </li>
        </ul>
        <div className="d-flex flex-column gap-10">
          <div>
            <LinkButton className="text-lg-large">
              <span className="d-flex align-items-center">
                <img
                  className="mr-10"
                  src="/assets/icons/pdf-icon@2x.png"
                  alt=""
                  style={{ width: 14, height: 16 }}
                />
                <span>High Optional Dental (PDF)</span>
              </span>
            </LinkButton>
          </div>
          <div>
            <LinkButton className="text-lg-large">
              <span className="d-flex align-items-center">
                <img
                  className="mr-10"
                  src="/assets/icons/pdf-icon@2x.png"
                  alt=""
                  style={{ width: 14, height: 16 }}
                />
                <span>Plan Dental High Optional (Español) (PDF)</span>
              </span>
            </LinkButton>
          </div>
          <div>
            <LinkButton className="text-lg-large">
              <span className="d-flex align-items-center">
                <img
                  className="mr-10"
                  src="/assets/icons/pdf-icon@2x.png"
                  alt=""
                  style={{ width: 14, height: 16 }}
                />
                <span>牙科白金 (High Optional Dental) 附約</span>
              </span>
            </LinkButton>
          </div>
        </div>
      </div>
      <div className="bg-gray-2 border border-rounded-sm p-20">
        <div className="text-bold text-lg-large">
          Platinum Dental: Add $40 to your monthly premium
        </div>
        <ul className="text-lg-large">
          <li className="mb-10">
            Covered dental services through the Platinum Dental Rider: $1,500 a
            year
          </li>
          <li className="mb-10">No deductible</li>
          <li className="mb-10">
            In-network exams, x-rays, cleanings, fluoride, and fillings: $0
            copay
          </li>
          <li className="mb-10">
            Crowns, bridges, root canals, extractions, dentures, and all other
            covered comprehensive services: 50% coinsurance
          </li>
          <li>
            Access to the largest Medicare Advantage dental network.
            Out-of-network coverage is available. If you choose to see an
            out-of-network dentist you might be billed more, even for services
            listed as $0 copay.
          </li>
        </ul>
        <div className="d-flex flex-column gap-10">
          <div>
            <LinkButton className="text-lg-large">
              <span className="d-flex align-items-center">
                <img
                  className="mr-10"
                  src="/assets/icons/pdf-icon@2x.png"
                  alt=""
                  style={{ width: 14, height: 16 }}
                />
                <span>Platinum Dental (PDF)</span>
              </span>
            </LinkButton>
          </div>
          <div>
            <LinkButton className="text-lg-large">
              <span className="d-flex align-items-center">
                <img
                  className="mr-10"
                  src="/assets/icons/pdf-icon@2x.png"
                  alt=""
                  style={{ width: 14, height: 16 }}
                />
                <span>Plan Dental Platinum (Español) (PDF)</span>
              </span>
            </LinkButton>
          </div>
          <div>
            <LinkButton className="text-lg-large">
              <span className="d-flex align-items-center">
                <img
                  className="mr-10"
                  src="/assets/icons/pdf-icon@2x.png"
                  alt=""
                  style={{ width: 14, height: 16 }}
                />
                <span>牙科白金 (Platinum Dental) 附約</span>
              </span>
            </LinkButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverageRiders;
