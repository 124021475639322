import { LinkButton } from '@bswing/uhc-core';

const ShopperProfileHelpCTA = () => {
  return (
    <div className="mb-30">
      <div className="d-flex flex-column flex-lg-row align-items-center gap-20 py-lg-30 border-lg-top">
        <img
          className="d-none d-lg-block"
          src="/assets/images/full-brand-icon-library-communication-ic-phone-2-c-on-blue@2x.png"
          alt=""
          style={{ height: 48, width: 49 }}
        />
        <div className="flex">
          <h2 className="heading-3 mb-5 text-gray-8">Need help?</h2>
          <p className="mb-5">
          Speak with a Medicare Plan expert toll-free, 7 a.m. to 11 p.m. ET Monday - Friday, 9 a.m. to 5 p.m. ET Saturday
          </p>
          <LinkButton>
            <span className="underline">1-855-939-4744</span> (TTY 711)
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

export default ShopperProfileHelpCTA;
