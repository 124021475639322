import { useState } from 'react';
import FilterButton from 'components/filter-button/filter-button';
import { MAHZFilters } from 'components/filter-group/filter-group-data';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, A11y, Navigation } from 'swiper/modules';
import { v4 as uuidv4 } from 'uuid';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'components/carousel/slick.scss';
import './plan-list-filters.scss';

const PlanListFiltersHZ = (props) => {
  const { onFilterChange = () => {}, resetAll = () => {} } = props;

  const copyFilters = JSON.parse(JSON.stringify(MAHZFilters));

  const [filterButtons, setFilterButtons] = useState(copyFilters);
  const [selectedNumber, setSelectedNumber] = useState(-1);
  const [swiper, setSwiper] = useState(null);

  const resetFilters = () => {
    setFilterButtons(JSON.parse(JSON.stringify(MAHZFilters)));
    setSelectedNumber(-1);
    resetAll();
  };

  const reorderFilters = (event) => {
    let newOrder = [...filterButtons];

    // find the index number of the item we checked in the array
    const checkedItem = newOrder.findIndex(
      (el) => el.label === event.target.value
    );

    // pull the object out of the array
    let checkedObject = newOrder.splice(checkedItem, 1)[0];

    if (event.target.checked) {
      // change json isChecked to correct state
      checkedObject.isChecked = true;

      // put object to the right of any currently selected items
      const checkedIndex = newOrder.findLastIndex(
        (el) => el.isChecked === true
      );

      // if there's no checked items, put it in first spot, otherwise past the last one
      if (checkedIndex < 0) {
        newOrder.unshift(checkedObject);
      } else {
        newOrder.splice(checkedIndex + 1, 0, checkedObject);
      }

      setFilterButtons(newOrder);
      setSelectedNumber(selectedNumber + 1);

      swiper.slideTo(0);

      return;
    }

    // Unchecking functionality

    // change isChecked in the JSON
    checkedObject.isChecked = false;

    // find locationt put unchecked object
    const previousLocation = newOrder.findIndex(
      (el) => el.order === checkedObject.order - 1 && el.isChecked === false
    );

    // put it in that location, plus the number of selectedNumber states over
    if (previousLocation < 0) {
      newOrder.splice(selectedNumber, 0, checkedObject);
    } else {
      newOrder.splice(previousLocation + 1, 0, checkedObject);
    }

    setSelectedNumber(selectedNumber - 1);
    setFilterButtons(newOrder);
    swiper.slideTo(0);
  };

  return (
    <fieldset className="filter-button-list">
      <legend className="heading-5 text-blue-primary mb-10">Filter by:</legend>
      <div className="filter-contain">
        <Swiper
          slidesPerView={'auto'}
          modules={[FreeMode, A11y, Navigation]}
          centeredSlides={false}
          spaceBetween={8}
          navigation={true}
          onSwiper={setSwiper}
        >
          {filterButtons?.map((filter) => (
            <SwiperSlide key={uuidv4()}>
              <FilterButton
                key={uuidv4()}
                label={filter.label}
                checked={filter.isChecked}
                onFilterChange={(event) => onFilterChange(event)}
                reorderFilters={(event) => reorderFilters(event)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {selectedNumber > -1 && (
          <button
            className="filters-clear uhc-link-button"
            onClick={resetFilters}
          >
            Clear all
          </button>
        )}
      </div>
    </fieldset>
  );
};

export default PlanListFiltersHZ;
