import { Tile } from '@bswing/uhc-core';
import { withBreakpoints } from 'react-breakpoints';

const QUOTES = [
  {
    author: 'Ann S.',
    authorTitle: 'AARP Medicare Supplement Planholder',
    quote:
      '“For 20 years, it is the only plan I have had. I have had no reason to change.”'
  },
  {
    author: 'Thomas Y.',
    authorTitle: 'AARP Medicare Supplement Planholder',
    quote:
      '“The plan has been great. I have not had a large need for the Supplement Plan but it is comforting knowing that it is there when and if I need it.”'
  },
  {
    author: 'Mary Ann M.',
    authorTitle: 'AARP Medicare Supplement Planholder',
    quote:
      "“I do very little work using and/or managing the plan. It's easy to understand and seamless to use.”"
  }
];

const MSHomeTestimonials = ({ currentBreakpoint }) => {
  return (
    <div className="bg-teal-quaternary">
      <div className="container container-lg">
        <div className="row py-60">
          <div className="column column-12 column-lg-8 column-lg-shift-2 text-center">
            <div className="text-large mb-10">
              Testimonials from planholders
            </div>
            <div className="display-large">
              See what some of our members are saying about their plans
            </div>
          </div>
        </div>

        <div className="row">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row align-items-stretch justify-content-center gap-20 mb-n100">
              {QUOTES.map((quote, index) => (
                <Tile
                  className="flex"
                  id={`tile-${index + 1}`}
                  key={index}
                  style={
                    currentBreakpoint === 'mobile'
                      ? { maxWidth: 'none' }
                      : { maxWidth: 300 }
                  }
                >
                  <div className="height-100 d-flex flex-column align-items-start justify-content-between">
                    <div>
                      <img
                        className="d-block mb-30"
                        src="/assets/icons/quote-icon@2x.png"
                        alt=""
                        style={{ height: 27, width: 27 }}
                      />
                      <div className="mb-30 heading-4 text-sans-serif text-bold text-italic">
                        {quote.quote}
                      </div>
                    </div>
                    <div>
                      <div className="text-bold">{quote.author}</div>
                      <div>{quote.authorTitle}</div>
                    </div>
                  </div>
                </Tile>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withBreakpoints(MSHomeTestimonials);
