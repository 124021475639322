const MSHomeDisclaimers = (props) => {
  return (
    <div className="container container-lg mb-40">
      <div className="row">
        <div className="column column-12">
          <div className="border border-thick rounded-xl">
            <div className="uhc-vpp-scroll-pane" tabIndex={0}>
              <div className="d-flex flex-column gap-20 p-30">
                <h2 className="heading-3">Scroll for Important Disclaimers</h2>
                <p>
                  AARP endorses the AARP Medicare Supplement Insurance Plans,
                  insured by UnitedHealthcare. UnitedHealthcare pays royalty
                  fees to AARP for the use of its intellectual property. These
                  fees are used for the general purposes of AARP. AARP and its
                  affiliates are not insurers. AARP does not employ or endorse
                  agents, brokers or producers.
                </p>
                <p>
                  You must be an AARP member to enroll in an AARP Medicare
                  Supplement Plan.
                </p>
                <p>
                  AARP Medicare Supplement Plans are insured by UnitedHealthcare
                  Insurance Company, 185 Asylum Street, Hartford, CT 06103 or
                  UnitedHealthcare Insurance Company of America, 1600 McConnor
                  Parkway, Floor 2, Schaumburg, IL 60173. Each insurer has sole
                  financial responsibility for its products. Policy Form No. GRP
                  79171 GPS-1 (G-36000-4).
                </p>
                <p>
                  <b>
                    In some states, plans may be available to persons under age
                    65 who are eligible for Medicare by reason of disability or
                    End-Stage Renal Disease.
                  </b>
                </p>
                <p>
                  <b>
                    Not connected with or endorsed by the U.S. Government or the
                    federal Medicare program.
                  </b>
                </p>
                <p>
                  <b>
                    This is a solicitation of insurance. A licensed insurance
                    agent/producer may contact you.
                  </b>
                </p>
                <p>
                  THESE PLANS HAVE ELIGIBILITY REQUIREMENTS, EXCLUSIONS AND
                  LIMITATIONS. FOR COSTS AND COMPLETE DETAILS (INCLUDING
                  OUTLINES OF COVERAGE), CALL A LICENSED INSURANCE
                  AGENT/PRODUCER AT THE TOLL-FREE NUMBER SHOWN.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-40">
        <div className="column column-12">WBXXXXXST</div>
      </div>
    </div>
  );
};

export default MSHomeDisclaimers;
