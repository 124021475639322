import './sam.scss';

import { useState, useRef, useEffect } from 'react';
import { event } from '@abyss/web/tools/event';
import PhoneModal from 'partials/phone-modal/phone-modal';
import useOutsideClick from 'partials/outside-detect/outside-detect';
import classNames from 'classnames';

const Sam = (props) => {
  const [isPhoneModalShown, setIsPhoneModalShown] = useState(false);
  const [isChatModalShown, setIsChatModalShown] = useState(false);
  const [isDropShown, setIsDropShown] = useState(
    localStorage.getItem('sam3')
      ? JSON.parse(localStorage.getItem('sam3'))
      : false
  );
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (isChatModalShown === false) {
      return;
    }

    setIsChatModalShown(false);
  });

  const handleButtonState = () => {
    localStorage.setItem('sam3', !isDropShown);
    setIsDropShown(!isDropShown);
  };

  return (
    <div className="sam">
      <PhoneModal
        isShown={isPhoneModalShown}
        onCloseComplete={() => setIsPhoneModalShown(false)}
      />
      <div
        ref={ref}
        className={classNames('chat-modal', {
          isOpen: isChatModalShown,
          isClosed: !isChatModalShown
        })}
      >
        <img
          src="/assets/images/chat-modal.png"
          alt="Chat with us"
          style={{ maxWidth: '100%' }}
        />
      </div>
      <div className="samThree samThree__drop">
        <div
          className={classNames('samDrop', {
            isOpen: isDropShown,
            isClosed: !isDropShown
          })}
        >
          <button className="samDrop__button" onClick={handleButtonState}>
            <span>Contact us</span>
            <span>
              <svg
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.825 0.912598L5 4.72926L1.175 0.912598L0 2.0876L5 7.0876L10 2.0876L8.825 0.912598Z"
                  fill="#196ECF"
                />
              </svg>
            </span>
          </button>
          <div className="samDrop__menu">
            <button
              onClick={() => {
                setIsChatModalShown(true);
              }}
            >
              <span>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 1.5H3C2.175 1.5 1.5075 2.175 1.5075 3L1.5 16.5L4.5 13.5H15C15.825 13.5 16.5 12.825 16.5 12V3C16.5 2.175 15.825 1.5 15 1.5ZM5.25 6.75H12.75C13.1625 6.75 13.5 7.0875 13.5 7.5C13.5 7.9125 13.1625 8.25 12.75 8.25H5.25C4.8375 8.25 4.5 7.9125 4.5 7.5C4.5 7.0875 4.8375 6.75 5.25 6.75ZM9.75 10.5H5.25C4.8375 10.5 4.5 10.1625 4.5 9.75C4.5 9.3375 4.8375 9 5.25 9H9.75C10.1625 9 10.5 9.3375 10.5 9.75C10.5 10.1625 10.1625 10.5 9.75 10.5ZM12.75 6H5.25C4.8375 6 4.5 5.6625 4.5 5.25C4.5 4.8375 4.8375 4.5 5.25 4.5H12.75C13.1625 4.5 13.5 4.8375 13.5 5.25C13.5 5.6625 13.1625 6 12.75 6Z"
                    fill="#196ECF"
                  />
                </svg>
              </span>
              <span>Chat now</span>
            </button>
            <button
              onClick={() => {
                setIsPhoneModalShown(true);
              }}
            >
              <span>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.4223 11.4451L12.5173 11.2276C12.0598 11.1751 11.6098 11.3326 11.2873 11.6551L9.90729 13.0351C7.78479 11.9551 6.04479 10.2226 4.96479 8.09257L6.35229 6.70507C6.67479 6.38257 6.83229 5.93257 6.77979 5.47507L6.56229 3.58507C6.47229 2.82757 5.83479 2.25757 5.06979 2.25757H3.77229C2.92479 2.25757 2.21979 2.96257 2.27229 3.81007C2.66979 10.2151 7.79229 15.3301 14.1898 15.7276C15.0373 15.7801 15.7423 15.0751 15.7423 14.2276V12.9301C15.7498 12.1726 15.1798 11.5351 14.4223 11.4451Z"
                    fill="#196ECF"
                  />
                </svg>
              </span>
              <span>1-855-888-1640</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sam;
