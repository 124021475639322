import { Modal } from '@bswing/uhc-core';

const PhoneModal = (props) => {
  const { isShown, onCloseComplete = () => {} } = props;
  return (
    <Modal
      isFullscreen={false}
      isShown={isShown}
      title={<div className="heading-2"></div>}
      onCloseComplete={onCloseComplete}
      onOpenComplete={() => {}}
      aria-label="Questions? We’re ready to help.?"
    >
      {({ close }) => (
        <div className="container">
          <img
            src="/assets/images/phone-modal.png"
            alt="Call UHC"
            style={{ maxWidth: '100%', padding: '10px 30px' }}
          />
        </div>
      )}
    </Modal>
  );
};

export default PhoneModal;
