import { Button } from '@bswing/uhc-core';
import { withBreakpoints } from 'react-breakpoints';

const MSPlanDetailPlansSection = (props) => {
  const { currentBreakpoint } = props;

  return (
    <div className="bg-teal-quaternary py-40 py-lg-80">
      <div className="container container-lg">
        <div className="row">
          <div className="column column-12 column-lg-10 column-lg-shift-1">
            <div className="d-flex flex-column flex-lg-row align-items-center">
              <div className="mr-lg-60 mb-20 mb-lg-0 flex-auto">
                <img
                  className="d-block height-auto"
                  src="/assets/images/medicare-advantage-plans@2x.png"
                  style={
                    currentBreakpoint === 'mobile'
                      ? { width: '100%', maxWidth: '100%' }
                      : { width: 407, maxWidth: '100%' }
                  }
                  alt=""
                />
              </div>
              <div className="flex-auto">
                <div className="heading-2 text-dark mb-20">
                  See all our Medicare Supplement plans
                </div>
                <div>
                  <Button isSecondary={true} isOutlined={true}>
                    View all 14 plans
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withBreakpoints(MSPlanDetailPlansSection);
