import React, { Fragment, useEffect, useState } from 'react';
import ReactBreakpoints, { Media } from 'react-breakpoints';
import { Accordion } from '@bswing/uhc-core';
import SlideTable from 'components/slide-table/slide-table';
import msPlanSections from 'data/msPlanSections';
import MSPlanCompareHeader from './ms-plan-compare-header/ms-plan-compare-header';
import MSPlanCompareStickyHeader from './ms-plan-compare-sticky-header/ms-plan-compare-sticky-header';
// import MSHomeFAQs from 'pages/ms-home/ms-home-faqs/ms-home-faqs';
import MSHomeHeadline from 'pages/ms-home/ms-home-headline/ms-home-headline';
import MSPlanListCallout from 'pages/ms-plan-list/ms-plan-list-callout/ms-plan-list-callout';
import MSPlanListFAQs from 'pages/ms-plan-list/ms-plan-list-faqs/ms-plan-list-faqs';
import MSPlanListDisclaimers from 'pages/ms-plan-list/ms-plan-list-disclaimers/ms-plan-list-disclaimers';
import MSPlanCompareImportantInformation from './ms-plan-compare-important-information/ms-plan-compare-important-information';
// import MSPlanListInfo from 'pages/ms-plan-list/ms-plan-list-important-information/ms-plan-list-important-information';
// import MSHomeDisclosure from 'pages/ms-home/ms-home-disclaimers/ms-home-disclaimers';
import useHighlight from 'hooks/useHighlight';
import HighlightBanner from '../plan-compare/plan-compare-highlight-banner';

const breakpoints = {
  mobile: 320,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200
};

const MSPlanCompare = (props) => {
  const { data = [], showInformationAdded, showTabs } = props;

  const [isHighlighting, setIsHighlighting] = useState(false);
  const [offset, setOffset] = useState(0);

  // functionality for the highlight toggle
  useHighlight(isHighlighting, offset);

  const handleHighlightChange = () => {
    setIsHighlighting(!isHighlighting);
  };

  const handleNextClick = (breakpoints, currentBreakpoint) => {
    incrementOffset(breakpoints, currentBreakpoint);
  };

  const handlePrevClick = (breakpoints, currentBreakpoint) => {
    decrementOffset(breakpoints, currentBreakpoint);
  };

  const handleSwipe = (event, breakpoints, currentBreakpoint) => {
    event.dir === 'Left'
      ? incrementOffset(breakpoints, currentBreakpoint)
      : decrementOffset(breakpoints, currentBreakpoint);
  };

  const decrementOffset = (breakpoints, currentBreakpoint) => {
    const newOffset = Math.max(0, offset - 1);
    setOffset(newOffset);
  };

  const incrementOffset = (breakpoints, currentBreakpoint) => {
    const maxOffsetAllowance =
      breakpoints[currentBreakpoint] < breakpoints.tablet ? 1 : 2;
    const maxOffset = data.length - maxOffsetAllowance;
    const newOffset = Math.min(offset + 1, maxOffset);
    setOffset(newOffset);
  };

  const calculateTableWidth = (breakpoints, currentBreakpoint) => {
    const columnCount =
      breakpoints[currentBreakpoint] >= breakpoints.tablet ? 4 : 2;
    const nonPlanColumnCount =
      breakpoints[currentBreakpoint] >= breakpoints.tablet ? 2 : 1;

    const widthScale = (data.length + nonPlanColumnCount) / columnCount;

    return {
      width: `calc(${widthScale * 100}%)`
    };
  };

  useEffect(() => {
    const fixOffset = () => {
      if (offset > data.length - 2 && window.innerWidth >= 768) {
        setOffset(data.length - 2);
      }
    };

    window.addEventListener('resize', fixOffset);

    return () => window.removeEventListener('resize', fixOffset);
  }, [data, offset]);

  const renderHeader = (sectionId) => {
    return (
      <SlideTable.Row className="d-none d-lg-table-row">
        <SlideTable.Cell className="bg-gray-2" isHeader={true} isFrozen={true}>
          &nbsp;
        </SlideTable.Cell>
        {data &&
          data.map((plan, index) => (
            <SlideTable.Cell
              className="bg-gray-2"
              isHeader={true}
              isVCentered={true}
              key={index}
            >
              <span
                className="text-bold"
                style={{ textAlign: 'center', display: 'block', width: '100%' }}
              >
                You Pay
              </span>
            </SlideTable.Cell>
          ))}
        <SlideTable.Cell className="bg-gray-2" isHeader={true} />
      </SlideTable.Row>
    );
  };

  const renderRows = (sectionId) => {
    return msPlanSections
      .find((section) => section.id === sectionId)
      .rows?.map((row, rowIndex) => (
        <Fragment key={rowIndex}>
          <SlideTable.Row>
            <SlideTable.Cell
              isVCentered={true}
              isHeader={true}
              isFrozen={true}
              isMobileHeading={true}
              colSpan={2}
            >
              <span
                className="text-bold"
                dangerouslySetInnerHTML={{ __html: row }}
              />
            </SlideTable.Cell>
          </SlideTable.Row>
          <SlideTable.Row>
            <SlideTable.Cell isVCentered={false} isFrozen={true}>
              <span
                className="text-bold"
                dangerouslySetInnerHTML={{ __html: row }}
              />
            </SlideTable.Cell>
            {data &&
              data.map((plan, planIndex) => (
                <SlideTable.Cell key={planIndex} isVCentered={true}>
                  {plan.sections.find((section) => section.id === sectionId) &&
                    renderValue(
                      plan.sections.find((section) => section.id === sectionId)
                        .values[rowIndex]
                    )}
                </SlideTable.Cell>
              ))}
            <SlideTable.Cell />
          </SlideTable.Row>
        </Fragment>
      ));
  };

  const renderValue = (value) => {
    if (value) {
      return <div dangerouslySetInnerHTML={{ __html: value }} />;
    }

    return null;
  };

  return (
    <ReactBreakpoints breakpoints={breakpoints}>
      <Media>
        {({ breakpoints, currentBreakpoint }) => (
          <div className="msCompare">
            <MSPlanCompareHeader
              isHighlighting={isHighlighting}
              offset={offset}
              plans={data}
              showInformationAdded={showInformationAdded}
              showTabs={showTabs}
              onHighlightChange={handleHighlightChange}
              onNextClick={() =>
                handleNextClick(breakpoints, currentBreakpoint)
              }
              onPrevClick={() =>
                handlePrevClick(breakpoints, currentBreakpoint)
              }
            />
            <MSPlanCompareStickyHeader
              isHighlighting={isHighlighting}
              offset={offset}
              plans={data}
              showInformationAdded={showInformationAdded}
              onHighlightChange={handleHighlightChange}
              onNextClick={() =>
                handleNextClick(breakpoints, currentBreakpoint)
              }
              onPrevClick={() =>
                handlePrevClick(breakpoints, currentBreakpoint)
              }
              onSwiped={(event) =>
                handleSwipe(event, breakpoints, currentBreakpoint)
              }
            />
            <div className="container container-lg">
              <div className="row">
                <div className="column column-12">
                  <SlideTable
                    offset={offset}
                    showNextPreview={false}
                    onSwiped={(event) =>
                      handleSwipe(event, breakpoints, currentBreakpoint)
                    }
                    style={calculateTableWidth(breakpoints, currentBreakpoint)}
                  >
                    {renderRows('plan-summary')}
                  </SlideTable>
                </div>
              </div>
            </div>
            <div className="container container-lg my-60">
              {msPlanSections
                .filter(
                  (section) =>
                    section.isAccordion && section.id !== 'plan-summary'
                )
                .map((section, index) => (
                  <div className="row" key={section.id}>
                    {section.name === 'Doctor Visits' && !isHighlighting && (
                      <div className="column column-12">
                        <div>
                          <HighlightBanner />
                        </div>
                      </div>
                    )}
                    <div className="column column-12">
                      <Accordion
                        className="border-top"
                        id={`plan-compare-section-${section.id}`}
                        arrowColorOverride="#196ecf"
                        isExpanded={true}
                        isMinimal={true}
                        header={
                          <div
                            className="heading-2 text-serif text-blue-primary"
                            dangerouslySetInnerHTML={{ __html: section.name }}
                          />
                        }
                      >
                        <div className="pb-30">
                          <SlideTable
                            offset={offset}
                            header={renderHeader(section.id)}
                            isRounded={true}
                            showNextPreview={false}
                            onSwiped={(event) =>
                              handleSwipe(event, breakpoints, currentBreakpoint)
                            }
                            style={calculateTableWidth(
                              breakpoints,
                              currentBreakpoint
                            )}
                          >
                            {renderRows(section.id)}
                          </SlideTable>
                        </div>
                      </Accordion>
                    </div>
                  </div>
                ))}
              <div class="row">
                <div class="column column-12">
                  <Accordion
                    className="border-top"
                    id={`plan-compare-section-resources}`}
                    arrowColorOverride="#196ecf"
                    isExpanded={true}
                    isMinimal={true}
                    header={
                      <div className="heading-2 text-serif text-blue-primary">
                        Important Resources
                      </div>
                    }
                  >
                    <div className="pb-30">
                      <div className="text-small text-regular mt-5">
                        Valuable resources that provide important information
                        about the coverage and benefits of the plan as well as
                        other documents that you may find useful.
                      </div>
                      <div className="resource-list mt-30">
                        <img
                          src="/assets/images/resource-list.jpg"
                          alt="resource list placeholder"
                          style={{ maxWidth: '100%' }}
                        />
                      </div>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="container container-lg my-20">
              {msPlanSections
                .filter((section) => !section.isAccordion)
                .map((section) => (
                  <div className="row" key={section.id}>
                    <div className="column column-12">
                      <div className="pb-30">
                        <SlideTable
                          offset={offset}
                          header={renderHeader(section.id)}
                          isRounded={true}
                          showNextPreview={false}
                          onSwiped={(event) =>
                            handleSwipe(event, breakpoints, currentBreakpoint)
                          }
                          style={calculateTableWidth(
                            breakpoints,
                            currentBreakpoint
                          )}
                        >
                          {renderRows(section.id)}
                        </SlideTable>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <MSPlanCompareImportantInformation />
            <MSPlanListCallout />
            <MSHomeHeadline headline="Find answers to some of your Medicare Supplement questions." />
            <MSPlanListFAQs />
            <MSPlanListDisclaimers />
          </div>
        )}
      </Media>
    </ReactBreakpoints>
  );
};

export default MSPlanCompare;
