import { Fragment } from 'react';

const MAPDFilters = [
  {
    name: 'Plan Type',
    filters: [
      {
        label: 'HMO',
        isChecked: false
      },
      {
        label: 'PPO',
        isChecked: false
      }
    ]
  },
  {
    name: 'Additional Benefits',
    filters: [
      {
        label: 'Dental coverage',
        isChecked: false
      },
      {
        label: 'Optional dental rider',
        isChecked: false
      },
      {
        label: 'Eyewear',
        isChecked: false
      },
      {
        label: 'Hearing aids',
        isChecked: false
      },
      {
        label: 'Over-the-counter (OTC)',
        isChecked: false
      },
      {
        label: 'Fitness',
        isChecked: false
      }
    ]
  }
];

const SNPFilters = [
  {
    name: 'Special Needs',
    filters: [
      {
        label: 'For people with Medicaid',
        isChecked: false
      },
      {
        label:
          'For people with diabetes, cardiovascular disorders, or chronic heart failure',
        isChecked: false
      },
      {
        label: 'For people in a nursing home',
        isChecked: false
      },
      {
        label: 'For people in an assisted living',
        isChecked: false
      }
    ]
  },
  {
    name: 'Additional Benefits',
    filters: [
      {
        label: 'Comprehensive dental',
        isChecked: false
      },
      {
        label: 'Optional dental rider',
        isChecked: false
      },
      {
        label: 'Food allowance',
        isChecked: false
      },
      {
        label: 'OTC',
        isChecked: false
      },
      {
        label: 'Transportation',
        isChecked: false
      },
      {
        label: 'Eyewear',
        isChecked: false
      },
      {
        label: 'Hearing aids',
        isChecked: false
      },
      {
        label: 'Fitness',
        isChecked: false
      }
    ]
  }
];

const MAHZFilters = [
  {
    label: 'PPO',
    isChecked: false,
    order: 0
  },
  {
    label: 'Comprehensive dental',
    isChecked: false,
    order: 1
  },
  {
    label: 'OTC',
    isChecked: false,
    order: 2
  },
  {
    label: '$0 Premium',
    isChecked: false,
    order: 3
  },
  {
    label: 'Drug coverage',
    isChecked: false,
    order: 4
  },
  {
    label: 'Fitness program',
    isChecked: false,
    order: 5
  },
  {
    label: 'Eyewear allowance',
    isChecked: false,
    order: 6
  },
  {
    label: 'Part B giveback',
    isChecked: false,
    order: 7
  }
];

const MSFilters = [
  {
    name: 'Plan Category',
    contentPrompt: 'Learn more about plan categories',
    content: (
      <Fragment>
        <p>
          UnitedHealthcare offers a wide selection of Medicare Supplement plans
          to help meet your healthcare needs. To help with plan selection, we've
          added some categories.
        </p>
        <ul className="d-flex flex-column gap-10">
          <li>
            <b>Most comprehensive</b> coverage includes plans that offer low to
            no out-of-pocket costs.
          </li>
          <li>
            <b>Most popular</b> are plans with the highest market share
            nationwide.
          </li>
          <li>
            <b>Lower premium</b> includes plans with lower premium and pay out
            of pocket for care when you need it.
          </li>
          <li>
            <b>Health Savings Account (HSAs)</b> includes some of the plans that
            you can use your HSA to help pay for copays, deductible and other
            out-of-pocket costs.
          </li>
        </ul>
      </Fragment>
    ),
    filters: [
      {
        label: 'Most comprehensive plans',
        isChecked: false
      },
      {
        label: 'Most popular plans',
        isChecked: false
      },
      {
        label: 'Lower premium plans',
        isChecked: false
      },
      {
        label: 'Best to use a Health Savings Account (HSA)',
        isChecked: false
      }
    ]
  },
  {
    name: 'Additional Discounts & Services',
    contentPrompt: 'Learn more about additional discounts & services',
    content: (
      <p>
        Some AARP Medicare Supplement Plans come along with{' '}
        <b>additional services and discounts</b> like free gym memberships, and
        discounts on dental, vision and hearing.
      </p>
    ),
    filters: [
      {
        label: 'Gym membership',
        isChecked: false
      },
      {
        label: 'Dental discounts',
        isChecked: false
      },
      {
        label: 'Hearing discounts',
        isChecked: false
      },
      {
        label: 'Vision discounts',
        isChecked: false
      }
    ]
  }
];

export { MAPDFilters, MSFilters, SNPFilters, MAHZFilters };
