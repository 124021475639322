import { Accordion } from '@bswing/uhc-core';
import './footer.scss';

const Footer = (props) => {
  return (
    <footer className="uhc-footer" role="contentinfo">
      <div className="container container-lg pt-20 pt-lg-40 pb-40">
        <div className="row mb-40">
          <div className="column column-12 column-lg-3">
            <div className="d-none d-lg-block">
              <h3
                className="text-small text-bold text-gray-3 text-sans-serif"
                id="shop-plans-list-heading"
              >
                Shop Plans
              </h3>
              <nav aria-label="Footer shop plans navigation">
                <ul
                  className="list-style-none m-0 p-0"
                  aria-labelledby="shop-plans-list-heading"
                >
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Medicare Advantage Plans
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Dual Special Needs Plans
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Medicare Supplement Insurance Plans
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Medicare Prescription Drug Plans
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="uhc-footer__divider d-lg-none"></div>
            <Accordion
              className="d-lg-none"
              id="accordion-1"
              header={<div className="text-bold">Shop Plans</div>}
              arrowColorOverride="#ffffff"
              isMinimal={true}
            >
              <nav aria-label="Footer shop plans navigation">
                <ul className="list-style-none m-0 p-0 mb-40">
                  <li>
                    <a className="text-bold" href="#link">
                      Medicare Advantage Plans
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Dual Special Needs Plans
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Medicare Supplement Insurance Plans
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Medicare Prescription Drug Plans
                    </a>
                  </li>
                </ul>
              </nav>
            </Accordion>
          </div>
          <div className="column column-12 column-lg-3">
            <div className="d-none d-lg-block">
              <h3
                className="text-small text-bold text-gray-3 text-sans-serif"
                id="tools-resources-list-heading"
              >
                Tools &amp; Resources
              </h3>
              <nav aria-label="Footer tools and resources navigation">
                <ul
                  className="list-style-none m-0 p-0"
                  aria-labelledby="tools-resources-list-heading"
                >
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Plan Recommendation
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Drug Cost Estimator
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Pharmacy Search
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Provider Search
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="uhc-footer__divider d-lg-none"></div>
            <Accordion
              className="d-lg-none"
              id="accordion-2"
              header={<div className="text-bold">Tools and Resources</div>}
              arrowColorOverride="#ffffff"
              isMinimal={true}
            >
              <nav aria-label="Footer tools and resources navigation">
                <ul className="list-style-none m-0 p-0 mb-40">
                  <li>
                    <a className="text-bold" href="#link">
                      Plan Recommendation
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Drug Cost Estimator
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Pharmacy Search
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Provider Search
                    </a>
                  </li>
                </ul>
              </nav>
            </Accordion>
          </div>
          <div className="column column-12 column-lg-3">
            <div className="d-none d-lg-block">
              <h3
                className="text-small text-bold text-gray-3 text-sans-serif"
                id="learn-about-medicare-list-heading"
              >
                Learn About Medicare
              </h3>
              <nav aria-label="Footer learn about medicare navigation">
                <ul
                  className="list-style-none m-0 p-0"
                  aria-labelledby="learn-about-medicare-list-heading"
                >
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Introduction to Medicare
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Eligibility
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Coverage Choices
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Medicare FAQ
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="uhc-footer__divider d-lg-none"></div>
            <Accordion
              className="d-lg-none"
              id="accordion-3"
              header={<div className="text-bold">Learn About Medicare</div>}
              arrowColorOverride="#ffffff"
              isMinimal={true}
            >
              <nav aria-label="Footer learn about medicare navigation">
                <ul className="list-style-none m-0 p-0 mb-40">
                  <li>
                    <a className="text-bold" href="#link">
                      Introduction to Medicare
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Eligibility
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Coverage Choices
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Medicare FAQ
                    </a>
                  </li>
                </ul>
              </nav>
            </Accordion>
          </div>
          <div className="column column-12 column-lg-3">
            <div className="d-none d-lg-block">
              <h3
                className="text-small text-bold text-gray-3 text-sans-serif"
                id="more-list-heading"
              >
                More
              </h3>
              <nav aria-label="Footer more navigation">
                <ul
                  className="list-style-none m-0 p-0"
                  aria-labelledby="more-list-heading"
                >
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      About
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Contact
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Language Assistance{' '}
                      <img
                        className="ml-5"
                        src="/assets/icons/external-white-icon@2x.png"
                        alt="external icon"
                        style={{
                          height: 12,
                          width: 12,
                          verticalAlign: -1
                        }}
                      />
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      AARP.org{' '}
                      <img
                        className="ml-5"
                        src="/assets/icons/external-white-icon@2x.png"
                        alt="external icon"
                        style={{
                          height: 12,
                          width: 12,
                          verticalAlign: -1
                        }}
                      />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="uhc-footer__divider d-lg-none"></div>
            <Accordion
              className="d-lg-none"
              id="accordion-4"
              header={<div className="text-bold">More</div>}
              arrowColorOverride="#ffffff"
              isMinimal={true}
            >
              <nav aria-label="Footer more navigation">
                <ul className="list-style-none m-0 p-0 mb-40">
                  <li>
                    <a className="text-bold" href="#link">
                      About
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Contact
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      Language Assistance
                    </a>
                  </li>
                  <li className="mt-20">
                    <a className="text-bold" href="#link">
                      AARP.org
                    </a>
                  </li>
                </ul>
              </nav>
            </Accordion>
            <div className="uhc-footer__divider d-lg-none"></div>
          </div>
        </div>
        <div className="row">
          <div className="column column-12">
            <label
              className="d-none d-lg-inline-block mr-10"
              htmlFor="Your State"
            >
              Your State:
            </label>
            <select id="footer-state" aria-label="Your State">
              <option>Minnesota</option>
            </select>
          </div>
        </div>
        <div className="row mb-20 mb-lg-0">
          <div className="column column-12">
            <div className="uhc-footer__divider d-none d-lg-block my-20"></div>
          </div>
        </div>
        <div className="row">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row align-items-lg-start justify-content-between text-small">
              <div className="text-white text-small mb-20 mb-lg-0">
                &copy; {new Date().getFullYear()} United HealthCare Services,
                Inc. All rights reserved.
                <br />
                <br />
                Last updated: {new Date().toLocaleDateString()} at 12:01 AM CT |
                Y0066_AARPMedicarePlans
              </div>
              <nav aria-label="Footer secondary navigation">
                <ul className="d-flex flex-column flex-lg-row list-style-none m-0 p-0">
                  <li>
                    <a href="#link">Site Map</a>
                  </li>
                  <li className="mt-15 mt-lg-0 ml-lg-20">
                    <a href="#link">Privacy Policy</a>
                  </li>
                  <li className="mt-15 mt-lg-0 ml-lg-20">
                    <a href="#link">Terms of Use</a>
                  </li>
                  <li className="mt-15 mt-lg-0 ml-lg-20">
                    <a href="#link">Disclaimers</a>
                  </li>
                  <li className="mt-15 mt-lg-0 ml-lg-20">
                    <a href="#link">Agents &amp; Brokers</a>
                  </li>
                  <li className="mt-15 mt-lg-0 ml-lg-20">
                    <a href="#link">Accessibility</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-1">
        <div className="container container-lg">
          <div className="row">
            <div className="column column-12">
              <Accordion
                id="accordion-disclaimer"
                header={
                  <div className="text-bold text-blue-secondary text-small">
                    View all disclaimer information
                  </div>
                }
                // arrowColorOverride="#ffffff"
                isMinimal={true}
              >
                <p className="mb-40">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
