const PlanCompareDisclaimer = () => {
  return (
    <div className="container container-lg my-20 my-lg-40">
      <div className="row">
        <div className="column column-12">
          <p className="mb-20">
            *Once you reach the Coverage Gap Stage, you pay copays or
            coinsurance defined by your plan for all Tier 1 through Tier 5 drugs
            regardless of whether or not your full deductible has been met.
          </p>
          <p className="mb-20">
            <sup>1</sup> The amount shown does not include the Part B premium
            you already pay to the government. Your plan costs, including
            premiums and drug costs, may be different if you get Extra Help from
            the government or if you have Medicaid.
          </p>
          <p className="mb-20">
            <sup>2</sup> Some limitations may apply. See the plan's Evidence of
            Coverage (PDF) for details.
          </p>
          <p className="mb-20">
            <sup>3</sup> Some limitations may apply. See the plan's Summary of
            Benefits (PDF) for details.
          </p>
          <p className="mb-60">
            <sup>4</sup> Amounts may vary depending on the level of care
            provided or the type of health care services you receive.
          </p>
          <p className="text-bold text-large mb-20">DISCLAIMERS</p>
          <p className="text-bold mb-20">More information</p>
          <p className="mb-20">
            The list of covered drugs was last updated on 12/01/2022.
          </p>
          <p className="mb-20">
            Copay or coinsurance amounts may change if you have a limited
            income.
          </p>
          <p className="mb-20">
            <span className="text-bold">
              The drug costs displayed are estimates and may vary based on the
              specific quantity, strength and/or dosage of the medication and
              the pharmacy you use.
            </span>{' '}
            It may be important to look beyond your current needs at the value
            of having Medicare prescription drug insurance. Enrolling when you
            become eligible will help give you peace of mind, should your drug
            needs become more significant in the future. It may also help you
            avoid the Medicare late enrollment penalty.
          </p>
          <p className="mb-20">
            The formulary, pharmacy network, and/or provider network may change
            at any time. You will receive notice when necessary.
          </p>
          <p className="mb-20">
            Amounts displayed do not include taxes or injection fees.
          </p>
          <p className="mb-20">
            During the coverage gap, amounts displayed for brand name drugs
            include a 70% manufacturer discount. However, this discount is based
            on pharmaceutical manufacturers' participation and may not apply to
            all brand drugs. You pay 25% of the total cost for brand name drugs,
            for any drug tier during the coverage gap.
          </p>
          <p className="mb-20">
            The 25% drug coinsurance within the coverage gap is based on an
            assessment that the drug is defined as a generic drug according to
            Part D rules.
          </p>
          <p className="mb-20">
            If your drug is not eligible for coverage under Medicare Part D, you
            will pay the full cost of the drug.
          </p>
          <p className="mb-20 text-bold">
            Preferred Mail Home Delivery through OptumRx
          </p>
          <p className="mb-20">
            2023 Savings BenefitSavings apply during the initial coverage
            period, which begins after the payment of your required deductible
            (if any) and ends when the total cost of your drugs (paid by
            UnitedHealthcare, you and others) reaches $4,660.
          </p>
          <p className="mb-20 text-bold">Enrollment Disclaimer Information</p>
          <p className="mb-20">
            Plans are insured through UnitedHealthcare Insurance Company or one
            of its affiliated companies, a Medicare Advantage organization with
            a Medicare contract and/or a Medicare-approved Part D sponsor.
            Enrollment in the plan depends on the plan's contract renewal with
            Medicare.
          </p>
          <p className="mb-20">
            UnitedHealthcare Insurance Company pays royalty fees to AARP for the
            use of its intellectual property. These fees are used for the
            general purposes of AARP. AARP and its affiliates are not insurers.
            You do not need to be an AARP member to enroll. AARP encourages you
            to consider your needs when selecting products and does not make
            specific product or pharmacy recommendations for individuals.
            UnitedHealthcare contracts directly with Walgreens for this plan;
            AARP and its affiliates are not parties to that contractual
            relationship.
          </p>
          <p className="mb-20">
            <span className="text-bold">Dental (Routine/Comprehensive):</span>{' '}
            Benefits vary by plan/area. Limitations and exclusions apply. If
            your plan offers out-of-network dental coverage and you see an
            out-of-network dentist, you might be billed more. Network size
            varies by local market.
          </p>
          <p className="mb-20">
            <span className="text-bold">Fitbit:</span> Choose one device from
            approved select models every 2 years. Devices may vary by plan/area.
            Limitations and exclusions apply. Fitbit, the Fitbit logo, and
            related marks and logos are trademarks of Google LLC and/or its
            affiliates.
          </p>
          <p className="mb-20">
            <span className="text-bold">Flex Card:</span> Benefits and features
            vary by plan/area. Limitations and exclusions apply.Flex benefit
            allowance expires at the end of the plan year.Flex benefit allowance
            must be used at dental, vision or hearing providers that accept
            Visa.
          </p>
          <p className="mb-20">
            <span className="text-bold">Hearing:</span> Benefits, features,
            and/or devices vary by plan/area. Limitations and exclusions may
            apply. Other hearing exam providers are available in the
            UnitedHealthcare network. The plan only covers hearing aids from a
            UnitedHealthcare Hearing network provider.
          </p>
          <p className="mb-20">
            <span className="text-bold">Network:</span> Network size varies by
            plan and market. Benefits, features and/or devices vary by
            plan/area. Limitations and exclusions apply.
          </p>
          <p className="mb-20">
            <span className="text-bold">Over-the-Counter (OTC):</span> Benefits
            and features vary by plan/area. Limitations and exclusions apply.
            Food, OTC and utility benefits have expiration timeframes. Call your
            plan or review your Evidence of Coverage (EOC) for more information.
          </p>
          <p className="mb-20">
            <span className="text-bold">PERS:</span> Benefits, features and/or
            devices vary by plan/area. Limitations and exclusions apply. You
            must have a working landline and/or cellular phone coverage to use
            PERS.
          </p>
          <p className="mb-20">
            <span className="text-bold">Renew Active:</span> Participation in
            the Renew Active® program is voluntary. Consult your doctor prior to
            beginning an exercise program. Renew Active includes standard
            fitness membership and other offerings.
          </p>
          <p className="mb-20">
            Fitness membership equipment, classes, personalized fitness plans,
            caregiver access and events may vary by location. Certain services,
            discounts, classes, events and online fitness offerings are provided
            by affiliates of UnitedHealthcare Insurance Company or other third
            parties not affiliated with UnitedHealthcare. Participation in these
            third-party services are subject to your acceptance of their
            respective terms and policies. UnitedHealthcare is not responsible
            for the services or information provided by third parties. The
            information provided through these services is for informational
            purposes only and is not a substitute for the advice of a doctor.
            Gym network may vary in local market.
          </p>
          <p className="mb-20">
            <span className="text-bold">Vision:</span> Vision benefits vary by
            plan and are not available with all plans. Limitations and
            exclusions apply. Additional charges may apply for out-of-network
            items and services.
          </p>
          <p className="mb-20">
            This information is not a complete description of benefits.{' '}
            <a href="#link">Call us</a> for more information.
          </p>
          <p className="mb-20">
            This information is available for free in other languages. Please{' '}
            <a href="#link">contact</a> Customer Service for additional
            information.
          </p>
          <p className="mb-20">
            Esta información está disponible sin costo en otros idiomas. Para
            obtener más información <a href="#link">comuníquese</a> con nuestro
            Servicio al Cliente.
          </p>
          <p className="mb-20">
            本資訊可以其他語言免費提供。如需更多資訊，請聯絡客戶服務部。
          </p>
          <p>
            Every year, Medicare evaluates plans based on a 5-star rating
            system.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlanCompareDisclaimer;
