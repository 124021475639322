import { LinkButton } from '@bswing/uhc-core';
import PlanYearToggle from 'partials/plan-year-toggle/plan-year-toggle';

const PlanListHeader = (props) => {
  const { showPlanToggle } = props;

  return (
    <div className="container container-lg">
      <div className="row py-20 py-lg-30">
        <div className="column column-12">
          <div className="d-lg-none mb-20">
            <LinkButton>
              <img
                className="mr-10"
                src="/assets/icons/chevron-left-icon@2x.png"
                alt=""
                style={{ height: 12, width: 8, verticalAlign: -1 }}
              />
              Back to plan results
            </LinkButton>
          </div>
          {showPlanToggle && (
            <div className="mt-20">
              <PlanYearToggle />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanListHeader;
