const planSections = [
  {
    name: 'Plan Summary',
    id: 'plan-summary',
    isAccordion: true,
    rows: [
      '<span class="hl-cat">Doctor Visits</span>',
      '<span class="hl-cat">Inpatient Hospital Care<br />(Days 1-90 per benefit period)</span><div class="text-small text-regular mt-5">View Plan Details for beyond 90 days</div>',
      '<span class="hl-cat">Out-of-Pocket Maximum</span><br /><div class="text-small text-regular mt-5">This is the amount of money you pay for covered services per calendar year.</div>',
      '<span class="hl-cat">See any doctor who accepts Medicare patients?</span>',
      '<span class="hl-cat">Referral to Specialist Required?</span>',
      '<span class="hl-cat">Insurer</span>'
    ]
  },
  {
    name: 'Basic Costs',
    id: 'basic-costs',
    isAccordion: true,
    rows: [
      '<span class="hl-cat">Monthly Premium Starting at</span>',
      '<span class="hl-cat">Part A Inpatient Hospital Deductible</span>',
      '<span class="hl-cat">Annual Part B Deductible</span>',
      '<span class="hl-cat">Out-of-Pocket Maximum</span></br><div class="text-small text-regular mt-5">This is the amount of money you pay for covered services per calendar year.</div>',
      '<span class="hl-cat">Part B Excess Charges</span></br><div class="text-small text-regular mt-5">If you have Original Medicare, and the amount a doctor or other health care provider is legally permitted to charge is higher than the Medicare-approved amount, the difference is called the excess charge.</div>'
    ]
  },
  {
    name: 'Doctor Visits',
    id: 'doctor-visits',
    isAccordion: true,
    rows: [
      'Primary Care Visits',
      'Specialist Visits',
      'Referral to Specialist Required?',
      'See any doctor who accepts Medicare patients?',
      'Preventative Services'
    ]
  },
  {
    name: 'Urgent/Emergency Care',
    id: 'urgent-care',
    isAccordion: true,
    rows: [
      'Urgent Care',
      'Emergency Care',
      'Ground Ambulance Services',
      'Air Ambulance Services',
      'Foreign Travel Emergency'
    ]
  },
  {
    name: 'Hospitalization and Skilled Nursing Facility Care',
    id: 'skilled-care',
    isAccordion: true,
    rows: [
      'Inpatient Hospital Care<sup><a class="text-bold no-underline" href="#footnote-7">7</a></sup>',
      'Skilled Nursing Facility<sup><a class="text-bold no-underline" href="#footnote-7">7</a></sup>'
    ]
  },
  {
    name: 'Outpatient Care',
    id: 'outpatient-care',
    isAccordion: true,
    rows: [
      'Ambulatory Surgical Center',
      'Outpatient Hospital Services',
      'Mental Health - Outpatient'
    ]
  },
  {
    name: 'Lab/X-Ray Services & Equipment',
    id: 'lab-services',
    isAccordion: true,
    rows: [
      'Lab Services<sup><a class="text-bold no-underline" href="#footnote-9">9</a></sup>',
      'Diagnostic Radiology Services<br /><span class="text-regular">(such as MRIs/CT scans, etc.)</span>',
      'Outpatient X-Rays',
      'Durable Medical Equipment'
    ]
  },
  {
    name: 'Wellness Extras<sup><a class="text-bold no-underline text-smaller" href="#footnote-10">10</a></sup>',
    id: 'wellness-extras',
    isAccordion: true,
    rows: [
      'Dental Discount',
      'Vision Discount',
      'Hearing Discount',
      'Gym Membership'
    ]
  }
  // {
  //   name: 'More Information',
  //   id: 'more-information',
  //   isAccordion: false,
  //   rows: ['More Information']
  // }
];

export default planSections;
