import { Button } from '@bswing/uhc-core';

const NotSureWhereToStartSection = (props) => {
  return (
    <div className="container container-lg py-80 py-lg-100 text-center text-lg-left">
      <div className="row">
        <div className="column column-12 column-lg-8 column-lg-shift-2">
          <div className="d-flex flex-column-reverse flex-lg-row align-items-center gap-40 gap-lg-60">
            <div className="d-flex flex-column align-items-center align-items-lg-start gap-20 gap-lg-30">
              <div className="heading-1">Not sure where to start?</div>
              <div className="text-large">
                Answer a few simple questions and get a personalized list of
                plans ranked by what's important to you.
              </div>
              <Button isSecondary={true} isOutlined={true}>
                <span className="d-flex align-items-center gap-5">
                  <span>Get Started</span>
                  <svg
                    className="mr-n5"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      d="m10 6.157-1.41 1.41 4.58 4.59-4.58 4.59 1.41 1.41 6-6z"
                      fill="currentColor"
                      fillRule="evenodd"
                    />
                  </svg>
                </span>
              </Button>
            </div>
            <img
              src="/assets/images/plan-recommendations-illustration@2x.png"
              alt=""
              style={{ width: 283, height: 'auto', maxWidth: '100%' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotSureWhereToStartSection;
