import { Fragment } from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { LinkButton } from '@bswing/uhc-core';

const AddLinkButton = ({ onAddDoctorsClick }) => (
  <LinkButton className="text-lg-large d-block" onClick={onAddDoctorsClick}>
    <span className="d-flex align-items-center">
      <img
        className="d-block mr-5"
        src="/assets/icons/add-box@2x.png"
        alt=""
        style={{ height: 24, width: 25 }}
      />
      <span>
        Add your doctors
        <span className="d-none d-lg-inline">
          {' '}
          to see if they are in network
        </span>
      </span>
    </span>
  </LinkButton>
);

const CoverageDoctorsTable = (props) => {
  const { providerData, showDoctors, onAddDoctorsClick, currentBreakpoint } =
    props;

  if (showDoctors || (providerData && providerData.length > 0)) {
    return (
      <div className="border border-rounded-sm overflow-hidden">
        <table style={{ tableLayout: 'fixed' }}>
          <caption className="visually-hidden">Doctor Visits</caption>
          <thead>
            <tr className="bg-gray-2">
              <th
                className="text-bold text-blue-primary p-20"
                style={{ width: '35%' }}
              >
                <span>Your doctors</span>{' '}
                <LinkButton className="ml-5" onClick={onAddDoctorsClick}>
                  Edit
                </LinkButton>
              </th>
              <th
                className="text-bold text-blue-primary p-20 d-none d-lg-table-cell"
                style={{ width: 'auto' }}
              >
                Coverage
              </th>
            </tr>
          </thead>
          <tbody>
            {providerData && (
              <Fragment>
                {providerData.map((provider, index) => (
                  <tr
                    className={index % 2 !== 0 ? 'bg-gray-2' : ''}
                    key={index}
                  >
                    <td className="p-20 valign-top" style={{ width: '35%' }}>
                      <div className="d-flex align-items-start">
                        <div className="mr-20">
                          <img
                            className="d-block"
                            src={`/assets/icons/provider-search-${provider.providerType}@2x.png`}
                            alt=""
                            style={{
                              width: currentBreakpoint === 'desktop' ? 48 : 32,
                              height: currentBreakpoint === 'desktop' ? 48 : 32
                            }}
                          />
                        </div>
                        <div>
                          <div className="text-bold text-lg-large mb-5">
                            {provider.providerName}
                          </div>
                          <div>{provider.selectedMedicalGroup}</div>
                          <div>
                            {provider.selectedLocation?.address}
                            <br />
                            {provider.selectedLocation?.city},{' '}
                            {provider.selectedLocation?.state}{' '}
                            {provider.selectedLocation?.zip}
                          </div>
                          <div className="d-block d-lg-none mt-10">
                            <div className="text-bold mb-5">Coverage:</div>
                            <div>
                              {provider.inNetwork ? (
                                <img
                                  className="mr-10"
                                  src="/assets/icons/check-icon-alt@2x.png"
                                  alt="Covered"
                                  style={{ width: 17, height: 11 }}
                                />
                              ) : (
                                <img
                                  className="mr-10"
                                  src="/assets/icons/not-covered-x@2x.png"
                                  alt="Not Covered"
                                  style={{ width: 17, height: 13 }}
                                />
                              )}
                              <span>
                                {provider.inNetwork
                                  ? 'In-Network'
                                  : 'Out-of-Network'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      className="p-20 valign-top d-none d-lg-table-cell"
                      style={{ width: 'auto' }}
                    >
                      <div className="d-flex align-items-start">
                        <div className="mr-10">
                          {provider.inNetwork ? (
                            <img
                              src="/assets/icons/check-icon-alt@2x.png"
                              alt="Covered"
                              style={{ width: 17, height: 11 }}
                            />
                          ) : (
                            <img
                              src="/assets/icons/not-covered-x@2x.png"
                              alt="Not Covered"
                              style={{ width: 17, height: 13 }}
                            />
                          )}
                        </div>
                        <div>
                          <div className="text-lg-large">
                            {provider.inNetwork
                              ? 'In-Network'
                              : 'Out-of-Network'}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </Fragment>
            )}
            {!providerData && (
              <Fragment>
                <tr>
                  <td className="p-20 valign-top" style={{ width: '35%' }}>
                    <div className="d-flex align-items-start">
                      <div className="mr-10">
                        <img
                          src="/assets/icons/user-icon@2x.png"
                          alt=""
                          style={{ width: 24, height: 24 }}
                        />
                      </div>
                      <div>
                        <div className="text-bold text-lg-large mb-5">
                          Melvin Leeds MD
                        </div>
                        <div>
                          4422 3rd Ave, Bronx, NY 10457
                          <br />
                          (718) 960-6486
                        </div>
                        <div className="d-block d-lg-none mt-10">
                          <div className="text-bold mb-5">Coverage:</div>
                          <div>
                            <img
                              className="mr-10"
                              src="/assets/icons/check-icon-alt@2x.png"
                              alt="Covered"
                              style={{ width: 17, height: 11 }}
                            />
                            <span>In-Network</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    className="p-20 valign-top d-none d-lg-table-cell"
                    style={{ width: 'auto' }}
                  >
                    <div className="d-flex align-items-start">
                      <div className="mr-10">
                        <img
                          src="/assets/icons/check-icon-alt@2x.png"
                          alt="Covered"
                          style={{ width: 17, height: 11 }}
                        />
                      </div>
                      <div>
                        <div className="text-lg-large">In-Network</div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-gray-2">
                  <td className="p-20 valign-top" style={{ width: '35%' }}>
                    <div className="d-flex align-items-start">
                      <div className="mr-10">
                        <img
                          src="/assets/icons/user-icon@2x.png"
                          alt=""
                          style={{ width: 24, height: 24 }}
                        />
                      </div>
                      <div>
                        <div className="text-bold text-lg-large mb-5">
                          Mary Nasso MD
                        </div>
                        <div>
                          2301 Ocean Pkwy, Brooklyn, NY 11235
                          <br />
                          (718) 616-3880
                        </div>
                        <div className="d-block d-lg-none mt-10">
                          <div className="text-bold mb-5">Coverage:</div>
                          <div>
                            <img
                              className="mr-10"
                              src="/assets/icons/not-covered-x@2x.png"
                              alt="Not Covered"
                              style={{ width: 17, height: 13 }}
                            />
                            <span>Out-of-Network</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    className="p-20 valign-top d-none d-lg-table-cell"
                    style={{ width: 'auto' }}
                  >
                    <div className="d-flex align-items-start">
                      <div className="mr-10">
                        <img
                          src="/assets/icons/not-covered-x@2x.png"
                          alt="Not Covered"
                          style={{ width: 17, height: 13 }}
                        />
                      </div>
                      <div>
                        <div className="text-lg-large">Out-of-Network</div>
                      </div>
                    </div>
                  </td>
                </tr>
              </Fragment>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="border border-rounded-sm overflow-hidden">
      <table style={{ tableLayout: 'fixed' }}>
        <caption className="visually-hidden">Doctor Visits</caption>
        <tbody>
          <tr>
            <td className="p-20" style={{ width: '35%' }}>
              <span className="text-bold text-lg-large">Your doctors</span>
              <div className="d-block d-lg-none mt-10">
                <AddLinkButton onAddDoctorsClick={onAddDoctorsClick} />
              </div>
            </td>
            <td
              className="p-20 d-none d-lg-table-cell"
              style={{ width: 'auto' }}
            >
              <AddLinkButton onAddDoctorsClick={onAddDoctorsClick} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default withBreakpoints(CoverageDoctorsTable);
