import { LinkButton } from '@bswing/uhc-core';

const AddLinkButton = () => (
  <LinkButton className="text-lg-large d-block">
    <span className="d-flex align-items-center">
      <img
        className="d-block mr-5"
        src="/assets/icons/add-box@2x.png"
        alt=""
        style={{ height: 24, width: 25 }}
      />
      <span>
        Add your prescription drugs
        <span className="d-none d-lg-inline"> to see estimated costs</span>
      </span>
    </span>
  </LinkButton>
);

const CoverageDrugsTable = (props) => {
  const { showDrugs, showDrugPricingUnavailable } = props;

  if (showDrugs) {
    return (
      <>
        {showDrugPricingUnavailable && (
          <div className="bg-teal-quaternary d-flex align-items-start align-items-lg-center border-rounded-sm p-20">
            <img
              className="d-block mr-15"
              src="/assets/icons/alert-icon@2x.png"
              alt=""
              style={{ width: 24, height: 24 }}
            />
            <p>
              REMEDI SENIORCARE provides Prescription Drug Coverage for this
              plan. For drug pricing at this Pharmacy, call UnitedHealthcare:
              1-877-699-5710 (TTY 711). <LinkButton>Change Pharmacy</LinkButton>
            </p>
          </div>
        )}
        {!showDrugPricingUnavailable && (
          <div className="d-flex align-items-center">
            <img
              className="d-block mr-10"
              src="/assets/icons/map-pin@2x.png"
              alt=""
              style={{ width: 21, height: 21 }}
            />
            <span className="text-lg-large">
              Pharmacy: Walgreens 333 7th Ave
            </span>{' '}
            <LinkButton className="text-lg-large ml-20">Edit</LinkButton>
          </div>
        )}
        <div className="border border-rounded-sm overflow-hidden">
          <table style={{ tableLayout: 'fixed' }}>
            <caption className="visually-hidden">Drugs</caption>
            <thead>
              <tr className="bg-gray-2">
                <th
                  className="text-bold text-blue-primary p-20"
                  style={{ width: '35%' }}
                >
                  <span>Your drugs</span>
                  <LinkButton className="ml-5">Edit</LinkButton>
                  <LinkButton
                    className="d-inline-block d-lg-none"
                    style={{ float: 'right' }}
                  >
                    View drug cost details
                  </LinkButton>
                </th>
                <th
                  className="text-bold text-blue-primary p-20 d-none d-lg-table-cell"
                  style={{ width: 'auto' }}
                >
                  <span>What you'll pay</span>{' '}
                  <LinkButton className="ml-5">
                    View drug cost details
                  </LinkButton>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-20 valign-top" style={{ width: '35%' }}>
                  <span className="text-bold text-lg-large">
                    Estimated Annual Drug Cost
                  </span>
                  <span className="d-block d-lg-none mt-10">
                    You Pay: $7,317.84
                  </span>
                </td>
                <td
                  className="p-20 valign-top d-none d-lg-table-cell"
                  style={{ width: 'auto' }}
                >
                  <span className="text-lg-large">$7,317.84</span>
                </td>
              </tr>
              <tr className="bg-gray-2">
                <td className="p-20 valign-top" style={{ width: '35%' }}>
                  <div className="d-flex align-items-start">
                    <div className="mr-10">
                      <img
                        src="/assets/icons/pill-icon@2x.png"
                        alt=""
                        style={{ width: 24, height: 24 }}
                      />
                    </div>
                    <div>
                      <div className="text-bold text-lg-large">
                        atorvastatin calcium TAB 10MG
                      </div>
                      <div className="d-block d-lg-none mt-10">
                        You Pay: $9 copay (Initial Coverage Stage - Tier 1)
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  className="p-20 valign-top d-none d-lg-table-cell"
                  style={{ width: 'auto' }}
                >
                  <span className="text-lg-large">
                    $9 copay (Initial Coverage Stage - Tier 1)
                  </span>
                </td>
              </tr>
              <tr>
                <td className="p-20 valign-top" style={{ width: '35%' }}>
                  <div className="d-flex align-items-start">
                    <div className="mr-10">
                      <img
                        src="/assets/icons/pill-icon@2x.png"
                        alt=""
                        style={{ width: 24, height: 24 }}
                      />
                    </div>
                    <div>
                      <div className="text-bold text-lg-large">atomoxetine</div>
                      <div className="d-block d-lg-none mt-10">
                        You Pay: $300 copay (Initial Coverage Stage - Tier 4)
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  className="p-20 valign-top d-none d-lg-table-cell"
                  style={{ width: 'auto' }}
                >
                  <span className="text-lg-large">
                    $300 copay (Initial Coverage Stage - Tier 4)
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }

  return (
    <div className="border border-rounded-sm overflow-hidden">
      <table style={{ tableLayout: 'fixed' }}>
        <caption className="visually-hidden">Drugs</caption>
        <tbody>
          <tr>
            <td className="p-20" style={{ width: '35%' }}>
              <span className="text-bold text-lg-large">Your drugs</span>
              <div className="d-block d-lg-none mt-10">
                <AddLinkButton />
              </div>
            </td>
            <td
              className="p-20 d-none d-lg-table-cell"
              style={{ width: 'auto' }}
            >
              <AddLinkButton />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CoverageDrugsTable;
