import { Button, Input } from '@bswing/uhc-core';

const faqData = [
  {
    question: 'What is Medicare Supplement and how much coverage do I need?',
    answer:
      "Medicare Supplement insurance plans (also known as Medigap plans) are offered by private insurance companies to help pay some of the out of pocket costs that Original Medicare (which includes Part A and Part B) doesn't pay. Some plans pay most of your out of pocket costs, but have a higher monthly premium. Other plans pay fewer out of pocket costs, but have a lower monthly premium. Benefits and costs vary depending on the plan chosen."
  },
  {
    question:
      'Does a Medicare Supplement insurance plan replace Original Medicare Part A and Part B?',
    answer:
      "No. A Medicare Supplement insurance plan works with Original Medicare, which includes Part A and Part B. Medicare supplement insurance helps cover some of the out-of-pocket health care costs that Original Medicare doesn't pay for. For instance, Medicare Part B generally covers about 80% of Part B expenses. You're responsible for paying the rest. A Medicare Supplement insurance plan could help pay your share."
  },
  {
    question: 'What costs are associated with Medicare Supplement?',
    answer: (
      <div>
        <div className="mb-20">Your costs can include the following:</div>
        <div className="text-bold">Monthly Premium</div>
        <div className="mb-20">
          This is the fee you pay to the plan in exchange for coverage. Each
          Medicare Supplement insurance plan has a diﬀerent monthly premium.
        </div>
        <div className="text-bold">Deductible</div>
        <div className="mb-20">
          This is the amount you pay out-of-pocket for covered services before
          Medicare and/or your Medicare Supplement plan begins to pay. It's a
          pre-set, ﬁxed cost. Most Medicare Supplement plans provide coverage
          for your Medicare Part A hospital deductible. In most cases, you're
          responsible for your Medicare Part B deductible.
        </div>
        <div className="text-bold">Copay/Coinsurance</div>
        <div className="mb-20">
          These are kinds of cost sharing. A copay is a set, ﬂat amount paid
          each time, such as a $20 copay for each in-office doctor visit.
          Coinsurance requires you to pay a percentage of the Medicare-approved
          amount each time.
        </div>
        <div className="text-bold">Out-of-Pocket Limit/Maximum</div>
        <div>
          This is a maximum amount of out-of-pocket costs you pay per calendar
          year. Since Medicare Parts A &amp; B don't have an out-of-pocket
          maximum, your out-of-pocket medical expenses can add up to hundreds or
          thousands per year
          <sup>
            <a className="text-bold no-underline" href="#footnote-5">
              5
            </a>
          </sup>
          . Medicare Supplement plans are designed to help cover some of these
          costs.
        </div>
      </div>
    )
  },
  {
    question: 'How do I decide which Medicare Supplement plan is right for me?',
    answer: (
      <div>
        <div className="mb-20">
          Your health needs and budget will help you decide which Medicare
          Supplement plan might be best for you.
        </div>
        <div className="text-bold">
          Prefer to have more coverage and less out-of-pocket expense?
        </div>
        <div className="mb-20">
          If yes, then plans G, C
          <sup>
            <a className="text-bold no-underline" href="#footnote-6">
              6
            </a>
          </sup>
          , or F
          <sup>
            <a className="text-bold no-underline" href="#footnote-6">
              6
            </a>
          </sup>{' '}
          may be right for you. These plans typically have a higher premium and
          offer the most supplemental coverage, paying up to 100% of your
          out-of-pocket costs for many Medicare-approved services (depending on
          the plan)
          <sup>
            <a className="text-bold no-underline" href="#footnote-6">
              6
            </a>
          </sup>
          .
        </div>
        <div className="text-bold">
          Prefer to have a lower monthly premium with out-of-pocket expenses
          based on your needs?
        </div>
        <div className="mb-20">
          If yes, then explore other Medicare Supplement plan options that may
          be right for you.
        </div>
      </div>
    )
  },
  {
    question: 'When can I apply for Medicare Supplement Insurance?',
    answer: (
      <div>
        <div>
          You can apply for a Medicare Supplement insurance plan any time during
          the year.
          <sup>
            <a className="text-bold no-underline" href="#footnote-7">
              7
            </a>
          </sup>{' '}
          The best time to enroll is during your Open Enrollment period. The
          Medicare Supplement Open Enrollment period is the six-month period
          that starts the first day of the month in which you are age 65 or
          older and enrolled in Medicare Part B. Some states may have additional
          open enrollment periods, including those for people under 65, and
          there may be other situations in which your acceptance may be
          guaranteed.
        </div>
      </div>
    )
  },
  {
    question: 'How do I apply for a Medigap plan?',
    answer: (
      <div>
        <div className="mb-20">
          There are two different ways to apply for a plan: online or by phone.
          Choose the option that works for you.
        </div>
        <div className="text-bold">Online</div>
        <div className="mb-10">
          You can apply for an AARP Medicare Supplement Plan online. It's easy,
          secure, and available anytime. To begin, enter your ZIP Code.
        </div>
        <form className="d-flex align-items-center gap-20 mb-20">
          <Input
            isIFTA={true}
            label={
              <span className="d-flex align-items-center gap-5">
                <svg
                  width="13px"
                  height="19px"
                  viewBox="0 0 13 19"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      transform="translate(-210.000000, -437.000000)"
                      fill="#5A5A5A"
                    >
                      <g transform="translate(197.000000, 310.000000)">
                        <g transform="translate(0.000000, 108.000000)">
                          <g transform="translate(13.375000, 19.750000)">
                            <path d="M6.125,0 C2.73875,0 0,2.73875 0,6.125 C0,10.71875 6.125,17.5 6.125,17.5 C6.125,17.5 12.25,10.71875 12.25,6.125 C12.25,2.73875 9.51125,0 6.125,0 L6.125,0 Z M6.125,8.3125 C4.9175,8.3125 3.9375,7.3325 3.9375,6.125 C3.9375,4.9175 4.9175,3.9375 6.125,3.9375 C7.3325,3.9375 8.3125,4.9175 8.3125,6.125 C8.3125,7.3325 7.3325,8.3125 6.125,8.3125 L6.125,8.3125 Z"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <span>Enter your ZIP Code</span>
              </span>
            }
            type="text"
          />
          <Button>Apply</Button>
        </form>
        <div className="text-bold">Over the Phone</div>
        <div>
          Call <a href="#link">1-833-738-0450</a> (TTY 711), weekdays, 7 a.m. to
          11 p.m., and Saturday, 9 a.m. to 5 p.m., Eastern Time. Friendly
          licensed insurance agents/producers are here to help, so you can enjoy
          the lifelong beneﬁts of a Medicare Supplement insurance plan.
          <sup>
            <a className="text-bold no-underline" href="#footnote-8">
              8
            </a>
          </sup>
        </div>
      </div>
    )
  }
];

export default faqData;
