import { LinkButton } from '@bswing/uhc-core';
import classNames from 'classnames';
import AutoEnroll from 'components/auto-enroll/auto-enroll';
import PrintAndEmail from 'components/print-and-email/print-and-email';
import ZIPCodePrompt from 'components/zip-code-prompt/zip-code-prompt';
import AutoEnrollmentModal from 'partials/auto-enrollment-modal/auto-enrollment-modal';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PlanDetailActions from '../plan-detail-actions/plan-detail-actions';

const PlanDetailHeader = (props) => {
  const {
    data,
    isSaved,
    savedDrugs = [],
    showAutoEnroll,
    showCompare,
    showDrugPricingUnavailable,
    showEnroll,
    showZipCodePrompt,
    onAddDrugsClick = () => {},
    onPlanSaveToggle = () => {}
  } = props;

  const [isAutoEnrollModalShown, setIsAutoEnrollModalShown] = useState(false);

  return (
    <Fragment>
      <div
        className={classNames('bg-gray-2 pt-20 pt-lg-30 pb-30', {
          'pb-lg-90': showEnroll
        })}
      >
        <div className="container container-lg">
          <div className="row mb-20 mb-lg-40">
            <div className="column column-12">
              <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
                <LinkButton
                  as={Link}
                  className="mb-20 mb-lg-0"
                  to="/medicare-advantage-plans"
                >
                  <img
                    className="mr-10"
                    src="/assets/icons/chevron-left-icon@2x.png"
                    alt=""
                    style={{ height: 12, width: 8, verticalAlign: -1 }}
                  />
                  View all Medicare Advantage plans (9)
                </LinkButton>
                <div className="d-flex flex-column flex-lg-row items-center">
                  <div className="d-none d-lg-block">
                    <PrintAndEmail
                      emailLabel="Email plan details"
                      printLabel="Print plan details"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-20 mb-lg-30">
            <div className="column column-12">
              {showAutoEnroll && (
                <div>
                  <AutoEnroll
                    onLearnClick={() => setIsAutoEnrollModalShown(true)}
                  />
                </div>
              )}
              <div className="heading-1 mb-15">{data?.name}</div>
              <div className="d-flex items-center gap-5">
                <img
                  src="/assets/icons/map-pin@2x.png"
                  alt=""
                  style={{ height: 21, width: 21 }}
                />
                <span>
                  You're viewing plan details for{' '}
                  {showZipCodePrompt ? (
                    <ZIPCodePrompt />
                  ) : (
                    <LinkButton>
                      <span className="d-flex align-items-center gap-5">
                        <span>77001 Harris County</span>
                        <img
                          src="/assets/icons/pencil-copy@2x.png"
                          alt=""
                          style={{ height: 12, width: 12 }}
                        />
                      </span>
                    </LinkButton>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="row mb-30 mb-lg-50">
            <div className="column column-12">
              <div className="d-flex flex-column flex-lg-row align-items-lg-start">
                {data?.monthlyPremium && (
                  <div className="border-top border-lg-none d-flex flex-row flex-lg-column align-items-center align-items-lg-start justify-content-between mr-lg-40 py-10 py-lg-0">
                    <div className="text-lg-large">Monthly Premium</div>
                    <div className="text-bold heading-lg-2 text-dark">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data?.monthlyPremium
                        }}
                      />
                    </div>
                  </div>
                )}

                {data?.primaryCarePhysician && (
                  <div className="border-top border-lg-none d-flex flex-row flex-lg-column align-items-center align-items-lg-start justify-content-between mr-lg-40 py-10 py-lg-0 text-right text-lg-left">
                    <div className="text-lg-large">Primary Care Physician</div>
                    <div className="text-bold heading-lg-2 text-dark">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data?.primaryCarePhysician
                        }}
                      />
                    </div>
                  </div>
                )}

                {data?.outOfPocketMax && (
                  <div className="border-top border-lg-none d-flex flex-row flex-lg-column align-items-center align-items-lg-start justify-content-between mr-lg-40 py-10 py-lg-0">
                    <div className="text-lg-large">Out of Pocket Maximum</div>
                    <div className="text-bold heading-lg-2 text-dark">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data?.outOfPocketMax
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="border-top border-bottom border-lg-none d-flex flex-row flex-lg-column align-items-center align-items-lg-start justify-content-between mr-lg-40 py-10 py-lg-0">
                  <div className="text-lg-large">
                    Estimated Annual Drug Cost
                  </div>
                  {savedDrugs.length > 0 && !showDrugPricingUnavailable && (
                    <div className="text-bold heading-lg-2 text-dark">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data?.annualDrugCost
                        }}
                      />
                    </div>
                  )}
                  {savedDrugs.length > 0 && showDrugPricingUnavailable && (
                    <div className="text-bold heading-lg-2 text-dark">
                      <span className="d-flex align-items-center gap-10">
                        <span>&mdash;</span>
                        <img
                          className="d-block"
                          src="/assets/icons/info-icon@2x.png"
                          alt="info icon"
                          style={{ width: 24, height: 24 }}
                        />
                      </span>
                    </div>
                  )}
                  {savedDrugs.length === 0 && (
                    <LinkButton
                      className="mt-0 mt-lg-10"
                      onClick={onAddDrugsClick}
                    >
                      <img
                        className="mr-5 valign-middle"
                        src="/assets/icons/add-box@2x.png"
                        alt=""
                        style={{ height: 24, width: 25 }}
                      />
                      Add<span className="d-none d-lg-inline"> your drugs</span>
                    </LinkButton>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column column-12 column-lg-6">
              <PlanDetailActions
                isSaved={isSaved}
                showAutoEnroll={showAutoEnroll}
                showCompare={showCompare}
                showEnroll={showEnroll}
                onPlanSaveToggle={onPlanSaveToggle}
              />
              <div className="d-block d-lg-none mt-30">
                <PrintAndEmail
                  emailLabel="Email plan details"
                  printLabel="Print plan details"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAutoEnroll && (
        <AutoEnrollmentModal
          isShown={isAutoEnrollModalShown}
          onCloseComplete={() => setIsAutoEnrollModalShown(false)}
        />
      )}
    </Fragment>
  );
};

export default PlanDetailHeader;
