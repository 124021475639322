import { Button } from '@bswing/uhc-core';
import classNames from 'classnames';
import Ribbon from 'components/ribbon/ribbon';
import './recommended-plan-card.scss';

const RecommendedPlanCard = ({
  children,
  className,
  data,
  onPrimaryActionClick = () => {},
  onViewClick = () => {},
  ...rest
}) => {
  const componentClassName = classNames(
    'uhc-recommended-plan-card',
    {},
    className
  );

  return (
    <div className={componentClassName} {...rest}>
      <div className="uhc-recommended-plan-card__header">
        <Ribbon>{data?.ribbon}</Ribbon>
        <h3 className="heading-2 text-white mb-10">
          <span>{data?.name}</span>
        </h3>
        <div className="d-flex align-items-center gap-5">
          <span>{data?.description}</span>
          <a href="#link">
            <img
              className="d-none d-lg-block"
              src="/assets/icons/full-icon-library-all-24-px-action-ic-info-outline-2@2x.png"
              alt="info icon"
              style={{ width: 20, height: 20 }}
            />
          </a>
        </div>
      </div>
      <div className="uhc-recommended-plan-card__main">
        <div className="row row-collapse mb-20">
          <div className="column column-12">
            {data?.type === 'MA' && (
              <div className="d-flex align-items-start gap-40">
                <div>
                  <div className="text-small">Monthly Premium</div>
                  <div className="heading-2 text-gray-8">
                    <span className="uhc-sup">$</span>
                    {data?.monthlyPremium}
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-small">Out-of-pocket Maximum</div>
                  <div className="heading-2 text-gray-8">
                    <span className="uhc-sup">$</span>
                    {data?.outOfPocketMax}
                  </div>
                </div>
              </div>
            )}
            {data?.type === 'MS' && (
              <div className="bg-teal-quaternary text-bold p-15">
                {data?.content}
              </div>
            )}
          </div>
        </div>
        <div className="row row-collapse">
          <div className="column column-12">
            <div className="d-flex align-items-center gap-10">
              <Button onClick={(event) => onPrimaryActionClick(event, data)}>
                {data?.type === 'MA' ? 'Enroll in plan' : 'Start application'}
              </Button>
              <Button
                isOutlined={true}
                isSecondary={true}
                onClick={(event) => onViewClick(event, data)}
              >
                View plan details
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendedPlanCard;
