import classNames from 'classnames';
import React from 'react';
import './control-button.scss';

const ControlButton = (props) => {
  const { children, className, direction, ...rest } = props;
  const componentClassName = classNames('uhc-control-button', {}, className);

  return (
    <button className={componentClassName} {...rest}>
      <span className="visually-hidden">
        {direction === 'right' ? 'Next' : 'Prev'}
      </span>
      <svg
        className="d-block flex-shrink-0"
        width="31"
        height="31"
        viewBox="0 0 31 31"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: direction === 'right' ? 'scaleX(-1)' : '' }}
        aria-hidden="true"
        focusable="false"
      >
        <path
          d="M19.602 9.766 17.87 8.033l-7.377 7.377 7.377 7.377 1.733-1.734-5.63-5.643z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </button>
  );
};

export default ControlButton;
