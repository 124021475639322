import { Button, Checkbox, LinkButton } from '@bswing/uhc-core';
import { Fragment } from 'react';

const CheckboxLabel = ({ showCompare }) => {
  if (showCompare) {
    return (
      <LinkButton onClick={(e) => e.preventDefault()}>
        <span className="d-flex align-items-center">
          Compare 1 Plan{' '}
          <img
            className="ml-10"
            src="/assets/icons/chevron-right-icon@2x.png"
            alt=""
            style={{ width: 8, height: 12 }}
          />
        </span>
      </LinkButton>
    );
  }

  return <span>Add to Compare</span>;
};

const PlanDetailActions = ({
  isSaved,
  showAutoEnroll,
  showCompare,
  showEnroll,
  onPlanSaveToggle = () => {}
}) => {
  return (
    <Fragment>
      <div
        className="d-flex flex-column flex-lg-row flex-lg-wrap align-items-lg-center"
        style={{ rowGap: 20, columnGap: 40 }}
      >
        {!showAutoEnroll && (
          <div className="d-flex align-items-center gap-10">
            {showEnroll && (
              <Button style={{ maxWidth: '100%', width: 200 }}>Enroll</Button>
            )}
            <Button
              isOutlined={true}
              isSecondary={true}
              onClick={onPlanSaveToggle}
            >
              <span className="d-flex align-items-center">
                {!isSaved && (
                  <>
                    <img
                      className="d-block mr-10 ml-n5"
                      src="/assets/icons/heart-icon-small@2x.png"
                      alt=""
                      style={{
                        height: 16,
                        width: 16
                      }}
                    />
                    <span>Save</span>
                  </>
                )}
                {isSaved && (
                  <>
                    <img
                      className="d-block mr-10 ml-n5"
                      src="/assets/icons/heart-icon-small-filled@2x.png"
                      alt=""
                      style={{
                        height: 16,
                        width: 16
                      }}
                    />
                    <span>Saved</span>
                  </>
                )}
              </span>
            </Button>
          </div>
        )}
        <Checkbox
          checked={showCompare}
          label={<CheckboxLabel showCompare={showCompare} />}
          onChange={() => {}}
        />
      </div>
      {!showEnroll && (
        <div className="mt-30">
          <div className="text-bold text-blue-primary mb-10">
            Enroll by phone: 1-877-659-9664 (TTY 711)
          </div>
          <div>
            Call a UnitedHealthcare sales agent to enroll, 7 a.m. - 7 p.m. CT, 7
            days a week.
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default PlanDetailActions;
