import MSHomeBanner from './ms-home-banner/ms-home-banner';
import MSHomeDisclaimers from './ms-home-disclaimers/ms-home-disclaimers';
import MSHomeFAQs from './ms-home-faqs/ms-home-faqs';
import MSHomeGuide from './ms-home-guide/ms-home-guide';
import MSHomeHeadline from './ms-home-headline/ms-home-headline';
import MSHomeHelp from './ms-home-help/ms-home-help';
import MSHomeImportantInformation from './ms-home-important-information/ms-home-important-information';
import MSHomeMosaic from './ms-home-mosaic/ms-home-mosaic';
import MSHomePlanLinks from './ms-home-plan-links/ms-home-plan-links';
import MSHomeTestimonials from './ms-home-testimonials/ms-home-testimonials';
import Sam1 from 'components/sam/sam1.js';
import Sam2 from 'components/sam/sam2.js';
import Sam3 from 'components/sam/sam3.js';

const MSHome = ({
  autoDetectZIP,
  limitedPlansAvailable,
  useAlternateMosaic,
  samType
}) => {
  return (
    <>
      {samType === 'one' && <Sam1 />}
      {samType === 'two' && <Sam2 />}
      {samType === 'three' && <Sam3 />}
      <MSHomeBanner autoDetectZIP={autoDetectZIP} samType={samType} />
      <MSHomePlanLinks
        autoDetectZIP={autoDetectZIP}
        limitedPlansAvailable={limitedPlansAvailable}
        showHelpSection={true}
      />
      <MSHomeHeadline
        headline="See how an AARP Medicare Supplement Insurance Plan puts you in control"
        overline="Why choose a Medicare Supplement plan"
      />
      <MSHomeMosaic useAlternateMosaic={useAlternateMosaic} />
      <MSHomeTestimonials />
      <div className="py-100 my-50"></div>
      <MSHomeGuide />
      <MSHomeHeadline
        headline="Get more complete coverage with Medicare Supplement and Part D"
        overline="Medicare Supplement and Medicare Part D"
      />
      <MSHomePlanLinks useAlternateLayout={true} />
      <MSHomeHeadline headline="Find answers to some of your Medicare Supplement questions" />
      <MSHomeFAQs />
      <MSHomeHelp />
      <MSHomeImportantInformation />
      <MSHomeDisclaimers />
    </>
  );
};

export default MSHome;
