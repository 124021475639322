const plansWithoutDetails = [
  {
    name: 'AARP Medicare Advantage from UHC ST-0003 (HMO-POS)',
    summary:
      'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',
    rate: '$0',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              `<div class="hl-flex hl-flex--empty">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="empty-cell"></span>
                </div>
              </div>`,
              // '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$2,900</span><div class="text-regular mt-5">In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-covered">Covered</span></span><div class="text-regular mt-5">with UnitedHealthcare’s National Network</div>'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<a class="uhc-link-button d-block" tabindex="0"><span class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/circle-add-icon@2x.png" alt="" style="height: 24px; width: 25px;"><span>Add your doctors</span></span></a>'
            ]
          },
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</br>In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$25</span><div class="text-regular mt-5">copay</br>In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</br>In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</br>In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$15</span><div class="text-regular mt-5">copay</br>In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$25</span><div class="text-regular mt-5">copay</br>In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</br>In-network</div>'
            ]
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '<a class="uhc-link-button d-block" tabindex="0"><span class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/circle-add-icon@2x.png" alt="" style="height: 24px; width: 25px;"><span>Add your drugs</span></span></a>'
            ]
          },
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">Optum Home Delivery</span>'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<a class="uhc-link-button d-block" tabindex="0"><span class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/circle-add-icon@2x.png" alt="" style="height: 24px; width: 25px;"><span>Add your dentists</span></span></a>'
            ]
          },
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$1,000</span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">50%</span><div class="text-regular mt-5">Coinsurance</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item">Not offered with this plan</span>'
            ]
          }
        ]
      },
      {
        // Medical Benefits
        tables: [
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$40</span><div class="text-regular mt-5">copay per visit<br />($0 copay when outside of the United States)</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$135</span><div class="text-regular mt-5">copay per visit<br />($0 copay when outside of the United States)</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$275</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$245</span><div class="text-regular mt-5">copay per day, days 1-8<br />In-network</div><br /><br /><span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay per day, after day 8<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$245</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$245</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0-75</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$50</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$15</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$25</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<div class="text-regular mt-5">Days 1-20</div><span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />In-network</div><br /><br /><div class="text-regular mt-5">Days 21-100</div><span class="hl-item text-extra-large text-bold text-serif">$203</span><div class="text-regular mt-5">copay<br />In-network</div>'
            ]
          }
        ]
      },
      {
        // Extra Benefits and Programs
        tables: [
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<div class="text-regular mt-5">Up to</div><span class="hl-item text-extra-large text-bold text-serif">$300</span><div class="text-regular mt-5">every year</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<div class="text-regular mt-5">copays from</div><span class="hl-item text-extra-large text-bold text-serif">$99-1,249</span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$70</span><div class="text-regular mt-5">credit per quarter</div>',
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
                <div class="text-regular mt-5">copay includes:</div>
                <ul class="compare-checklist">
                  <li>Free gym membership</li>
                  <li>Online fitness classes</li>
                  <li>Brain health challenges</li>
                </ul>`,
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />for 12 one-way trips</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$25</span><div class="text-regular mt-5">copay<br />6 visits per year</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-covered">Covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />for 28 meals</div>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'AARP Medicare Advantage from UHC ST-0004 (HMO-POS)',
    summary:
      'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',
    rate: '$0',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              `<div class="hl-flex hl-flex--empty">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="empty-cell"></span>
                </div>
              </div>`,
              // '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$3,400</span><div class="text-regular mt-5">In-network</div>',
              `<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>`,
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-covered">Covered</span></span><div class="text-regular mt-5">with UnitedHealthcare’s National Network</div>'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<a class="uhc-link-button d-block" tabindex="0"><span class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/circle-add-icon@2x.png" alt="" style="height: 24px; width: 25px;"><span>Add your doctors</span></span></a>'
            ]
          },
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</br>In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$35</span><div class="text-regular mt-5">copay</br>In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</br>In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</br>In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$15</span><div class="text-regular mt-5">copay</br>In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$25</span><div class="text-regular mt-5">copay</br>In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</br>In-network</div>'
            ]
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '<a class="uhc-link-button d-block" tabindex="0"><span class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/circle-add-icon@2x.png" alt="" style="height: 24px; width: 25px;"><span>Add your drugs</span></span></a>'
            ]
          },
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">Optum Home Delivery</span>'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<a class="uhc-link-button d-block" tabindex="0"><span class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/circle-add-icon@2x.png" alt="" style="height: 24px; width: 25px;"><span>Add your dentists</span></span></a>'
            ]
          },
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$2,500</span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">50%</span><div class="text-regular mt-5">Coinsurance</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item">Not offered with this plan</span>'
            ]
          }
        ]
      },
      {
        // Medical Benefits
        tables: [
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$40</span><div class="text-regular mt-5">copay per visit<br />($0 copay when outside of the United States)</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$135</span><div class="text-regular mt-5">copay per visit<br />($0 copay when outside of the United States)</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$290</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$290</span><div class="text-regular mt-5">copay per day, days 1-5<br />In-network</div><br /><br /><span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay per day, after day 5<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$325</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$325</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0-85</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$20</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$15</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$25</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<div class="text-regular mt-5">Days 1-20</div><span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />In-network</div><br /><br /><div class="text-regular mt-5">Days 21-100</div><span class="hl-item text-extra-large text-bold text-serif">$203</span><div class="text-regular mt-5">copay<br />In-network</div>'
            ]
          }
        ]
      },
      {
        // Extra Benefits and Programs
        tables: [
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<div class="text-regular mt-5">Up to</div><span class="hl-item text-extra-large text-bold text-serif">$300</span><div class="text-regular mt-5">every year</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />In-network</div>',
              '<div class="text-regular mt-5">copays from</div><span class="hl-item text-extra-large text-bold text-serif">$99-1,249</span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$100</span><div class="text-regular mt-5">credit per quarter</div>',
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
                <div class="text-regular mt-5">copay includes:</div>
                <ul class="compare-checklist">
                  <li>Free gym membership</li>
                  <li>Online fitness classes</li>
                  <li>Brain health challenges</li>
                </ul>`,
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />for 12 one-way trips</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$35</span><div class="text-regular mt-5">copay<br />6 visits per year</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-covered">Covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />for 28 meals</div>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'AARP Medicare Advantage Walgreens from UHC ST-0002 (PPO)',
    summary:
      'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',
    rate: '$0',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$3,800</span>
                  <div class="text-regular mt-5">In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$5,750</span>
                  <div class="text-regular mt-5">Out-of-network</div>
                </div>
              </div>`,
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-covered">Covered</span></span><div class="text-regular mt-5">with UnitedHealthcare’s National Network</div>'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<a class="uhc-link-button d-block" tabindex="0"><span class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/circle-add-icon@2x.png" alt="" style="height: 24px; width: 25px;"><span>Add your doctors</span></span></a>'
            ]
          },
          {
            values: [
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$35</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`,
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$15</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$30</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$25</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$40</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`
            ]
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '<a class="uhc-link-button d-block" tabindex="0"><span class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/circle-add-icon@2x.png" alt="" style="height: 24px; width: 25px;"><span>Add your drugs</span></span></a>'
            ]
          },
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">Walgreens Pharmacy</span>'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<a class="uhc-link-button d-block" tabindex="0"><span class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/circle-add-icon@2x.png" alt="" style="height: 24px; width: 25px;"><span>Add your dentists</span></span></a>'
            ]
          },
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$1,000</span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">50%</span><div class="text-regular mt-5">Coinsurance</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item">Not offered with this plan</span>'
            ]
          }
        ]
      },
      {
        // Medical Benefits
        tables: [
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$40</span><div class="text-regular mt-5">copay per visit<br />($0 copay when outside of the United States)</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$120</span><div class="text-regular mt-5">copay per visit<br />($0 copay when outside of the United States)</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$275</span><div class="text-regular mt-5">copay</div>',
              `<div class="hl-flex">
                <div class="hl-flex__item">
                <span class="hl-item text-extra-large text-bold text-serif">$325</span><div class="text-regular mt-5">copay per day, days 1-8<br />In-network</div><br /><br /><span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay per day, after day 8<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$290</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$290</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0-$190</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$325</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$50</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$70</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$15</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$30</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$15</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$30</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <div class="text-regular mt-5">Days 1-20</div><span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay per day<br />In-network</div><br /><br /><div class="text-regular mt-5">Days 21-100</div><span class="hl-item text-extra-large text-bold text-serif">$203</span><div class="text-regular mt-5">copay per day<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                <div class="text-regular mt-5">Days 1-26</div><span class="hl-item text-extra-large text-bold text-serif">$225</span><div class="text-regular mt-5">copay per day<br />In-network</div><br /><br /><div class="text-regular mt-5">Days 27-100</div><span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay per day<br />In-network</div>
                </div>
              </div>`
            ]
          }
        ]
      },
      {
        // Extra Benefits and Programs
        tables: [
          {
            values: [
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              '<div class="text-regular mt-5">Up to</div><span class="hl-item text-extra-large text-bold text-serif">$250</span><div class="text-regular mt-5">every year</div>',
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              '<div class="text-regular mt-5">copays from</div><span class="hl-item text-extra-large text-bold text-serif">$99-1,249</span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$40</span><div class="text-regular mt-5">credit per quarter</div>',
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
                <div class="text-regular mt-5">copay includes:</div>
                <ul class="compare-checklist">
                  <li>Free gym membership</li>
                  <li>Online fitness classes</li>
                  <li>Brain health challenges</li>
                </ul>`,
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$30 | 40%</span><div class="text-regular mt-5">of the cost</div>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-covered">Covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />for 28 meals</div>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'AARP Medicare Advantage from UHC ST-0001 (PPO)',
    summary:
      'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',
    rate: '$34',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$3,800</span>
                  <div class="text-regular mt-5">In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$5,750</span>
                  <div class="text-regular mt-5">Out-of-network</div>
                </div>
              </div>`,
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif">Covered</span><div class="text-regular mt-5">with UnitedHealthcare’s National Network</div>'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<a class="uhc-link-button d-block" tabindex="0"><span class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/circle-add-icon@2x.png" alt="" style="height: 24px; width: 25px;"><span>Add your doctors</span></span></a>'
            ]
          },
          {
            values: [
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$30</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`,
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$15</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$30</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$25</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$40</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">of the cost<br />Out-of-network</div>
                </div>
              </div>`
            ]
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '<a class="uhc-link-button d-block" tabindex="0"><span class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/circle-add-icon@2x.png" alt="" style="height: 24px; width: 25px;"><span>Add your drugs</span></span></a>'
            ]
          },
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">Optum Home Delivery</span>'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<a class="uhc-link-button d-block" tabindex="0"><span class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/circle-add-icon@2x.png" alt="" style="height: 24px; width: 25px;"><span>Add your dentists</span></span></a>'
            ]
          },
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$1,500</span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">50%</span><div class="text-regular mt-5">Coinsurance</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay</div>',
              '<span class="hl-item">Not offered with this plan</span>'
            ]
          }
        ]
      },
      {
        // Medical Benefits
        tables: [
          {
            values: [
              '<span class="hl-item text-extra-large text-bold text-serif">$40</span><div class="text-regular mt-5">copay per visit<br />($0 copay when outside of the United States)</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$120</span><div class="text-regular mt-5">copay per visit<br />($0 copay when outside of the United States)</div>',
              '<span class="hl-item text-extra-large text-bold text-serif">$275</span><div class="text-regular mt-5">copay</div>',
              `<div class="hl-flex">
                <div class="hl-flex__item">
                <span class="hl-item text-extra-large text-bold text-serif">$295</span><div class="text-regular mt-5">copay per day, days 1-8<br />In-network</div><br /><br /><span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay per day, after day 8<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$295</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$285</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0-$115</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$325</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$5</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$70</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$15</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$30</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$15</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">$30</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">50%</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <div class="text-regular mt-5">Days 1-20</div><span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay per day<br />In-network</div><br /><br /><div class="text-regular mt-5">Days 21-100</div><span class="hl-item text-extra-large text-bold text-serif">$203</span><div class="text-regular mt-5">copay per day<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                <div class="text-regular mt-5">Days 1-26</div><span class="hl-item text-extra-large text-bold text-serif">$225</span><div class="text-regular mt-5">copay per day<br />In-network</div><br /><br /><div class="text-regular mt-5">Days 27-100</div><span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay per day<br />In-network</div>
                </div>
              </div>`
            ]
          }
        ]
      },
      {
        // Extra Benefits and Programs
        tables: [
          {
            values: [
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              '<div class="text-regular mt-5">Up to</div><span class="hl-item text-extra-large text-bold text-serif">$250</span><div class="text-regular mt-5">every year</div>',
              `<div class="hl-flex">
                <div class="hl-flex__item">
                  <span class="hl-item text-extra-large text-bold text-serif">$0</span>
                  <div class="text-regular mt-5">copay<br />In-network</div>
                </div>
                <div class="hl-flex__item">
                  <span class="hl-item-oon text-extra-large text-bold text-serif">40%</span>
                  <div class="text-regular mt-5">Per stay<br />Out-of-network</div>
                </div>
              </div>`,
              '<div class="text-regular mt-5">copays from</div><span class="hl-item text-extra-large text-bold text-serif">$99-1,249</span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$50</span><div class="text-regular mt-5">credit per quarter</div>',
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
                <div class="text-regular mt-5">copay includes:</div>
                <ul class="compare-checklist">
                  <li>Free gym membership</li>
                  <li>Online fitness classes</li>
                  <li>Brain health challenges</li>
                </ul>`,
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$30 | 40%</span><div class="text-regular mt-5">of the cost</div>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-covered">Covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif">$0</span><div class="text-regular mt-5">copay<br />for 28 meals</div>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>',
              '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  }
];

const plansWithDetails = [
  {
    name: 'AARP Medicare Advantage Prime (HMO)',
    summary:
      'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',
    rate: '$161.80',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',

              'No Coverage',
              '$750',
              '$7,550'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: ['$5 copay', '$45 copay', 'No', 'Yes']
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '$317.74',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$9 copay (Tier 1 Initial Coverage Stage)</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$300 copay (Tier 4 Initial Coverage Stage)</div>'
            ]
          },
          {
            values: [
              '$0 for Tier 1, Tier 2<br /><br />$295 for Tier 3, Tier 4, Tier 5',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): 28% coinsurance',
              '-',
              '-',
              "During the Coverage Gap Stage, you (or others on your behalf) will pay no more than 25% of the negotiated price for drugs. You generally stay in this stage until your out-of-pocket cost reaches $7,050.<br /><br />You may pay less if your plan has additional coverage in the gap.<br /><br />Always use your Medicare Advantage member ID card during the coverage gap to get the plan's discounted drug rates. The money you spend using your card counts toward your out-of-pocket costs.",
              'After your total out-of-pocket costs reach $7,050, you will pay the greater of $3.95 copay for generic (including brand drugs treated as generic), and $9.85 copay for all other drugs, or 5% coinsurance.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with Walgreens Preferred Retail Pharmacy.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with OptumRx home delivery.'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: [
              '$0 copay for Preventive Services',
              '<span class="text-bold">Dental Platinum</span> for $40 a month<br /><br />$1,500 per year for covered dental services through the Platinum Dental Rider.<br /><br />No deductible.<br /><br />$0 copay for in-network exams, x-rays, cleanings, fluoride, and fillings.<br /><br />50% coinsurance on crowns, bridges, root canals, extractions, dentures, and all other covered comprehensive services.<br /><br />Access to the largest Medicare Advantage dental network. Out-of-network coverage is available. If you choose to see an out-of-network dentist you might be billed more, even for services listed as $0 copay.'
            ]
          }
        ]
      },
      // Medical Benefits
      {
        tables: [
          {
            values: [
              '$0 - $325 copay',
              '$90 copay',
              '$0 for covered brands',
              '$0 copay',
              '$345 copay per day: days 1 - 5<br /><br />$0 copay per day for unlimited days afer that',
              '$0 copay',
              'Group: $15 copay<br /><br />Individual: $25 copay',
              '$0 copay',
              '$0 - $345 copay',
              '$0 for covered brands',
              '-',
              '$40 copay'
            ]
          }
        ]
      },
      // Extra Benefits and Programs
      {
        tables: [
          {
            values: [
              '$0 copay; 1 every year',
              '$0 copay; up to $100 every two years for frames or contact lenses. Standard single, bifocal, trifocal, or progressive lenses are covered in full. Home delivered eyewear availabile nationwide only through UnitedHealthcare Vision (select products only).',
              '$375 copay - $1,425 copay for each hearing aid provide through UnitedHealthcare Hearing, up to 2 hearing aids every year.<br /><br />Includes hearing aids delivered directly to you with virtual follow-up care through Right2You (select models), offered only by UnitedHealthcare Hearing.',
              'Fitness Membership Only: Basic membership in a fitness program at a network location at no additional cost',
              '-',
              '-',
              '-',
              '-',
              '-',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'AARP Medicare Advantage Plan 1 (HMO)',
    summary:
      'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',
    rate: '$161.80',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',

              'No Coverage',
              '$750',
              '$7,550'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: ['$5 copay', '$45 copay', 'No', 'Yes']
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '$317.74',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$9 copay (Tier 1 Initial Coverage Stage)</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$300 copay (Tier 4 Initial Coverage Stage)</div>'
            ]
          },
          {
            values: [
              '$0 for Tier 1, Tier 2<br /><br />$295 for Tier 3, Tier 4, Tier 5',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): 28% coinsurance',
              '-',
              '-',
              "During the Coverage Gap Stage, you (or others on your behalf) will pay no more than 25% of the negotiated price for drugs. You generally stay in this stage until your out-of-pocket cost reaches $7,050.<br /><br />You may pay less if your plan has additional coverage in the gap.<br /><br />Always use your Medicare Advantage member ID card during the coverage gap to get the plan's discounted drug rates. The money you spend using your card counts toward your out-of-pocket costs.",
              'After your total out-of-pocket costs reach $7,050, you will pay the greater of $3.95 copay for generic (including brand drugs treated as generic), and $9.85 copay for all other drugs, or 5% coinsurance.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with Walgreens Preferred Retail Pharmacy.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with OptumRx home delivery.'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: [
              '$0 copay for Preventive Services',
              '<span class="text-bold">Dental Platinum</span> for $40 a month<br /><br />$1,500 per year for covered dental services through the Platinum Dental Rider.<br /><br />No deductible.<br /><br />$0 copay for in-network exams, x-rays, cleanings, fluoride, and fillings.<br /><br />50% coinsurance on crowns, bridges, root canals, extractions, dentures, and all other covered comprehensive services.<br /><br />Access to the largest Medicare Advantage dental network. Out-of-network coverage is available. If you choose to see an out-of-network dentist you might be billed more, even for services listed as $0 copay.'
            ]
          }
        ]
      },
      // Medical Benefits
      {
        tables: [
          {
            values: [
              '$0 - $325 copay',
              '$90 copay',
              '$0 for covered brands',
              '$0 copay',
              '$345 copay per day: days 1 - 5<br /><br />$0 copay per day for unlimited days afer that',
              '$0 copay',
              'Group: $15 copay<br /><br />Individual: $25 copay',
              '$0 copay',
              '$0 - $345 copay',
              '$0 for covered brands',
              '-',
              '$40 copay'
            ]
          }
        ]
      },
      // Extra Benefits and Programs
      {
        tables: [
          {
            values: [
              '$0 copay; 1 every year',
              '$0 copay; up to $100 every two years for frames or contact lenses. Standard single, bifocal, trifocal, or progressive lenses are covered in full. Home delivered eyewear availabile nationwide only through UnitedHealthcare Vision (select products only).',
              '$375 copay - $1,425 copay for each hearing aid provide through UnitedHealthcare Hearing, up to 2 hearing aids every year.<br /><br />Includes hearing aids delivered directly to you with virtual follow-up care through Right2You (select models), offered only by UnitedHealthcare Hearing.',
              'Fitness Membership Only: Basic membership in a fitness program at a network location at no additional cost',
              '-',
              '-',
              '-',
              '-',
              '-',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'AARP Medicare Advantage Plan 2 (HMO)',
    summary:
      'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',
    rate: '$161.80',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',

              'No Coverage',
              '$750',
              '$7,550'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: ['$5 copay', '$45 copay', 'No', 'Yes']
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '$317.74',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$9 copay (Tier 1 Initial Coverage Stage)</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$300 copay (Tier 4 Initial Coverage Stage)</div>'
            ]
          },
          {
            values: [
              '$0 for Tier 1, Tier 2<br /><br />$295 for Tier 3, Tier 4, Tier 5',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): 28% coinsurance',
              '-',
              '-',
              "During the Coverage Gap Stage, you (or others on your behalf) will pay no more than 25% of the negotiated price for drugs. You generally stay in this stage until your out-of-pocket cost reaches $7,050.<br /><br />You may pay less if your plan has additional coverage in the gap.<br /><br />Always use your Medicare Advantage member ID card during the coverage gap to get the plan's discounted drug rates. The money you spend using your card counts toward your out-of-pocket costs.",
              'After your total out-of-pocket costs reach $7,050, you will pay the greater of $3.95 copay for generic (including brand drugs treated as generic), and $9.85 copay for all other drugs, or 5% coinsurance.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with Walgreens Preferred Retail Pharmacy.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with OptumRx home delivery.'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: [
              '$0 copay for Preventive Services',
              '<span class="text-bold">Dental Platinum</span> for $40 a month<br /><br />$1,500 per year for covered dental services through the Platinum Dental Rider.<br /><br />No deductible.<br /><br />$0 copay for in-network exams, x-rays, cleanings, fluoride, and fillings.<br /><br />50% coinsurance on crowns, bridges, root canals, extractions, dentures, and all other covered comprehensive services.<br /><br />Access to the largest Medicare Advantage dental network. Out-of-network coverage is available. If you choose to see an out-of-network dentist you might be billed more, even for services listed as $0 copay.'
            ]
          }
        ]
      },
      // Medical Benefits
      {
        tables: [
          {
            values: [
              '$0 - $325 copay',
              '$90 copay',
              '$0 for covered brands',
              '$0 copay',
              '$345 copay per day: days 1 - 5<br /><br />$0 copay per day for unlimited days afer that',
              '$0 copay',
              'Group: $15 copay<br /><br />Individual: $25 copay',
              '$0 copay',
              '$0 - $345 copay',
              '$0 for covered brands',
              '-',
              '$40 copay'
            ]
          }
        ]
      },
      // Extra Benefits and Programs
      {
        tables: [
          {
            values: [
              '$0 copay; 1 every year',
              '$0 copay; up to $100 every two years for frames or contact lenses. Standard single, bifocal, trifocal, or progressive lenses are covered in full. Home delivered eyewear availabile nationwide only through UnitedHealthcare Vision (select products only).',
              '$375 copay - $1,425 copay for each hearing aid provide through UnitedHealthcare Hearing, up to 2 hearing aids every year.<br /><br />Includes hearing aids delivered directly to you with virtual follow-up care through Right2You (select models), offered only by UnitedHealthcare Hearing.',
              'Fitness Membership Only: Basic membership in a fitness program at a network location at no additional cost',
              '-',
              '-',
              '-',
              '-',
              '-',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'AARP Medicare Advantage Mosaic Choice (PPO)',
    summary:
      'This PPO plan gives you the freedom to see any doctor nationwide that accepts Medicare, without referrals for specialist or hospital visits.',
    rate: '$161.80',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              'This PPO plan gives you the freedom to see any doctor nationwide that accepts Medicare, without referrals for specialist or hospital visits.',

              'No Coverage',
              '$750',
              '$7,550'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: ['$5 copay', '$45 copay', 'No', 'Yes']
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '$317.74',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$9 copay (Tier 1 Initial Coverage Stage)</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$300 copay (Tier 4 Initial Coverage Stage)</div>'
            ]
          },
          {
            values: [
              '$0 for Tier 1, Tier 2<br /><br />$295 for Tier 3, Tier 4, Tier 5',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): 28% coinsurance',
              '-',
              '-',
              "During the Coverage Gap Stage, you (or others on your behalf) will pay no more than 25% of the negotiated price for drugs. You generally stay in this stage until your out-of-pocket cost reaches $7,050.<br /><br />You may pay less if your plan has additional coverage in the gap.<br /><br />Always use your Medicare Advantage member ID card during the coverage gap to get the plan's discounted drug rates. The money you spend using your card counts toward your out-of-pocket costs.",
              'After your total out-of-pocket costs reach $7,050, you will pay the greater of $3.95 copay for generic (including brand drugs treated as generic), and $9.85 copay for all other drugs, or 5% coinsurance.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with Walgreens Preferred Retail Pharmacy.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with OptumRx home delivery.'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: [
              '$0 copay for Preventive Services',
              '<span class="text-bold">Dental Platinum</span> for $40 a month<br /><br />$1,500 per year for covered dental services through the Platinum Dental Rider.<br /><br />No deductible.<br /><br />$0 copay for in-network exams, x-rays, cleanings, fluoride, and fillings.<br /><br />50% coinsurance on crowns, bridges, root canals, extractions, dentures, and all other covered comprehensive services.<br /><br />Access to the largest Medicare Advantage dental network. Out-of-network coverage is available. If you choose to see an out-of-network dentist you might be billed more, even for services listed as $0 copay.'
            ]
          }
        ]
      },
      // Medical Benefits
      {
        tables: [
          {
            values: [
              '$0 - $325 copay',
              '$90 copay',
              '$0 for covered brands',
              '$0 copay',
              '$345 copay per day: days 1 - 5<br /><br />$0 copay per day for unlimited days afer that',
              '$0 copay',
              'Group: $15 copay<br /><br />Individual: $25 copay',
              '$0 copay',
              '$0 - $345 copay',
              '$0 for covered brands',
              '-',
              '$40 copay'
            ]
          }
        ]
      },
      // Extra Benefits and Programs
      {
        tables: [
          {
            values: [
              '$0 copay; 1 every year',
              '$0 copay; up to $100 every two years for frames or contact lenses. Standard single, bifocal, trifocal, or progressive lenses are covered in full. Home delivered eyewear availabile nationwide only through UnitedHealthcare Vision (select products only).',
              '$375 copay - $1,425 copay for each hearing aid provide through UnitedHealthcare Hearing, up to 2 hearing aids every year.<br /><br />Includes hearing aids delivered directly to you with virtual follow-up care through Right2You (select models), offered only by UnitedHealthcare Hearing.',
              'Fitness Membership Only: Basic membership in a fitness program at a network location at no additional cost',
              '-',
              '-',
              '-',
              '-',
              '-',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'AARP Medicare Advantage Advantage Patriot (HMO)',
    summary:
      'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',
    rate: '$161.80',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',

              'No Coverage',
              '$750',
              '$7,550'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: ['$5 copay', '$45 copay', 'No', 'Yes']
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '$317.74',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$9 copay (Tier 1 Initial Coverage Stage)</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$300 copay (Tier 4 Initial Coverage Stage)</div>'
            ]
          },
          {
            values: [
              '$0 for Tier 1, Tier 2<br /><br />$295 for Tier 3, Tier 4, Tier 5',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): 28% coinsurance',
              '-',
              '-',
              "During the Coverage Gap Stage, you (or others on your behalf) will pay no more than 25% of the negotiated price for drugs. You generally stay in this stage until your out-of-pocket cost reaches $7,050.<br /><br />You may pay less if your plan has additional coverage in the gap.<br /><br />Always use your Medicare Advantage member ID card during the coverage gap to get the plan's discounted drug rates. The money you spend using your card counts toward your out-of-pocket costs.",
              'After your total out-of-pocket costs reach $7,050, you will pay the greater of $3.95 copay for generic (including brand drugs treated as generic), and $9.85 copay for all other drugs, or 5% coinsurance.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with Walgreens Preferred Retail Pharmacy.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with OptumRx home delivery.'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: [
              '$0 copay for Preventive Services',
              '<span class="text-bold">Dental Platinum</span> for $40 a month<br /><br />$1,500 per year for covered dental services through the Platinum Dental Rider.<br /><br />No deductible.<br /><br />$0 copay for in-network exams, x-rays, cleanings, fluoride, and fillings.<br /><br />50% coinsurance on crowns, bridges, root canals, extractions, dentures, and all other covered comprehensive services.<br /><br />Access to the largest Medicare Advantage dental network. Out-of-network coverage is available. If you choose to see an out-of-network dentist you might be billed more, even for services listed as $0 copay.'
            ]
          }
        ]
      },
      // Medical Benefits
      {
        tables: [
          {
            values: [
              '$0 - $325 copay',
              '$90 copay',
              '$0 for covered brands',
              '$0 copay',
              '$345 copay per day: days 1 - 5<br /><br />$0 copay per day for unlimited days afer that',
              '$0 copay',
              'Group: $15 copay<br /><br />Individual: $25 copay',
              '$0 copay',
              '$0 - $345 copay',
              '$0 for covered brands',
              '-',
              '$40 copay'
            ]
          }
        ]
      },
      // Extra Benefits and Programs
      {
        tables: [
          {
            values: [
              '$0 copay; 1 every year',
              '$0 copay; up to $100 every two years for frames or contact lenses. Standard single, bifocal, trifocal, or progressive lenses are covered in full. Home delivered eyewear availabile nationwide only through UnitedHealthcare Vision (select products only).',
              '$375 copay - $1,425 copay for each hearing aid provide through UnitedHealthcare Hearing, up to 2 hearing aids every year.<br /><br />Includes hearing aids delivered directly to you with virtual follow-up care through Right2You (select models), offered only by UnitedHealthcare Hearing.',
              'Fitness Membership Only: Basic membership in a fitness program at a network location at no additional cost',
              '-',
              '-',
              '-',
              '-',
              '-',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'UnitedHealthcare Medicare Advantage Choice Plan 1 (Regional PPO) ',
    summary:
      'This PPO plan gives you the freedom to see any doctor nationwide that accepts Medicare, without referrals for specialist or hospital visits.',
    rate: '$161.80',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              'This PPO plan gives you the freedom to see any doctor nationwide that accepts Medicare, without referrals for specialist or hospital visits.',

              'No Coverage',
              '$750',
              '$7,550'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: ['$5 copay', '$45 copay', 'No', 'Yes']
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '$317.74',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$9 copay (Tier 1 Initial Coverage Stage)</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$300 copay (Tier 4 Initial Coverage Stage)</div>'
            ]
          },
          {
            values: [
              '$0 for Tier 1, Tier 2<br /><br />$295 for Tier 3, Tier 4, Tier 5',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): 28% coinsurance',
              '-',
              '-',
              "During the Coverage Gap Stage, you (or others on your behalf) will pay no more than 25% of the negotiated price for drugs. You generally stay in this stage until your out-of-pocket cost reaches $7,050.<br /><br />You may pay less if your plan has additional coverage in the gap.<br /><br />Always use your Medicare Advantage member ID card during the coverage gap to get the plan's discounted drug rates. The money you spend using your card counts toward your out-of-pocket costs.",
              'After your total out-of-pocket costs reach $7,050, you will pay the greater of $3.95 copay for generic (including brand drugs treated as generic), and $9.85 copay for all other drugs, or 5% coinsurance.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with Walgreens Preferred Retail Pharmacy.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with OptumRx home delivery.'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: [
              '$0 copay for Preventive Services',
              '<span class="text-bold">Dental Platinum</span> for $40 a month<br /><br />$1,500 per year for covered dental services through the Platinum Dental Rider.<br /><br />No deductible.<br /><br />$0 copay for in-network exams, x-rays, cleanings, fluoride, and fillings.<br /><br />50% coinsurance on crowns, bridges, root canals, extractions, dentures, and all other covered comprehensive services.<br /><br />Access to the largest Medicare Advantage dental network. Out-of-network coverage is available. If you choose to see an out-of-network dentist you might be billed more, even for services listed as $0 copay.'
            ]
          }
        ]
      },
      // Medical Benefits
      {
        tables: [
          {
            values: [
              '$0 - $325 copay',
              '$90 copay',
              '$0 for covered brands',
              '$0 copay',
              '$345 copay per day: days 1 - 5<br /><br />$0 copay per day for unlimited days afer that',
              '$0 copay',
              'Group: $15 copay<br /><br />Individual: $25 copay',
              '$0 copay',
              '$0 - $345 copay',
              '$0 for covered brands',
              '-',
              '$40 copay'
            ]
          }
        ]
      },
      // Extra Benefits and Programs
      {
        tables: [
          {
            values: [
              '$0 copay; 1 every year',
              '$0 copay; up to $100 every two years for frames or contact lenses. Standard single, bifocal, trifocal, or progressive lenses are covered in full. Home delivered eyewear availabile nationwide only through UnitedHealthcare Vision (select products only).',
              '$375 copay - $1,425 copay for each hearing aid provide through UnitedHealthcare Hearing, up to 2 hearing aids every year.<br /><br />Includes hearing aids delivered directly to you with virtual follow-up care through Right2You (select models), offered only by UnitedHealthcare Hearing.',
              'Fitness Membership Only: Basic membership in a fitness program at a network location at no additional cost',
              '-',
              '-',
              '-',
              '-',
              '-',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'UnitedHealthcare Advantage Choice Plan 3 (Regionalal PPO)',
    summary:
      'This PPO plan gives you the freedom to see any doctor nationwide that accepts Medicare, without referrals for specialist or hospital visits.',
    rate: '$161.80',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              'This PPO plan gives you the freedom to see any doctor nationwide that accepts Medicare, without referrals for specialist or hospital visits.',
              'No Coverage',
              '$750',
              '$7,550'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: ['$5 copay', '$45 copay', 'No', 'Yes']
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '$317.74',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$9 copay (Tier 1 Initial Coverage Stage)</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$300 copay (Tier 4 Initial Coverage Stage)</div>'
            ]
          },
          {
            values: [
              '$0 for Tier 1, Tier 2<br /><br />$295 for Tier 3, Tier 4, Tier 5',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): 28% coinsurance',
              '-',
              '-',
              "During the Coverage Gap Stage, you (or others on your behalf) will pay no more than 25% of the negotiated price for drugs. You generally stay in this stage until your out-of-pocket cost reaches $7,050.<br /><br />You may pay less if your plan has additional coverage in the gap.<br /><br />Always use your Medicare Advantage member ID card during the coverage gap to get the plan's discounted drug rates. The money you spend using your card counts toward your out-of-pocket costs.",
              'After your total out-of-pocket costs reach $7,050, you will pay the greater of $3.95 copay for generic (including brand drugs treated as generic), and $9.85 copay for all other drugs, or 5% coinsurance.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with Walgreens Preferred Retail Pharmacy.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with OptumRx home delivery.'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: [
              '$0 copay for Preventive Services',
              '<span class="text-bold">Dental Platinum</span> for $40 a month<br /><br />$1,500 per year for covered dental services through the Platinum Dental Rider.<br /><br />No deductible.<br /><br />$0 copay for in-network exams, x-rays, cleanings, fluoride, and fillings.<br /><br />50% coinsurance on crowns, bridges, root canals, extractions, dentures, and all other covered comprehensive services.<br /><br />Access to the largest Medicare Advantage dental network. Out-of-network coverage is available. If you choose to see an out-of-network dentist you might be billed more, even for services listed as $0 copay.'
            ]
          }
        ]
      },
      // Medical Benefits
      {
        tables: [
          {
            values: [
              '$0 - $325 copay',
              '$90 copay',
              '$0 for covered brands',
              '$0 copay',
              '$345 copay per day: days 1 - 5<br /><br />$0 copay per day for unlimited days afer that',
              '$0 copay',
              'Group: $15 copay<br /><br />Individual: $25 copay',
              '$0 copay',
              '$0 - $345 copay',
              '$0 for covered brands',
              '-',
              '$40 copay'
            ]
          }
        ]
      },
      // Extra Benefits and Programs
      {
        tables: [
          {
            values: [
              '$0 copay; 1 every year',
              '$0 copay; up to $100 every two years for frames or contact lenses. Standard single, bifocal, trifocal, or progressive lenses are covered in full. Home delivered eyewear availabile nationwide only through UnitedHealthcare Vision (select products only).',
              '$375 copay - $1,425 copay for each hearing aid provide through UnitedHealthcare Hearing, up to 2 hearing aids every year.<br /><br />Includes hearing aids delivered directly to you with virtual follow-up care through Right2You (select models), offered only by UnitedHealthcare Hearing.',
              'Fitness Membership Only: Basic membership in a fitness program at a network location at no additional cost',
              '-',
              '-',
              '-',
              '-',
              '-',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'UnitedHealthcare Advantage Choice Plan 4 (Regional PPO)',
    summary:
      'This PPO plan gives you the freedom to see any doctor nationwide that accepts Medicare, without referrals for specialist or hospital visits.',
    rate: '$161.80',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              'This PPO plan gives you the freedom to see any doctor nationwide that accepts Medicare, without referrals for specialist or hospital visits.',

              'No Coverage',
              '$750',
              '$7,550'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: ['$5 copay', '$45 copay', 'No', 'Yes']
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '$317.74',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$9 copay (Tier 1 Initial Coverage Stage)</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$300 copay (Tier 4 Initial Coverage Stage)</div>'
            ]
          },
          {
            values: [
              '$0 for Tier 1, Tier 2<br /><br />$295 for Tier 3, Tier 4, Tier 5',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): 28% coinsurance',
              '-',
              '-',
              "During the Coverage Gap Stage, you (or others on your behalf) will pay no more than 25% of the negotiated price for drugs. You generally stay in this stage until your out-of-pocket cost reaches $7,050.<br /><br />You may pay less if your plan has additional coverage in the gap.<br /><br />Always use your Medicare Advantage member ID card during the coverage gap to get the plan's discounted drug rates. The money you spend using your card counts toward your out-of-pocket costs.",
              'After your total out-of-pocket costs reach $7,050, you will pay the greater of $3.95 copay for generic (including brand drugs treated as generic), and $9.85 copay for all other drugs, or 5% coinsurance.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with Walgreens Preferred Retail Pharmacy.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with OptumRx home delivery.'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: [
              '$0 copay for Preventive Services',
              '<span class="text-bold">Dental Platinum</span> for $40 a month<br /><br />$1,500 per year for covered dental services through the Platinum Dental Rider.<br /><br />No deductible.<br /><br />$0 copay for in-network exams, x-rays, cleanings, fluoride, and fillings.<br /><br />50% coinsurance on crowns, bridges, root canals, extractions, dentures, and all other covered comprehensive services.<br /><br />Access to the largest Medicare Advantage dental network. Out-of-network coverage is available. If you choose to see an out-of-network dentist you might be billed more, even for services listed as $0 copay.'
            ]
          }
        ]
      },
      // Medical Benefits
      {
        tables: [
          {
            values: [
              '$0 - $325 copay',
              '$90 copay',
              '$0 for covered brands',
              '$0 copay',
              '$345 copay per day: days 1 - 5<br /><br />$0 copay per day for unlimited days afer that',
              '$0 copay',
              'Group: $15 copay<br /><br />Individual: $25 copay',
              '$0 copay',
              '$0 - $345 copay',
              '$0 for covered brands',
              '-',
              '$40 copay'
            ]
          }
        ]
      },
      // Extra Benefits and Programs
      {
        tables: [
          {
            values: [
              '$0 copay; 1 every year',
              '$0 copay; up to $100 every two years for frames or contact lenses. Standard single, bifocal, trifocal, or progressive lenses are covered in full. Home delivered eyewear availabile nationwide only through UnitedHealthcare Vision (select products only).',
              '$375 copay - $1,425 copay for each hearing aid provide through UnitedHealthcare Hearing, up to 2 hearing aids every year.<br /><br />Includes hearing aids delivered directly to you with virtual follow-up care through Right2You (select models), offered only by UnitedHealthcare Hearing.',
              'Fitness Membership Only: Basic membership in a fitness program at a network location at no additional cost',
              '-',
              '-',
              '-',
              '-',
              '-',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'UnitedHeatlthcare Medicare Advantage Patriot (Regional PPO)',
    summary:
      'This PPO plan gives you the freedom to see any doctor nationwide that accepts Medicare, without referrals for specialist or hospital visits.',
    rate: '$161.80',
    sections: [
      {
        // Plan Costs
        tables: [
          {
            values: [
              'This PPO plan gives you the freedom to see any doctor nationwide that accepts Medicare, without referrals for specialist or hospital visits.',

              'No Coverage',
              '$750',
              '$7,550'
            ]
          }
        ]
      },
      {
        // Doctor Visits
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: ['$5 copay', '$45 copay', 'No', 'Yes']
          }
        ]
      },
      {
        // Prescription Drug Benefits
        tables: [
          {
            values: [
              '$317.74',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$9 copay (Tier 1 Initial Coverage Stage)</div>',
              '<div class="d-flex align-items-center justify-content-center justify-content-lg-start mb-5"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div><div class="text-small">$300 copay (Tier 4 Initial Coverage Stage)</div>'
            ]
          },
          {
            values: [
              '$0 for Tier 1, Tier 2<br /><br />$295 for Tier 3, Tier 4, Tier 5',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): $3 copay<br /><br />Preferred Mail Order Pharmacy (90 days): $0 copay<br /><br />Standard Mail Order Pharmacy (90 days): $9 copay',
              'Standard Network Pharmacy Cost Sharing (30 days): 28% coinsurance',
              '-',
              '-',
              "During the Coverage Gap Stage, you (or others on your behalf) will pay no more than 25% of the negotiated price for drugs. You generally stay in this stage until your out-of-pocket cost reaches $7,050.<br /><br />You may pay less if your plan has additional coverage in the gap.<br /><br />Always use your Medicare Advantage member ID card during the coverage gap to get the plan's discounted drug rates. The money you spend using your card counts toward your out-of-pocket costs.",
              'After your total out-of-pocket costs reach $7,050, you will pay the greater of $3.95 copay for generic (including brand drugs treated as generic), and $9.85 copay for all other drugs, or 5% coinsurance.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with Walgreens Preferred Retail Pharmacy.',
              '$0 copay for at least a 3-month supply of Tier 1 drugs with OptumRx home delivery.'
            ]
          }
        ]
      },
      {
        // Dental Coverage
        tables: [
          {
            values: [
              '<div class="d-flex align-items-center"><img class="d-block mr-5" src="/assets/icons/covered-check@2x.png" alt="" style="height:13px;width:17px;"/>In-Network</div>'
            ]
          },
          {
            values: [
              '$0 copay for Preventive Services',
              '<span class="text-bold">Dental Platinum</span> for $40 a month<br /><br />$1,500 per year for covered dental services through the Platinum Dental Rider.<br /><br />No deductible.<br /><br />$0 copay for in-network exams, x-rays, cleanings, fluoride, and fillings.<br /><br />50% coinsurance on crowns, bridges, root canals, extractions, dentures, and all other covered comprehensive services.<br /><br />Access to the largest Medicare Advantage dental network. Out-of-network coverage is available. If you choose to see an out-of-network dentist you might be billed more, even for services listed as $0 copay.'
            ]
          }
        ]
      },
      // Medical Benefits
      {
        tables: [
          {
            values: [
              '$0 - $325 copay',
              '$90 copay',
              '$0 for covered brands',
              '$0 copay',
              '$345 copay per day: days 1 - 5<br /><br />$0 copay per day for unlimited days afer that',
              '$0 copay',
              'Group: $15 copay<br /><br />Individual: $25 copay',
              '$0 copay',
              '$0 - $345 copay',
              '$0 for covered brands',
              '-',
              '$40 copay'
            ]
          }
        ]
      },
      // Extra Benefits and Programs
      {
        tables: [
          {
            values: [
              '$0 copay; 1 every year',
              '$0 copay; up to $100 every two years for frames or contact lenses. Standard single, bifocal, trifocal, or progressive lenses are covered in full. Home delivered eyewear availabile nationwide only through UnitedHealthcare Vision (select products only).',
              '$375 copay - $1,425 copay for each hearing aid provide through UnitedHealthcare Hearing, up to 2 hearing aids every year.<br /><br />Includes hearing aids delivered directly to you with virtual follow-up care through Right2You (select models), offered only by UnitedHealthcare Hearing.',
              'Fitness Membership Only: Basic membership in a fitness program at a network location at no additional cost',
              '-',
              '-',
              '-',
              '-',
              '-',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device',
              '$0 copay; Speak to network telehealth providers using your computer or mobile device'
            ]
          }
        ]
      },
      {
        // Plan Documents
        tables: [
          {
            values: [
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Summary of Benefits</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Resumen de Beneficios (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Evidence of Coverage</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Comprobante de Cobertura (PDF)</span></span></a></div></div>',
              '<div class="d-flex flex-column gap-10"><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Benefit Highlights</span></span></a></div><div><a class="uhc-link-button" tabindex="0"><span class="d-flex align-items-center"><img class="mr-10" src="/assets/icons/pdf-icon@2x.png" alt="" style="width: 14px; height: 16px;" /><span>Beneficios Importantes</span></span></a></div></div>'
            ]
          }
        ]
      }
    ]
  }
];

const pharmacyPlans1 = [
  {
    name: '0003',
    sections: [
      {
        // Preferred retail network pharmacy
        tables: [
          {
            values: [
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`
            ]
          }
        ]
      }
    ]
  },
  {
    name: '0004',
    sections: [
      {
        // Preferred retail network pharmacy
        tables: [
          {
            values: [
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`
            ]
          }
        ]
      }
    ]
  },
  {
    name: '0002',
    sections: [
      {
        // Preferred retail network pharmacy
        tables: [
          {
            values: [
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$47</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$100</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">33%</span>
              <div class="text-regular mt-5">of the cost</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$35</span>
              <div class="text-regular mt-5">copay</div>`
            ]
          }
        ]
      }
    ]
  },
  {
    name: '0001',
    sections: [
      {
        // Preferred retail network pharmacy
        tables: [
          {
            values: [
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`,
              `<span class="hl-item">See retail network pricing below</span>`
            ]
          }
        ]
      }
    ]
  }
];

const pharmacyPlans2 = [
  {
    name: '0003',
    sections: [
      {
        // Preferred retail network pharmacy
        tables: [
          {
            values: [
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$6</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$47</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$100</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">33%</span>
              <div class="text-regular mt-5">of the cost</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$35</span>
              <div class="text-regular mt-5">copay</div>`
            ]
          }
        ]
      }
    ]
  },
  {
    name: '0004',
    sections: [
      {
        // Preferred retail network pharmacy
        tables: [
          {
            values: [
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$47</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$100</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">33%</span>
              <div class="text-regular mt-5">of the cost</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$35</span>
              <div class="text-regular mt-5">copay</div>`
            ]
          }
        ]
      }
    ]
  },
  {
    name: '0002',
    sections: [
      {
        // Preferred retail network pharmacy
        tables: [
          {
            values: [
              `<span class="hl-item text-extra-large text-bold text-serif">$15</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$25</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$47</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$100</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">33%</span>
              <div class="text-regular mt-5">of the cost</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$35</span>
              <div class="text-regular mt-5">copay</div>`
            ]
          }
        ]
      }
    ]
  },
  {
    name: '0001',
    sections: [
      {
        // Preferred retail network pharmacy
        tables: [
          {
            values: [
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$5</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$45</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$95</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">33%</span>
              <div class="text-regular mt-5">of the cost</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$35</span>
              <div class="text-regular mt-5">copay</div>`
            ]
          }
        ]
      }
    ]
  }
];

const pharmacyPlans3 = [
  {
    name: '0003',
    sections: [
      {
        // Preferred retail network pharmacy
        tables: [
          {
            values: [
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$131</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$290</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">33%</span>
              <div class="text-regular mt-5">of the cost</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$95</span>
              <div class="text-regular mt-5">copay</div>`
            ]
          }
        ]
      }
    ]
  },
  {
    name: '0004',
    sections: [
      {
        // Preferred retail network pharmacy
        tables: [
          {
            values: [
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$131</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$290</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">33%</span>
              <div class="text-regular mt-5">of the cost</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$95</span>
              <div class="text-regular mt-5">copay</div>`
            ]
          }
        ]
      }
    ]
  },
  {
    name: '0002',
    sections: [
      {
        // Preferred retail network pharmacy
        tables: [
          {
            values: [
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$131</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$290</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">33%</span>
              <div class="text-regular mt-5">of the cost</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$95</span>
              <div class="text-regular mt-5">copay</div>`
            ]
          }
        ]
      }
    ]
  },
  {
    name: '0001',
    sections: [
      {
        // Preferred retail network pharmacy
        tables: [
          {
            values: [
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$0</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$125</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$275</span>
              <div class="text-regular mt-5">copay</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">33%</span>
              <div class="text-regular mt-5">of the cost</div>`,
              `<span class="hl-item text-extra-large text-bold text-serif">$95</span>
              <div class="text-regular mt-5">copay</div>`
            ]
          }
        ]
      }
    ]
  }
];

export {
  plansWithoutDetails,
  plansWithDetails,
  pharmacyPlans1,
  pharmacyPlans2,
  pharmacyPlans3
};
