import './radio.scss';

const Radio = (props) => {
  const {
    value,
    label,
    name,
    onCheckboxChange = () => {},
    updateDropButtonState = () => {}
  } = props;

  const handleChange = (e) => {
    onCheckboxChange(e);
    updateDropButtonState(e);
  };

  return (
    <label className={'checkbox checkbox__radio'}>
      <input
        className="checkbox__input"
        type="radio"
        value={value}
        onChange={handleChange}
        name={name}
      />
      <span className="checkbox__visual"></span>
      <span className="checkbox__label">{label}</span>
    </label>
  );
};

export default Radio;
