import { Button, Input } from '@bswing/uhc-core';
import LearnAboutPlansSection from 'partials/learn-about-plans-section/learn-about-plans-section';
import NotSureWhereToStartSection from 'partials/not-sure-where-to-start-section/not-sure-where-to-start-section';
import { Fragment } from 'react';

const ZipCodeSearch = ({ onSubmit = (e) => e.preventDefault() }) => {
  return (
    <Fragment>
      <div className="container container-lg">
        <div className="row">
          <div className="column column-12">
            <div
              className="uhc-zip-banner"
              style={{
                backgroundImage: 'url(/assets/images/zip-illustration@2x.png)'
              }}
            >
              <div className="uhc-zip-banner__heading display-large mb-30">
                Find Medicare plans in your area
              </div>
              <form
                className="d-flex align-items-center gap-20"
                onSubmit={onSubmit}
              >
                <Input
                  id="zip"
                  label={
                    <span className="d-flex align-items-center gap-5">
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        focusable="false"
                      >
                        <path
                          d="M10.5 1.75a6.12 6.12 0 0 0-6.125 6.125c0 4.594 6.125 11.375 6.125 11.375s6.125-6.781 6.125-11.375A6.12 6.12 0 0 0 10.5 1.75zm0 8.313a2.188 2.188 0 1 1 .002-4.377 2.188 2.188 0 0 1-.002 4.377z"
                          fill="currentColor"
                          fillRule="evenodd"
                        />
                      </svg>
                      <span>Enter your ZIP Code</span>
                    </span>
                  }
                  isIFTA={true}
                  style={{ width: 210, maxWidth: '100%' }}
                />
                <Button type="submit">Find plans</Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <LearnAboutPlansSection />
      <NotSureWhereToStartSection />
    </Fragment>
  );
};

export default ZipCodeSearch;
