import { Button, LinkButton } from '@bswing/uhc-core';
import classNames from 'classnames';
import EmptyState from 'components/empty-state/empty-state';
import UtilityTag from 'components/utility-tag/utility-tag';
import { Fragment } from 'react';
import ShopperProfileDoctorCoverageCTA from '../components/doctor-coverage-cta';
import ShopperProfileFAQs from '../components/faqs';
import ShopperProfileFindPlanCTA from '../components/find-plan-cta';
import { doctorsFaqData } from '../data/faq-data';

const ShopperProfileDoctorsPage = ({
  data = [],
  onAddDoctorsClick = () => {},
  onRemoveDoctorClick = () => {}
}) => {
  return (
    <Fragment>
      <div className="mb-30">
        <h2 className="mb-10">Your doctors and dentists</h2>
        <p>
          Create a personalized list of your doctors and dentists to see who is
          included in our nationwide network. You can add and remove your
          doctors and dentists as needed.
        </p>
      </div>

      <div className="mb-30">
        <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-20">
          <UtilityTag as="button" onClick={onAddDoctorsClick}>
            <span className="d-flex align-items-center">
              <img
                className="d-block mr-10"
                src="/assets/icons/circle-add-icon@2x.png"
                alt=""
                style={{ height: 20, width: 20 }}
              />
              <span className="text-blue-secondary text-bold">
                Add your doctors and dentists
              </span>
            </span>
          </UtilityTag>
          <LinkButton>
            <span className="d-flex align-items-center gap-10">
              <span>Import my doctors and dentists</span>
              <img
                src="/assets/icons/chevron-right-icon@2x.png"
                alt=""
                style={{ width: 8, height: 12 }}
              />
            </span>
          </LinkButton>
        </div>
      </div>

      {/* Populated State */}
      {data.length > 0 && (
        <Fragment>
          <div className="mb-30">
            <div className="d-flex flex-column gap-30">
              <div className="border border-rounded-sm">
                {data.map((doctor, index) => (
                  <div
                    className={classNames(
                      'd-flex align-items-start align-items-lg-center p-20',
                      {
                        'border-top': index > 0,
                        'bg-gray-2': index % 2 !== 0
                      }
                    )}
                    key={index}
                  >
                    <img
                      className="d-block mr-20"
                      src="/assets/images/stethoscope@2x.png"
                      alt=""
                      style={{
                        width: 48,
                        height: 48
                      }}
                    />
                    <div className="d-flex flex-column flex-lg-row align-items-lg-center flex gap-20 gap-lg-60">
                      <div className="flex">
                        <div className="text-bold mb-5">{doctor.name}</div>
                        <div className="mb-5">{doctor.speciality}</div>
                        <div>{doctor.address}</div>
                      </div>
                      <div className="d-flex align-items-center flex-row gap-20 gap-lg-60">
                        <LinkButton>Edit</LinkButton>
                        <LinkButton
                          onClick={() => onRemoveDoctorClick(doctor.id)}
                        >
                          Remove
                        </LinkButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <ShopperProfileDoctorCoverageCTA />
        </Fragment>
      )}

      {/* Empty State */}
      {data.length === 0 && (
        <div className="mb-30">
          <EmptyState>
            <img
              className="d-block mb-20"
              src="/assets/images/stethoscope@2x.png"
              alt=""
              style={{ width: 48, height: 48 }}
            />

            <p className="mb-20">
              Looks like you don't have any saved doctors or dentists yet
            </p>
            <Button isSecondary={true} onClick={onAddDoctorsClick}>
              Save your doctors and dentists
            </Button>
          </EmptyState>
        </div>
      )}
      <ShopperProfileFAQs faqs={doctorsFaqData} />
      <ShopperProfileFindPlanCTA />
    </Fragment>
  );
};

export default ShopperProfileDoctorsPage;
