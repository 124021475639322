import { useEffect } from 'react';

export default function useHighlight(isHighlighting, offset) {
  useEffect(() => {
    // remove all highlighted items first
    const allLights = document.querySelectorAll('.is-highlighted');
    const allRows = document.querySelectorAll('.hl-row');

    allLights.forEach((item) => {
      item.classList.remove('is-highlighted');
    });

    allRows.forEach((theRow) => {
      const allItems = theRow.querySelectorAll(
        '[data-visible="true"] .hl-item'
      );
      const allOON = theRow.querySelectorAll(
        '[data-visible="true"] .hl-item-oon'
      );
      let allItemsArray = [];
      let oonItemsArray = [];

      allItems.forEach((item) => {
        allItemsArray.push(item.outerText);
      });

      allOON.forEach((item) => {
        oonItemsArray.push(item.outerText);
      });

      allItemsArray = allItemsArray.filter(function (value, index, array) {
        return array.indexOf(value) == array.lastIndexOf(value);
      });

      oonItemsArray = oonItemsArray.filter(function (value, index, array) {
        return array.indexOf(value) == array.lastIndexOf(value);
      });

      allItemsArray.forEach((item) => {
        allItems.forEach((innerItem) => {
          if (innerItem.outerText === item) {
            if (isHighlighting === true) {
              innerItem.classList.add('is-highlighted');
              innerItem
                ?.closest('.hl-row')
                ?.querySelector('.hl-cat')
                ?.classList.add('is-highlighted');
            } else {
              innerItem.classList.remove('is-highlighted');
            }
          }
        });
      });

      oonItemsArray.forEach((item) => {
        allOON.forEach((innerItem) => {
          if (innerItem.outerText === item && oonItemsArray.length !== 1) {
            if (isHighlighting === true) {
              innerItem.classList.add('is-highlighted');
              innerItem
                ?.closest('.hl-row')
                ?.querySelector('.hl-cat')
                ?.classList.add('is-highlighted');
            } else {
              innerItem.classList.remove('is-highlighted');
            }
          }
        });
      });
    });
  }, [isHighlighting, offset]);
}
