import { LinkButton } from '@bswing/uhc-core';
import { withBreakpoints } from 'react-breakpoints';

const MSHomeHelp = (props) => {
  const { currentBreakpoint } = props;

  return (
    <div className="container container-lg mb-60 mb-lg-100">
      <div className="row">
        <div className="column column-12 column-lg-10 column-lg-shift-1">
          <div className="bg-gray-2 px-20 py-40 p-lg-100 rounded-xl">
            <div className="d-flex flex-column flex-lg-row align-items-center">
              <img
                className="d-block height-auto mr-lg-100 mb-20 mb-lg-0"
                src="/assets/images/illustration-support-rgb-full@2x.png"
                style={
                  currentBreakpoint === 'mobile'
                    ? { width: 120, maxWidth: '100%' }
                    : { width: 250, maxWidth: '100%' }
                }
                alt=""
              />
              <div className="text-center text-lg-left">
                <div className="display-large mb-10 mb-lg-20">
                  Still have questions?
                </div>
                <div className="mb-20 page-lead test-regular text-sans-serif">
                  <LinkButton>Call XXX-XXX-XXXX</LinkButton> to speak with a
                  [Medicare expert]
                </div>
                <div>
                  7AM - 11PM ET, Monday - Friday, 9AM - 5PM ET, Saturday
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withBreakpoints(MSHomeHelp);
