import { LinkButton } from '@bswing/uhc-core';

const AddLinkButton = () => (
  <LinkButton className="text-lg-large d-block">
    <span className="d-flex align-items-center">
      <img
        className="d-block mr-5"
        src="/assets/icons/add-box@2x.png"
        alt=""
        style={{ height: 24, width: 25 }}
      />
      <span>
        Add your dentists
        <span className="d-none d-lg-inline">
          {' '}
          to see if they are in network
        </span>
      </span>
    </span>
  </LinkButton>
);

const CoverageDentistsTable = (props) => {
  const { showDentists } = props;

  if (showDentists) {
    return (
      <div className="border border-rounded-sm overflow-hidden">
        <table style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr className="bg-gray-2">
              <th
                className="text-bold text-blue-primary p-20"
                style={{ width: '35%' }}
              >
                <span>Your dentists</span>{' '}
                <LinkButton className="ml-5 underline">Edit</LinkButton>
              </th>
              <th
                className="text-bold text-blue-primary p-20 d-none d-lg-table-cell"
                style={{ width: 'auto' }}
              >
                Coverage
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-20 valign-top" style={{ width: '35%' }}>
                <div className="d-flex align-items-start">
                  <div className="mr-10">
                    <img
                      src="/assets/icons/user-icon@2x.png"
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                  </div>
                  <div>
                    <div className="text-bold text-lg-large mb-5">
                      Ian Pasch DDS
                    </div>
                    <div>
                      4422 3rd Ave, Bronx, NY 10457
                      <br />
                      (718) 960-6486
                    </div>
                    <div className="d-block d-lg-none mt-10">
                      <div className="text-bold mb-5">Coverage:</div>
                      <div>
                        <img
                          className="mr-10"
                          src="/assets/icons/check-icon-alt@2x.png"
                          alt="Covered"
                          style={{ width: 17, height: 11 }}
                        />
                        <span>In-Network</span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td
                className="p-20 valign-top d-none d-lg-table-cell"
                style={{ width: 'auto' }}
              >
                <div className="d-flex align-items-start">
                  <div className="mr-10">
                    <img
                      src="/assets/icons/check-icon-alt@2x.png"
                      alt="Covered"
                      style={{ width: 17, height: 11 }}
                    />
                  </div>
                  <div>
                    <div className="text-lg-large">In-Network</div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="border border-rounded-sm overflow-hidden">
      <table style={{ tableLayout: 'fixed' }}>
        <tbody>
          <tr>
            <td className="p-20" style={{ width: '35%' }}>
              <span className="text-bold text-lg-large">Your dentists</span>
              <div className="d-block d-lg-none mt-10">
                <AddLinkButton />
              </div>
            </td>
            <td
              className="p-20 d-none d-lg-table-cell"
              style={{ width: 'auto' }}
            >
              <AddLinkButton />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CoverageDentistsTable;
