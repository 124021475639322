import { Button } from '@bswing/uhc-core';
import PropTypes from 'prop-types';
import './toast.scss';

const Toast = ({ message, showCTA, title, visible, onCTAClick, onDismiss }) => {
  return (
    <div
      className={`uhc-toast ${
        visible ? 'uhc-toast--enter' : 'uhc-toast--leave'
      }`}
    >
      <div className="d-flex align-items-start gap-10 p-20">
        <div className="flex-shrink-0">
          <img
            className="d-block"
            src="/assets/icons/ic_Heart_2C_onBlue.png"
            width="42"
            height="42"
            alt="Heart on blue"
          />
        </div>

        <div className="d-flex align-items-center gap-20 flex">
          <div className="flex">
            <p className="text-bold">{title}</p>
            <p className="text-small">{message}</p>
          </div>
          {showCTA && (
            <div className="pr-30">
              <Button isOutlined={true} isSecondary={true} onClick={onCTAClick}>
                Click here
              </Button>
            </div>
          )}
        </div>

        <button className="uhc-toast__close" onClick={onDismiss}>
          <span className="visually-hidden">Close</span>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden={true}
            focusable={false}
          >
            <path
              d="M2 2L10 10M2 10L10 2"
              stroke="#196ECF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

Toast.propTypes = {
  message: PropTypes.string,
  showCTA: PropTypes.bool,
  title: PropTypes.string,
  visible: PropTypes.bool,
  onCTAClick: PropTypes.func,
  onDismiss: PropTypes.func
};

Toast.defaultProps = {
  message: '',
  showCTA: false,
  title: '',
  visible: false,
  onCTAClick: () => {},
  onDismiss: () => {}
};

export default Toast;
