import { Button, Checkbox, LinkButton, Switch } from '@bswing/uhc-core';
import classNames from 'classnames';
import ControlButton from 'components/control-button/control-button';
import SlideTable from 'components/slide-table/slide-table';
import useScrollDirection from 'hooks/useScrollDirection';
import { Fragment, useEffect, useState } from 'react';
import { withBreakpoints } from 'react-breakpoints';
import Sticky from 'react-stickynode';
import { Popover } from 'react-tiny-popover';

const ADDITIONAL_PLANS = [
  {
    name: 'AARP Medicare Advantage Plan 2 (HMO)',
    premium: '$0'
  },
  {
    name: 'AARP Medicare Advantage Patriot (HMO)',
    premium: '$0'
  },
  {
    name: 'UnitedHealthcare Medicare Advantage Choice Plan 1 (Regional PPO)',
    premium: '$0'
  },
  {
    name: 'UnitedHealthcare Medicare Advantage Choice Plan 2 (Regional PPO)',
    premium: '$0'
  },
  {
    name: 'UnitedHealthcare Medicare Advantage Choice Plan 3 (Regional PPO)',
    premium: '$0'
  },
  {
    name: 'UnitedHealthcare Medicare Advantage Choice Plan 4 (Regional PPO)',
    premium: '$0'
  },
  {
    name: 'UnitedHealthcare Medicare Advantage Patriot (Regional PPO)',
    premium: '$0'
  }
];

const PlanCompareStickyHeader = (props) => {
  const {
    breakpoints,
    currentBreakpoint,
    isAgentView,
    isCurrentPlanHidden,
    isHighlighting,
    offset,
    plans,
    showAutoEnroll,
    onCurrentPlanChange,
    onHighlightChange,
    onNextClick,
    onPrevClick,
    onSwiped
  } = props;

  const [isPlanPopoverOpen, setIsPlanPopoverOpen] = useState(false);
  const direction = useScrollDirection();

  const calculateTableWidth = () => {
    const columnCount =
      breakpoints[currentBreakpoint] >= breakpoints.tablet ? 4 : 2;
    const nonPlanColumnCount =
      breakpoints[currentBreakpoint] >= breakpoints.tablet ? 2 : 1;

    const tableData =
      breakpoints[currentBreakpoint] >= breakpoints.tablet
        ? plans
        : plans.filter((x) => !x.isCurrentPlan);
    const widthScale = (tableData.length + nonPlanColumnCount) / columnCount;

    return {
      width: `calc(${widthScale * 100}%`
    };
  };

  return (
    <Sticky innerZ={10}>
      {({ status }) => (
        <div
          className={
            status === Sticky.STATUS_FIXED
              ? 'd-flex bg-white overflow-hidden shadow'
              : 'd-flex'
          }
        >
          <div className="container container-lg" style={{ marginBottom: -1 }}>
            <div className="row">
              <div className="column column-12">
                <div className="position-relative">
                  <ControlButton
                    className="position-absolute"
                    disabled={offset === 0}
                    onClick={onPrevClick}
                    style={{
                      top: '30%',
                      left: currentBreakpoint === 'mobile' ? -15 : -25,
                      zIndex: 5
                    }}
                  />
                  <ControlButton
                    className="position-absolute"
                    direction="right"
                    disabled={
                      offset ===
                      plans.length -
                        (breakpoints[currentBreakpoint] < breakpoints.tablet
                          ? 1
                          : 2)
                    }
                    onClick={onNextClick}
                    style={{
                      top: '30%',
                      right: currentBreakpoint === 'mobile' ? -15 : -25,
                      zIndex: 5
                    }}
                  />

                  <div
                    className={
                      (direction === 'scrolling up' ? 'is-active ' : ' ') +
                      `plan-compare-checkbox plan-compare-checkbox__sticky`
                    }
                    data-banner-highlight-mobile
                  >
                    <Checkbox
                      className=""
                      checked={isHighlighting}
                      label="Highlight Differences"
                      onChange={onHighlightChange}
                    />
                  </div>
                  <SlideTable
                    offset={offset}
                    showNextPreview={false}
                    onSwiped={onSwiped}
                    style={calculateTableWidth()}
                    header={
                      <Fragment>
                        <SlideTable.Row>
                          <SlideTable.Cell
                            isFrozen={true}
                            isVCentered={false}
                            isHeader={true}
                            rowSpan={2}
                          >
                            <div className="d-flex flex-column justify-content-between height-100">
                              <div className="d-none d-lg-block">
                                <div
                                  className="plan-compare-checkbox"
                                  data-banner-highlight
                                >
                                  <Checkbox
                                    checked={isHighlighting}
                                    label="Highlight Differences"
                                    onChange={onHighlightChange}
                                  />
                                </div>
                              </div>
                              <div>
                                <div>
                                  Showing 3 of {plans.length} plans available
                                </div>
                                <div>
                                  <LinkButton className="mt-10" href="#link">
                                    Show all {plans.length} plans
                                  </LinkButton>
                                </div>
                              </div>
                              {isAgentView &&
                                status !== Sticky.STATUS_FIXED && (
                                  <div className="d-flex flex-column align-items-start gap-10">
                                    <LinkButton>View All PDP Plans</LinkButton>
                                    <Switch label="Show DSNP and CSNP Plans" />
                                    <Switch
                                      checked={isCurrentPlanHidden}
                                      label="Hide Current Plan"
                                      onChange={onCurrentPlanChange}
                                    />
                                  </div>
                                )}
                            </div>
                          </SlideTable.Cell>
                          {plans.map((plan, index) => (
                            <SlideTable.Cell
                              className={
                                plan.isCurrentPlan
                                  ? 'bg-green-secondary text-bold text-left'
                                  : 'text-bold text-left bg-blue-primary'
                              }
                              key={index}
                              isFrozen={plan.isCurrentPlan}
                              isHeader={true}
                            >
                              <div className="d-flex align-items-start justify-content-between">
                                <a
                                  className={classNames(
                                    status === Sticky.STATUS_FIXED
                                      ? 'text-normal'
                                      : 'text-lg-large',
                                    plan.isCurrentPlan
                                      ? 'text-blue-primary'
                                      : 'text-white'
                                  )}
                                  href="#link"
                                >
                                  {plan.name}
                                </a>
                                {!plan.isCurrentPlan && (
                                  <LinkButton className="ml-15">
                                    <img
                                      className="d-block"
                                      src="/assets/icons/close-white-icon@2x.png"
                                      alt=""
                                      style={{
                                        height: 20,
                                        width: 20
                                      }}
                                    />
                                  </LinkButton>
                                )}
                              </div>
                            </SlideTable.Cell>
                          ))}
                          <SlideTable.Cell
                            isHCentered={true}
                            isVCentered={true}
                            isFrozen={false}
                            isHeader={true}
                            rowSpan="2"
                          >
                            <Popover
                              containerClassName="uhc-popover"
                              align="end"
                              isOpen={isPlanPopoverOpen}
                              padding={5}
                              positions={['bottom']}
                              content={
                                <div>
                                  <div className="d-flex flex-column gap-20 mb-20">
                                    {ADDITIONAL_PLANS.map((plan, index) => (
                                      <Checkbox
                                        key={index}
                                        label={
                                          <Fragment>
                                            <div className="mb-5">
                                              {plan.name}
                                            </div>
                                            <div className="text-regular">
                                              Monthly premium: {plan.premium}
                                            </div>
                                          </Fragment>
                                        }
                                      />
                                    ))}
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <LinkButton className="flex-1 text-center">
                                      Select All
                                    </LinkButton>
                                    <Button
                                      className="flex-1"
                                      isSecondary={true}
                                    >
                                      Compare Plans
                                    </Button>
                                  </div>
                                </div>
                              }
                              onClickOutside={() => setIsPlanPopoverOpen(false)}
                            >
                              <Button
                                className="overflow-hidden"
                                isOutlined={true}
                                isSecondary={true}
                                onClick={() =>
                                  setIsPlanPopoverOpen(!isPlanPopoverOpen)
                                }
                              >
                                <span className="d-flex align-items-center">
                                  <span>
                                    Add
                                    <span className="d-none d-lg-inline">
                                      {' '}
                                      Another Plan
                                    </span>
                                  </span>
                                  <img
                                    className="d-block mr-n10 ml-10"
                                    src="/assets/icons/full-icon-library-all-24-px-navigation-ic-expand-more@2x.png"
                                    alt=""
                                    style={{
                                      width: 24,
                                      height: 24
                                    }}
                                  />
                                </span>
                              </Button>
                            </Popover>
                          </SlideTable.Cell>
                        </SlideTable.Row>
                        <SlideTable.Row
                          className={
                            status === Sticky.STATUS_FIXED &&
                            breakpoints[currentBreakpoint] < breakpoints.tablet
                              ? ''
                              : 'border-none'
                          }
                        >
                          {plans.map((plan, index) => (
                            <SlideTable.Cell
                              key={index}
                              className={
                                plan.isCurrentPlan
                                  ? 'bg-green-secondary text-left'
                                  : 'text-left'
                              }
                              isFrozen={plan.isCurrentPlan}
                            >
                              {plan.isCurrentPlan && (
                                <div className="text-bold text-lg-large text-blue-primary">
                                  Current Plan
                                </div>
                              )}
                              {!plan.isCurrentPlan && (
                                <div className="d-flex flex-column width-100">
                                  {(status !== Sticky.STATUS_FIXED ||
                                    status == Sticky.STATUS_FIXED) && (
                                    <div className="d-flex text-center justify-content-center flex-wrap gap-10">
                                      <div>
                                        <div
                                          className="text-bold text-dark"
                                          style={{ fontSize: '24px' }}
                                        >
                                          {plan.rate}
                                        </div>
                                        <div className="text-regular">
                                          Monthly Premium
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div className="d-flex flex-row align-items-center flex-wrap gap-10">
                                    {status !== Sticky.STATUS_FIXED &&
                                      isAgentView && (
                                        <div className="flex">
                                          <div>{plan.contractId}</div>
                                          <div className="text-small text-bold">
                                            #{plan.rank} Rank
                                          </div>
                                        </div>
                                      )}
                                    {status === Sticky.STATUS_FIXED &&
                                      isAgentView && (
                                        <div className="flex">
                                          <div className="text-small text-bold mb-5">
                                            Monthly Premium: {plan.rate}
                                          </div>
                                          <div>{plan.contractId}</div>
                                          <div className="text-small text-bold">
                                            #{plan.rank} Rank
                                          </div>
                                        </div>
                                      )}
                                    {(!showAutoEnroll ||
                                      isAgentView ||
                                      index > 0) && (
                                      <div
                                        className={
                                          'd-flex align-items-center plan-button-contain gap-10 width-100 mt-20' +
                                          (direction === 'scrolling up'
                                            ? ' is-active '
                                            : ' ')
                                        }
                                      >
                                        {!isAgentView && (
                                          <Button>Enroll</Button>
                                        )}
                                        <Button
                                          className={'button-save'}
                                          isOutlined={true}
                                          isSecondary={true}
                                          style={{ flex: 1 }}
                                        >
                                          <span className="d-flex align-items-center">
                                            <img
                                              className="d-block"
                                              src="/assets/icons/heart-icon-small@2x.png"
                                              alt=""
                                              style={{
                                                height: 18,
                                                width: 18
                                              }}
                                            />
                                            <span className="flex text-center button-save-text">
                                              Save
                                            </span>
                                          </span>
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                  {isAgentView &&
                                    showAutoEnroll &&
                                    index === 1 && (
                                      <div className="d-flex align-items-start gap-10 mt-15">
                                        <svg
                                          className="flex-shrink-0"
                                          width="25"
                                          height="24"
                                          viewBox="0 0 25 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          aria-hidden="true"
                                          focusable="false"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M7.2334 7H17.2334V10L21.2334 6L17.2334 2V5H5.2334V11H7.2334V7ZM17.2334 17H7.2334V14L3.2334 18L7.2334 22V19H19.2334V13H17.2334V17Z"
                                            fill="#002677"
                                          />
                                        </svg>
                                        <div className="text-bold text-small">
                                          Automatically enrolled in this plan
                                        </div>
                                      </div>
                                    )}
                                  {!isAgentView &&
                                    showAutoEnroll &&
                                    index === 0 && (
                                      <div className="d-flex align-items-start gap-10 mt-5">
                                        <svg
                                          className="flex-shrink-0"
                                          width="25"
                                          height="24"
                                          viewBox="0 0 25 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          aria-hidden="true"
                                          focusable="false"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M7.2334 7H17.2334V10L21.2334 6L17.2334 2V5H5.2334V11H7.2334V7ZM17.2334 17H7.2334V14L3.2334 18L7.2334 22V19H19.2334V13H17.2334V17Z"
                                            fill="#002677"
                                          />
                                        </svg>
                                        <div className="text-bold text-small">
                                          You will be automatically enrolled in
                                          this plan for 2024.
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                            </SlideTable.Cell>
                          ))}
                        </SlideTable.Row>
                      </Fragment>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Sticky>
  );
};

export default withBreakpoints(PlanCompareStickyHeader);
