import { Button, LinkButton } from '@bswing/uhc-core';
import SPCard from 'components/sp-card/sp-card';
import { Link } from 'react-router-dom';

const SavedItemsCard = () => (
  <SPCard className="border">
    <div className="bg-gray-2 d-flex align-items-start align-items-lg-center p-20">
      <img
        className="d-block mr-20"
        src="/assets/images/ic_Heart_2C_onBlue@2x.png"
        alt=""
        style={{
          width: 48,
          height: 48
        }}
      />
      <div className="d-flex flex-column flex-lg-row align-items-lg-center flex gap-20 gap-lg-40">
        <div className="flex-1">
          <div className="mb-5">
            <LinkButton
              as={Link}
              className="text-large text-blue-primary"
              to="/account/plans/saved"
            >
              <span className="d-flex align-items-center gap-10">
                <span>Your guest account</span>
                <img
                  src="/assets/icons/chevron-right-darkblue-icon@2x.png"
                  alt=""
                  style={{ width: 8, height: 12 }}
                />
              </span>
            </LinkButton>
          </div>
          <div>
            The information you save to your account will be here for your next
            visit.
          </div>
        </div>
        <div className="d-flex align-items-center gap-20 flex-shrink-0 flex-wrap">
          <Button
            as={Link}
            href="https://marvelapp.com/prototype/76b08c7/screen/91038397"
            isOutlined={true}
            isSecondary={true}
          >
            Create an account
          </Button>
          <LinkButton href="https://marvelapp.com/prototype/76b08c7/screen/91038397">
            Sign in
          </LinkButton>
        </div>
      </div>
    </div>
  </SPCard>
);

export default SavedItemsCard;
