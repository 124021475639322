import { Autocomplete, Button, LinkButton } from '@bswing/uhc-core';
import classNames from 'classnames';
import SlideOver from 'components/slide-over/slide-over';
import { Fragment } from 'react';
import SavedItemsCard from './saved-items-card';

const AddDrugsSlideOver = ({
  isSearchMode = false,
  isShown = false,
  savedData = [],
  searchData = [],
  searchValue = '',
  onChange = () => {},
  onItemSelect = () => {},
  onRemove = () => {},
  onSearchSubmit = () => {},
  onCancel = () => {},
  onClose = () => {}
}) => {
  return (
    <SlideOver
      footer={
        <Fragment>
          <Button isSecondary={true} onClick={onClose}>
            Done
          </Button>
          {isSearchMode && (
            <Button isOutlined={true} isSecondary={true} onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Fragment>
      }
      isShown={isShown}
      title="Add your prescription drugs"
    >
      <div className="text-bold text-blue-primary mb-5">Your guest account</div>
      <h2 className="heading-1 mb-20 mb-lg-30">Add your prescription drugs</h2>
      <p className="border-top pt-20 pt-lg-30 mb-20">
        Add your drugs, then check to see if they are covered as you browse and
        compare plans in your area.
      </p>
      <form onSubmit={onSearchSubmit}>
        <div
          className="d-flex align-items-center gap-20 mt-20"
          style={{ maxWidth: '100%' }}
        >
          <div style={{ width: '100%', maxWidth: 360 }}>
            <Autocomplete
              className="d-block"
              id="drug-name"
              isBlock={true}
              isIFTA={true}
              items={searchData.map((item) => {
                return {
                  label: item.name,
                  value: item.id
                };
              })}
              label={
                <span>
                  Enter drug name <span className="text-red-primary">*</span>
                </span>
              }
              value={searchValue}
              onChange={onChange}
              onItemSelect={onItemSelect}
            />
          </div>
          <Button isSecondary={true} type="submit">
            <img
              className="d-block d-lg-none"
              src="/assets/icons/full-icon-library-all-24-px-action-ic-search@2x.png"
              alt="Search"
              style={{ width: 24, height: 24 }}
            />
            <span className="d-none d-lg-block">Search</span>
          </Button>
        </div>
      </form>
      <div className="border-top mt-20 pt-20 mt-lg-30 pt-lg-30">
        <div style={{ maxWidth: 800 }}>
          <div className="text-bold text-blue-primary mb-20">
            {isSearchMode
              ? `There are ${searchData.length} Results for "${searchValue}"`
              : 'Your saved drugs'}
          </div>
          {!isSearchMode && savedData.length > 0 && (
            <Fragment>
              <div className="border border-rounded-sm mb-20 mb-lg-30">
                {savedData.map((drug, index) => (
                  <div
                    className={classNames(
                      'd-flex align-items-start align-items-lg-center p-20',
                      {
                        'border-top': index > 0,
                        'bg-gray-2': index % 2 !== 0
                      }
                    )}
                    key={index}
                  >
                    <img
                      className="d-block mr-20"
                      src="/assets/images/local-prescription-pickup@2x.png"
                      alt=""
                      style={{
                        width: 48,
                        height: 48
                      }}
                    />
                    <div className="d-flex flex-column flex-lg-row align-items-lg-center flex gap-20 gap-lg-60">
                      <div className="flex">
                        <div className="text-bold mb-5">{drug.name}</div>
                        {drug.package && (
                          <div className="mb-5">{drug.package}</div>
                        )}
                        <div>{drug.dosage}</div>
                      </div>
                      <div className="d-flex align-items-center flex-row gap-20 gap-lg-60">
                        <LinkButton>Edit</LinkButton>
                        <LinkButton onClick={() => onRemove(drug.id)}>
                          Remove
                        </LinkButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Fragment>
          )}
          {!isSearchMode && savedData.length === 0 && (
            <div className="border border-rounded-sm paragraph p-20 mb-20 mb-lg-30">
              You do not have any drugs saved to your list. Use the search above
              to begin adding your drugs.
            </div>
          )}

          {/* Search Mode */}
          {isSearchMode && searchData.length > 0 && (
            <Fragment>
              <div className="border border-rounded-sm mb-20 mb-lg-30">
                {searchData.map((drug, index) => (
                  <div
                    className={classNames(
                      'd-flex align-items-start align-items-lg-center p-20',
                      {
                        'border-top': index > 0,
                        'bg-gray-2': index % 2 !== 0
                      }
                    )}
                    key={index}
                  >
                    <img
                      className="d-block mr-20"
                      src="/assets/images/local-prescription-pickup@2x.png"
                      alt=""
                      style={{
                        width: 48,
                        height: 48
                      }}
                    />
                    <div className="d-flex flex-column flex-lg-row align-items-lg-center flex gap-20 gap-lg-60">
                      <div className="flex">
                        <div className="text-bold mb-5">{drug.name}</div>
                        {drug.package && (
                          <div className="mb-5">{drug.package}</div>
                        )}
                        <div>{drug.dosage}</div>
                      </div>
                      <div className="d-flex align-items-center flex-row gap-20 gap-lg-60">
                        <Button
                          isOutlined={true}
                          isSecondary={true}
                          onClick={() => onItemSelect({ value: drug.id })}
                        >
                          Select
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Fragment>
          )}
          <SavedItemsCard />
        </div>
      </div>
    </SlideOver>
  );
};

export default AddDrugsSlideOver;
