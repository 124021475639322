import { Button, LinkButton, Menu } from '@bswing/uhc-core';
import PrintAndEmail from 'components/print-and-email/print-and-email';
import Tabs from 'components/tabs/tabs';
import tabsData from 'components/tabs/tabs-data.js';
import { Fragment } from 'react';
import { withBreakpoints } from 'react-breakpoints';

const MSPlanCompareHeader = (props) => {
  const {
    breakpoints,
    currentBreakpoint,
    isHighlighting,
    offset,
    plans,
    showInformationAdded,
    showTabs,
    onHighlightChange,
    onNextClick,
    onPrevClick
  } = props;

  return (
    <Fragment>
      <div className="container container-lg">
        <div className="row py-20">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
              <div className="mb-20">
                <LinkButton>
                  <img
                    className="mr-10"
                    src="/assets/icons/chevron-left-icon@2x.png"
                    alt=""
                    style={{ height: 12, width: 8, verticalAlign: -1 }}
                  />
                  Back to Plan List
                </LinkButton>
              </div>
              <div className="d-flex items-center">
                <img
                  className="mr-10"
                  src="/assets/icons/map-pin@2x.png"
                  alt=""
                  style={{ height: 21, width: 21 }}
                />
                <span>
                  Plans for{' '}
                  <LinkButton>
                    <span className="d-flex align-items-center">
                      <span>35203</span>
                      <img
                        className="d-block ml-5"
                        src="/assets/icons/pencil-copy@2x.png"
                        alt=""
                        style={{ height: 12, width: 12 }}
                      />
                    </span>
                  </LinkButton>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showTabs && (
        <div className="mb-20">
          <Tabs tabs={tabsData} />
        </div>
      )}
      <div className="container container-lg">
        <div className="row mb-20 mb-lg-30">
          <div className="column column-12">
            <h1 className="heading-1">
              Comparing AARP® Medicare Supplement Insurance Plans insured by
              UnitedHealthcare just got a little easier.
            </h1>
          </div>
        </div>
        <div className="d-none d-lg-block row mb-40">
          <div className="column column-12">
            <div className="paragraph text-large">
              Explore Medicare Supplement plans that fill the gaps and may cover
              some or all of the costs not covered by Medicare Parts A and B.
              Medigap plans allow you to choose any hospital or doctor that
              accepts Medicare patients, offer predictable out-of-pocket costs
              and provide nationwide coverage.
            </div>
          </div>
        </div>
        <div className="row mb-20 mb-lg-30">
          <div className="column column-12">
            <div className="bg-color-foam rounded-md p-20">
              <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between gap-20">
                <div>
                  <b>
                    {showInformationAdded ? 'Edit' : 'Add'} your information to
                    get a personal rate quote.
                  </b>{' '}
                  Premiums shown below are lowest available.
                </div>
                <LinkButton>
                  <span className="d-flex align-items-center gap-5">
                    <img
                      className="d-block"
                      src="/assets/icons/add-box@2x.png"
                      alt=""
                      style={{ height: 24, width: 25 }}
                    />
                    <span>
                      {showInformationAdded ? 'Edit' : 'Add'} your information
                    </span>
                  </span>
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-20 mb-lg-30">
          <div className="column column-12">
            <div className="d-flex align-items-center justify-content-lg-between">
              <div className="d-none d-lg-block"></div>
              <div className="d-flex align-items-center gap-20 flex-grow-1 flex-lg-grow-0">
                <div className="d-none d-lg-block mr-20">
                  <PrintAndEmail emailLabel="Email" printLabel="Print" />
                </div>
                <Menu
                  className="flex"
                  isBorderless={true}
                  items={[
                    'Defaults',
                    'Lowest premium',
                    'Highest premium',
                    'Lowest out-of-pocket maximum',
                    'Highest out-of-pocket maximum'
                  ]}
                  label={
                    <div>
                      <span className="text-bold">Sort:</span> Defaults
                    </div>
                  }
                  position="right"
                  onItemSelect={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withBreakpoints(MSPlanCompareHeader);
