import { Accordion } from '@bswing/uhc-core';

const ShopperProfileFAQs = ({ faqs }) => {
  return (
    <div className="mb-30">
      <h2 className="mb-30">Frequently asked questions</h2>
      {faqs.map((faq, index) => (
        <Accordion
          className={
            index + 1 === faqs.length
              ? 'border-top border-bottom'
              : 'border-top'
          }
          id={`faq-${index + 1}`}
          arrowColorOverride="#196ecf"
          header={<h3 className="heading-3">{faq.question}</h3>}
          isMinimal={true}
          key={index}
        >
          <div className="mb-20">{faq.answer}</div>
        </Accordion>
      ))}
    </div>
  );
};

export default ShopperProfileFAQs;
