import { Button, LinkButton, Tile } from '@bswing/uhc-core';

const MSPlanListCallout = () => {
  return (
    <div className="bg-gray-2 py-40 py-lg-60">
      <div className="container container-lg">
        <div className="row">
          <div className="column column-12 column-lg-10 column-lg-shift-1">
            <div className="d-flex flex-column flex-lg-row gap-20 mb-40 mb-lg-60">
              <Tile className="flex" id="tile-callout-1">
                <div className="height-100 d-flex flex-column align-items-start justify-content-lg-between flex gap-30 gap-20 gap-lg-30">
                  <div>
                    <h2 className="heading-2 mb-20 mb-lg-30">
                      Already an Insured Member?
                    </h2>
                    <p>
                      For accurate information about rates and plans if you are
                      currently insured under an AARP Medicare Supplement Plan,
                      please call UnitedHealthcare for information.
                    </p>
                  </div>
                  <LinkButton className="text-large">
                    Call <span className="underline">1-866-606-3424</span> (TTY
                    711)
                  </LinkButton>
                </div>
              </Tile>
              <Tile className="flex" id="tile-callout-2">
                <div className="height-100 d-flex flex-column align-items-stretch align-items-lg-start justify-content-lg-between flex gap-20 gap-lg-30">
                  <div>
                    <h2 className="heading-2 mb-20 mb-lg-30">
                      Meet with a Sales Agent
                    </h2>
                    <p>
                      Make an appointment with a UnitedHealthcare licensed
                      insurance agent.
                    </p>
                  </div>
                  <Button isOutlined={true} isSecondary={true}>
                    Find an Agent
                  </Button>
                </div>
              </Tile>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center gap-20 gap-lg-15 text-center">
              <img
                className="d-block"
                src="/assets/images/full-brand-icon-library-communication-ic-phone-2-c-on-blue@2x.png"
                alt=""
                style={{ height: 48, width: 49 }}
              />
              <div className="text-large">
                <span className="text-bold">Need help?</span> Speak with a
                Medicare expert today toll free, 7AM - 11PM ET, Monday - Friday,
                9AM - 5PM ET, Saturday
              </div>
              <LinkButton className="text-large">
                <span className="underline">1-855-939-4744</span> (TTY 711)
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSPlanListCallout;
