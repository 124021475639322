import { Fragment } from 'react';
import { Button, Input, LinkButton } from '@bswing/uhc-core';
import { useNavigate } from 'react-router-dom';

const MSHomeBanner = ({ autoDetectZIP, samType }) => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = '/';
    switch (samType) {
      case 'one':
        path = `/sam1b`;
        break;
      case 'two':
        path = `/sam2b`;
        break;
      // …
      case 'three':
        path = `/sam3b`;
        break;
      default:
    }
    navigate(path);
  };

  return (
    <div className="position-relative pt-lg-50 pb-lg-100 overflow-hidden">
      <div className="position-lg-absolute inset-0">
        <img
          className="object-cover height-100 width-100"
          src="/assets/images/h-canoe@2x.jpg"
          alt=""
        />
      </div>
      <div className="container container-lg">
        <div className="row">
          <div className="column column-12 column-lg-5">
            <div className="bg-white p-30 mx-n20 mx-lg-0">
              <h1 className="display-large mb-15">
                Hassle-free Medicare Coverage
              </h1>
              <div className="text-large text-blue-primary mb-20">
                Explore AARP Medicare Supplement Insurance Plans from
                UnitedHealthcare.
              </div>
              {autoDetectZIP && (
                <Fragment>
                  <div className="d-flex align-items-center gap-5 mb-30 text-large text-blue-primary">
                    <img
                      src="/assets/icons/map-pin-blue@2x.png"
                      alt=""
                      style={{ height: 21, width: 21 }}
                    />
                    <span>
                      Plans for{' '}
                      <LinkButton className="text-large">
                        <span className="d-flex align-items-center gap-10">
                          <span>10001 New York County</span>
                          <img
                            src="/assets/icons/pencil-copy@2x.png"
                            alt=""
                            style={{ height: 12, width: 12 }}
                          />
                        </span>
                      </LinkButton>
                    </span>
                  </div>
                  <Button isSecondary={true}>Shop all plans</Button>
                </Fragment>
              )}
              {!autoDetectZIP && (
                <form>
                  <div className="heading-5 text-blue-primary mb-20">
                    Enter your ZIP Code to browse plans specific to your area.
                  </div>
                  <div className="d-flex align-items-center gap-20">
                    <Input
                      isIFTA={true}
                      label={
                        <span className="d-flex align-items-center gap-5">
                          <svg
                            width="13px"
                            height="19px"
                            viewBox="0 0 13 19"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <g
                                transform="translate(-210.000000, -437.000000)"
                                fill="#5A5A5A"
                              >
                                <g transform="translate(197.000000, 310.000000)">
                                  <g transform="translate(0.000000, 108.000000)">
                                    <g transform="translate(13.375000, 19.750000)">
                                      <path d="M6.125,0 C2.73875,0 0,2.73875 0,6.125 C0,10.71875 6.125,17.5 6.125,17.5 C6.125,17.5 12.25,10.71875 12.25,6.125 C12.25,2.73875 9.51125,0 6.125,0 L6.125,0 Z M6.125,8.3125 C4.9175,8.3125 3.9375,7.3325 3.9375,6.125 C3.9375,4.9175 4.9175,3.9375 6.125,3.9375 C7.3325,3.9375 8.3125,4.9175 8.3125,6.125 C8.3125,7.3325 7.3325,8.3125 6.125,8.3125 L6.125,8.3125 Z"></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                          <span>Enter your ZIP Code</span>
                        </span>
                      }
                      type="text"
                    />
                    <Button className="flex-1" onClick={routeChange}>
                      Browse Plans
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSHomeBanner;
