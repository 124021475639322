import { Button } from '@bswing/uhc-core';
import SideNav from 'components/side-nav/side-nav';
import { Fragment } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ShopperProfileHeader from './components/header';
import { mockNavData } from './data/nav-data';
import ShopperProfileDisclaimers from './partials/disclaimers';

const ShopperProfileLayout = ({ children, isAuthenticated = false }) => {
  let location = useLocation();

  const sideNavItems = mockNavData.map((item) => {
    return {
      ...item,
      isActive: location.pathname.includes(item.href)
    };
  });

  return (
    <Fragment>
      <ShopperProfileHeader isAuthenciated={isAuthenticated} />
      <div className="container container-lg mb-40 pt-80 pt-lg-100">
        <div className="row">
          <div className="column column-12 column-lg-3 mb-30 mb-lg-0">
            <div className="mt-20 mt-lg-0">
              <SideNav items={sideNavItems} />
            </div>
            <div className="d-none d-lg-block border border-rounded-sm mt-30 p-20">
              <div className="d-flex align-items-center gap-10 mb-15">
                <img
                  src="/assets/icons/search-icon@2x.png"
                  alt=""
                  width="24"
                  height="24"
                />
                <div className="text-bold">Looking for plans?</div>
              </div>
              <div className="mb-15">
                There are 20 plans available in your area.
              </div>
              <Button
                className="d-block width-100"
                isOutlined={true}
                isSecondary={true}
              >
                Browse all plans
              </Button>
            </div>
          </div>
          <div className="column column-12 column-lg-9">
            {children || <Outlet />}
          </div>
        </div>
      </div>
      <ShopperProfileDisclaimers />
    </Fragment>
  );
};

export default ShopperProfileLayout;
