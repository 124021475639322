import maSnpAnimation from './animation-data/MA&SNP.json';
import medSuppAnimation from './animation-data/MedSupp.json';
import pdpAnimation from './animation-data/PDP.json';

const tabsData = [
  {
    name: 'Medicare Advantage Plans',
    href: '/medicare-advantage-plans',
    image: '/assets/icons/tab-ma-snp@2x.png',
    animationData: maSnpAnimation,
    planCount: 14
  },
  {
    name: 'Medicare Supplement Insurance Plans',
    href: '/medicare-supplement-plans',
    image: '/assets/icons/tab-ms@2x.png',
    animationData: medSuppAnimation,
    planCount: 14
  },
  {
    name: 'Medicare Prescription Drug (Part D) Plans',
    href: '/plan-summary/medicare-prescription-drug-plans',
    image: '/assets/icons/tab-rx@2x.png',
    animationData: pdpAnimation,
    planCount: 3
  },
  // {
  //   name: 'Medicare Special Needs Plans',
  //   href: '/plan-summary/medicare-special-needs-plans',
  //   image: '/assets/icons/tab-ma-snp@2x.png',
  //   animationData: maSnpAnimation,
  //   planCount: 0
  // }
];

export default tabsData;
