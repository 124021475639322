import { Button, LinkButton, Menu, Tile } from '@bswing/uhc-core';
import { Fragment } from 'react';
import { withBreakpoints } from 'react-breakpoints';

const TILES1 = [
  {
    imageSrc: '/assets/images/icon-illustrations-comprehensive-coverage@2x.png',
    title: 'I want a plan with the most comprehensive coverage.',
    subtitle:
      'Look at plans that offer low to no out-of-pockets costs to give you more peace of mind.'
  },
  {
    imageSrc: '/assets/images/icon-illustrations-popular@2x.png',
    title: 'I want to see the plans most people choose.',
    subtitle: "Look at plans that offer the coverage and freedom you'd expect."
  },
  {
    imageSrc: '/assets/images/icon-illustrations-budget@2x.png',
    title: 'I want a lower premium plan.',
    subtitle:
      'Look at lower premium plans and pay out of pocket for care when you need it.'
  },
  {
    imageSrc: '/assets/images/icon-illustrations-hsa@2x.png',
    title: 'I want to use my Health Savings Account.',
    subtitle:
      'Look at some plans where you can use your HSA to help pay for copays, coinsurance and other out-of-pocket costs.'
  }
];

const TILES2 = [
  {
    imageSrc: '/assets/images/lawn_516x222@2x.jpg',
    title: 'Compare AARP Medicare Supplement Plans',
    linkText: 'Compare plans'
  },
  {
    imageSrc: '/assets/images/Sailing_516x222@2x.jpg',
    title: 'Cover your prescription drug costs with a Medicare Part D plan',
    linkText: 'View plans'
  }
];

const MSHomePlanLinks = ({
  autoDetectZIP,
  currentBreakpoint,
  limitedPlansAvailable,
  showHelpSection,
  useAlternateLayout
}) => {
  return (
    <div className="bg-teal-quaternary">
      <div className="container container-lg">
        {!useAlternateLayout && !limitedPlansAvailable && autoDetectZIP && (
          <Fragment>
            <div className="row">
              <div className="column column-12">
                <div className="d-flex flex-column flex-lg-row align-items-stretch justify-content-stretch gap-20 pt-20 pt-lg-0 mt-lg-n60">
                  {TILES1.map((tile, index) => (
                    <Tile className="flex" id={`tile-${index + 1}`} key={index}>
                      <div className="height-100 d-flex flex-column align-items-start justify-content-between">
                        <div>
                          <img
                            className="d-block mb-30"
                            src={tile.imageSrc}
                            alt=""
                            style={
                              currentBreakpoint === 'mobile'
                                ? { height: 52, width: 52 }
                                : { height: 96, width: 96 }
                            }
                          />
                          <h2 className="mb-15 heading-3 text-sans-serif text-bold">
                            {tile.title}
                          </h2>
                          <div className="mb-20 text-large">
                            {tile.subtitle}
                          </div>
                        </div>
                        <Button isOutlined={true} isSecondary={true}>
                          See plans
                        </Button>
                      </div>
                    </Tile>
                  ))}
                </div>
              </div>
            </div>
            <div className="row mt-50">
              <div className="column column-12">
                <div className="d-lg-flex justify-content-lg-center">
                  <Menu
                    buttonClassName="bg-none border-bottom border-blue-secondary border-thick py-15 pr-0"
                    className="d-block d-lg-inline-block width-100 width-lg-auto"
                    id="plan-jump"
                    isBorderless={true}
                    items={['Plan X', 'Plan Y', 'Plan Z']}
                    label={
                      <span className="d-flex align-items-center justify-content-between gap-0 gap-lg-100">
                        <span className="d-block heading-3 text-sans-serif text-bold text-blue-primary">
                          Jump to a specific plan
                        </span>
                        <img
                          className="d-block"
                          src="/assets/icons/full-icon-library-all-24-px-navigation-ic-expand-more-light@2x.png"
                          alt=""
                          style={{ height: 24, width: 24 }}
                        />
                      </span>
                    }
                    onItemSelect={() => {}}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {!useAlternateLayout && limitedPlansAvailable && (
          <div className="row">
            <div className="column column-12 column-lg-8 column-lg-shift-2">
              <div className="pt-20 pt-lg-0 mt-lg-n40">
                <div className="bg-white p-30 px-lg-100 rounded-xl">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <div className="mb-10 heading-2">Browse our plans.</div>
                      <div>
                        <LinkButton className="text-large">
                          <span className="d-flex align-items-center gap-10">
                            <span>Shop plans available in New York</span>
                            <img
                              className="d-block"
                              src="/assets/icons/full-icon-library-all-24-px-navigation-ic-arrow-forward@2x.png"
                              alt=""
                              style={{ height: 24, width: 24 }}
                            />
                          </span>
                        </LinkButton>
                      </div>
                    </div>
                    <img
                      className="d-none d-lg-block align-self-end mb-n30"
                      src="/assets/images/zip-illustration@2x.png"
                      alt=""
                      style={{ width: 'auto', height: 150 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {useAlternateLayout && (
          <div className="row mt-lg-60 pb-lg-100">
            <div className="column column-12">
              <div className="d-flex flex-column flex-lg-row gap-20 py-20 pt-lg-0 mt-lg-n60">
                {TILES2.map((tile, index) => (
                  <Tile
                    className="flex"
                    id={`tile-${index + 1}`}
                    imageSrc={tile.imageSrc}
                    key={index}
                    showImage={true}
                  >
                    <div className="height-100 d-flex flex-column align-items-start justify-content-lg-between flex">
                      <h2 className="heading-2 mb-20 mb-lg-80">{tile.title}</h2>
                      <LinkButton
                        className="text-large"
                        isOutlined={true}
                        isSecondary={true}
                      >
                        <span className="d-flex align-items-center gap-10">
                          <span>{tile.linkText}</span>
                          <img
                            className="d-block"
                            src="/assets/icons/full-icon-library-all-24-px-navigation-ic-arrow-forward@2x.png"
                            alt=""
                            style={{ height: 24, width: 24 }}
                          />
                        </span>
                      </LinkButton>
                    </div>
                  </Tile>
                ))}
              </div>
            </div>
          </div>
        )}
        {showHelpSection && (
          <div className="row py-40 py-lg-60">
            <div className="column column-12">
              <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center gap-15 text-center">
                <img
                  className="d-none d-lg-block"
                  src="/assets/images/full-brand-icon-library-communication-ic-phone-2-c-on-blue@2x.png"
                  alt=""
                  style={{ height: 48, width: 49 }}
                />
                <div className="text-large">
                  <span className="text-bold">Need help?</span> Speak with a
                  Medicare expert today toll free, 7AM - 11PM ET, Monday -
                  Friday, 9AM - 5PM ET, Saturday
                </div>
                <LinkButton className="text-large">
                  <span className="underline">1-855-939-4744</span> (TTY 711)
                </LinkButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withBreakpoints(MSHomePlanLinks);
