import {
  Button,
  Checkbox,
  HorizontalRule,
  LinkButton,
  // Radio,
  Switch
  // Tag
} from '@bswing/uhc-core';
import PrintAndEmail from 'components/print-and-email/print-and-email';
import PlanYearToggle from 'partials/plan-year-toggle/plan-year-toggle';
import { useState } from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Popover } from 'react-tiny-popover';

const PlanCompareHeader = (props) => {
  const { isAgentView, isHighlighting, showPlanToggle, onHighlightChange } =
    props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  // const [isPlanTypePopoverOpen, setIsPlanTypePopoverOpen] = useState(false);

  return (
    <div className="pt-20 pt-lg-30">
      <div className="container container-lg">
        <div className="row mb-lg-40">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
              <LinkButton className="mb-20 mb-lg-0">
                <img
                  className="mr-10"
                  src="/assets/icons/chevron-left-icon@2x.png"
                  alt=""
                  style={{ height: 12, width: 8, verticalAlign: -1 }}
                />
                View all Medicare Advantage plans (9)
              </LinkButton>
              <div className="d-flex flex-column flex-lg-row items-center">
                <div className="d-flex items-center mr-0 mr-lg-30 mb-20 mb-lg-0">
                  <img
                    className="mr-10"
                    src="/assets/icons/map-pin@2x.png"
                    alt=""
                    style={{ height: 21, width: 21 }}
                  />
                  <span>
                    Plans for{' '}
                    <LinkButton className="underline">
                      63101 St. Louis, MO
                    </LinkButton>
                  </span>
                </div>
                <div className="d-lg-block mb-20">
                  <PrintAndEmail emailLabel="Email" printLabel="Print" />
                </div>
              </div>
            </div>
            {isAgentView && (
              <div>
                <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between gap-20 mt-lg-20">
                  <div className="d-none d-lg-block flex-1"></div>
                  <div className="uhc-agent-alert flex-1">
                    You are in Agent mode viewing MEMBER TEST profile
                  </div>
                  <div className="d-flex justify-content-lg-end flex-1">
                    <Button isOutlined={true} isSecondary={true}>
                      Save to Mira
                    </Button>
                  </div>
                </div>
                <div className="border-rounded-md bg-gray-2 p-20 mt-20 mt-lg-30">
                  <div className="row row-collapse">
                    <div className="column column-12 column-lg-3 mb-15 mb-lg-0">
                      <div className="d-flex align-items-center gap-10">
                        <svg
                          className="d-block"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden={true}
                          focusable={false}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
                            fill="#196ECF"
                          />
                        </svg>
                        <div>
                          <span className="text-bold">Member Test</span>{' '}
                          (#3GG4-RR5-DE22)
                        </div>
                      </div>
                    </div>
                    <div className="column column-12 column-lg-3 mb-15 mb-lg-0">
                      <div>Member status: Current Member</div>
                    </div>
                    <div className="column column-12 column-lg-2 mb-15 mb-lg-0">
                      <div>DOB: 07/29/1957</div>
                    </div>
                    <div className="column column-12 column-lg-4 mb-15 mb-lg-0">
                      <div>UUID: cbe42aed-2884-4623-8fffc-b15e9840a4d7</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-center gap-10 gap-lg-80 mt-20 mt-lg-30 mb-20">
                  <LinkButton>
                    Medicare Advantage and Prescription Drug Plans
                  </LinkButton>
                  <LinkButton>Medicare Supplement Plans</LinkButton>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row mb-lg-20">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row flex-wrap align-items-lg-center justify-content-lg-between gap-lg-20">
              <div className="heading-1 mb-10 mb-lg-0">
                Compare Medicare Advantage Plans for 63101
              </div>
            </div>
            {showPlanToggle && (
              <div className="mt-20">
                <PlanYearToggle />
              </div>
            )}
          </div>
        </div>
        {isAgentView && (
          <div className="row mb-20">
            <div className="column column-12">
              <div className="d-flex align-items-center flex-wrap gap-20">
                <div>
                  <div className="text-bold mb-5">Plan Ranking</div>
                  <Popover
                    containerClassName="uhc-popover"
                    align="start"
                    isOpen={isPopoverOpen}
                    padding={5}
                    positions={['bottom']}
                    content={
                      <div>
                        <div className="d-flex flex-column gap-20 mb-20">
                          {[
                            'Drugs',
                            'Doctors/Providers',
                            'Lower Premium',
                            'Dental',
                            'Hearing',
                            'Vision',
                            'Fitness'
                          ].map((value, index) => (
                            <Checkbox key={index} label={value} />
                          ))}
                        </div>
                        <HorizontalRule className="mb-20" isNarrow={true} />
                        <div className="d-flex align-items-center justify-content-between">
                          <Button isOutlined={true} isSecondary={true}>
                            Apply
                          </Button>
                        </div>
                      </div>
                    }
                    onClickOutside={() => setIsPopoverOpen(false)}
                  >
                    <button
                      className="uhc-sort-button"
                      onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                    >
                      <span className="d-flex align-items-center">
                        <span>Rank plans by...</span>
                        <img
                          className="d-block mr-n10 ml-10"
                          src="/assets/icons/full-icon-library-all-24-px-navigation-ic-expand-more@2x.png"
                          alt=""
                          style={{
                            width: 24,
                            height: 24
                          }}
                        />
                      </span>
                    </button>
                  </Popover>
                </div>
                {/* <div>
                  <div className="text-bold mb-5">Select Plan Type</div>
                  <Popover
                    containerClassName="uhc-popover"
                    align="start"
                    isOpen={isPlanTypePopoverOpen}
                    padding={5}
                    positions={['bottom']}
                    content={
                      <div>
                        <div className="d-flex flex-column gap-20 mb-20">
                          {[
                            'Medicare Advantage Plans',
                            'Medicare Supplement Plans',
                            'Prescription Drug Plans'
                          ].map((value, index) => (
                            <div className="border-bottom pb-20" key={index}>
                              <Radio
                                key={index}
                                label={value}
                                name="plan-type"
                              />
                              {value === 'Medicare Advantage Plans' && (
                                <div className="d-flex flex-column gap-20 mt-20 ml-20">
                                  <Checkbox label={<span>HMO</span>} />
                                  <Checkbox label={<span>PPO</span>} />
                                  <Checkbox
                                    label={
                                      <span>
                                        D-SNP{' '}
                                        <span className="text-regular">
                                          (for people with both Medicare and
                                          Medicaid)
                                        </span>
                                      </span>
                                    }
                                  />
                                  <Checkbox
                                    label={
                                      <span>
                                        C-SNP{' '}
                                        <span className="text-regular">
                                          (for people with diabetes,
                                          cardiovascular disease, or chronic
                                          heart failure)
                                        </span>
                                      </span>
                                    }
                                  />
                                  <Checkbox
                                    label={
                                      <span>
                                        I-SNP{' '}
                                        <span className="text-regular">
                                          (for people in a nursing home or
                                          assisted living)
                                        </span>
                                      </span>
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <Button isOutlined={true} isSecondary={true}>
                            Apply
                          </Button>
                        </div>
                      </div>
                    }
                    onClickOutside={() => setIsPlanTypePopoverOpen(false)}
                  >
                    <button
                      className="uhc-sort-button"
                      onClick={() =>
                        setIsPlanTypePopoverOpen(!isPlanTypePopoverOpen)
                      }
                    >
                      <span className="d-flex align-items-center">
                        <span>Medicare Advantage Plans</span>
                        <img
                          className="d-block mr-n10 ml-10"
                          src="/assets/icons/full-icon-library-all-24-px-navigation-ic-expand-more@2x.png"
                          alt=""
                          style={{
                            width: 24,
                            height: 24
                          }}
                        />
                      </span>
                    </button>
                  </Popover>
                </div> */}
              </div>
              {/* <div className="mt-20">
                <div className="d-flex align-items-center flex-wrap gap-15">
                  <Tag isDismissable={true}>
                    <span className="text-bold">D-SNP</span>&nbsp;(for people
                    with both Medicare and Medicaid)
                  </Tag>
                  <Tag isDismissable={true}>
                    <span className="text-bold">C-SNP</span>&nbsp;(for people
                    with diabetes, cardiovascular disease, or chronic heart
                    failure)
                  </Tag>
                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withBreakpoints(PlanCompareHeader);
