import './auto-enroll.scss';

const AutoEnroll = ({ onLearnClick = () => {} }) => {
  const handleClick = (event) => {
    event.preventDefault();
    onLearnClick(event);
  };

  return (
    <div className="uhc-auto-enroll">
      <span>
        [Member name], you will be automatically enrolled in this plan for 2024.
      </span>
      <a href="#link" onClick={handleClick}>
        Learn more
      </a>
      <div className="uhc-auto-enroll__arrow" />
    </div>
  );
};

export default AutoEnroll;
