import { Accordion, LinkButton } from '@bswing/uhc-core';
import SlideTable from 'components/slide-table/slide-table';
import { Fragment, useEffect, useState } from 'react';
import ReactBreakpoints, { Media } from 'react-breakpoints';
import PlanCompareDisclaimer from './plan-compare-disclaimer/plan-compare-disclaimer';
import PlanCompareHeader from './plan-compare-header/plan-compare-header';
import PlanCompareStickyHeader from './plan-compare-sticky-header/plan-compare-sticky-header';
import useHighlight from 'hooks/useHighlight';
import HighlightBanner from './plan-compare-highlight-banner';

import { planSectionsPreferredPharmacy } from 'data/plan-sections';
import { pharmacyPlans1, pharmacyPlans2, pharmacyPlans3 } from 'data/plans';

const breakpoints = {
  mobile: 320,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200
};

const PlanCompare = (props) => {
  const {
    data = [],
    isAgentView = false,
    sections = [],
    showAutoEnroll = false,
    showDetails = false,
    showDrugPricingUnavailable = false,
    showPlanToggle
  } = props;

  const [filteredData, setFilteredData] = useState(data);
  const [isCurrentPlanHidden, setIsCurrentPlanHidden] = useState(false);
  const [isHighlighting, setIsHighlighting] = useState(false);
  const [offset, setOffset] = useState(0);

  // functionality for the highlight toggle
  useHighlight(isHighlighting, offset);

  const handleCurrentPlanChange = () => {
    setIsCurrentPlanHidden(!isCurrentPlanHidden);
  };

  const handleHighlightChange = () => {
    setIsHighlighting(!isHighlighting);
  };

  const handleNextClick = (breakpoints, currentBreakpoint) => {
    incrementOffset(breakpoints, currentBreakpoint);
  };

  const handlePrevClick = (breakpoints, currentBreakpoint) => {
    decrementOffset(breakpoints, currentBreakpoint);
  };

  const handleSwipe = (event, breakpoints, currentBreakpoint) => {
    event.dir === 'Left'
      ? incrementOffset(breakpoints, currentBreakpoint)
      : decrementOffset(breakpoints, currentBreakpoint);
  };

  const decrementOffset = (breakpoints, currentBreakpoint) => {
    const newOffset = Math.max(0, offset - 1);
    setOffset(newOffset);
  };

  const incrementOffset = (breakpoints, currentBreakpoint) => {
    const maxOffsetAllowance =
      breakpoints[currentBreakpoint] < breakpoints.tablet ? 1 : 2;
    const maxOffset = filteredData.length - maxOffsetAllowance;
    const newOffset = Math.min(offset + 1, maxOffset);
    setOffset(newOffset);
  };

  const calculateTableWidth = (breakpoints, currentBreakpoint) => {
    const columnCount =
      breakpoints[currentBreakpoint] >= breakpoints.tablet ? 4 : 2;
    const nonPlanColumnCount =
      breakpoints[currentBreakpoint] >= breakpoints.tablet ? 2 : 1;

    const widthScale = (filteredData.length + nonPlanColumnCount) / columnCount;

    return {
      width: `calc(${widthScale * 100}%)`
    };
  };

  useEffect(() => {
    const fixOffset = () => {
      if (offset > filteredData.length - 2 && window.innerWidth >= 768) {
        setOffset(filteredData.length - 2);
      }
    };

    window.addEventListener('resize', fixOffset);

    return () => window.removeEventListener('resize', fixOffset);
  }, [filteredData, offset]);

  // hide current plan
  useEffect(() => {
    if (isCurrentPlanHidden) {
      setFilteredData(data.filter((x) => !x.isCurrentPlan));
    } else {
      setFilteredData(data);
    }
  }, [data, isCurrentPlanHidden]);

  const renderHeader = () => {
    return (
      <SlideTable.Row className="d-none d-lg-table-row">
        <SlideTable.Cell className="bg-gray-2" isHeader={true} isFrozen={true}>
          &nbsp;
        </SlideTable.Cell>
        {filteredData &&
          filteredData.map((plan, index) => (
            <SlideTable.Cell
              className="bg-gray-2"
              isFrozen={plan.isCurrentPlan}
              isHeader={true}
              isVCentered={true}
              key={index}
            >
              <span className="text-small">{plan.name}</span>
            </SlideTable.Cell>
          ))}
        <SlideTable.Cell className="bg-gray-2" isHeader={true} />
      </SlideTable.Row>
    );
  };

  const renderRows = (
    sectionIndex,
    tableIndex,
    data = sections,
    theRows = filteredData
  ) => {
    return data[sectionIndex]?.tables[tableIndex]?.rows?.map(
      (row, rowIndex) => (
        <Fragment key={rowIndex}>
          <SlideTable.Row isMobileHeading={true}>
            <SlideTable.Cell
              isVCentered={true}
              isHeader={true}
              isFrozen={true}
              isMobileHeading={true}
              colSpan={2}
            >
              <span className="mobile-heading-contain">
                <span>
                  <span
                    className="text-bold"
                    dangerouslySetInnerHTML={{ __html: row.headline }}
                  />
                  <div
                    className="mt-5 mobile-heading-description"
                    dangerouslySetInnerHTML={{ __html: row.description }}
                  ></div>
                </span>
                {row.description && (
                  <img
                    className="mobile-heading-arrow"
                    src="/assets/icons/chevron-down-icon@2x.png"
                    alt=""
                  />
                )}
              </span>
            </SlideTable.Cell>
          </SlideTable.Row>
          <SlideTable.Row>
            <SlideTable.Cell isVCentered={false} isFrozen={true}>
              <span
                className="text-bold"
                dangerouslySetInnerHTML={{ __html: row.headline }}
              />
              <span
                className="mt-5"
                dangerouslySetInnerHTML={{ __html: row.description }}
              />
            </SlideTable.Cell>
            {theRows &&
              theRows.map((plan, planIndex) => (
                <SlideTable.Cell
                  key={planIndex}
                  isFrozen={plan.isCurrentPlan}
                  isVCentered={true}
                >
                  {renderValue(
                    plan.sections[sectionIndex]?.tables[tableIndex]?.values[
                      rowIndex
                    ]
                  )}
                </SlideTable.Cell>
              ))}
            <SlideTable.Cell />
          </SlideTable.Row>
        </Fragment>
      )
    );
  };

  const renderValue = (value) => {
    if (value) {
      return <div dangerouslySetInnerHTML={{ __html: value }} />;
    }

    return null;
  };

  return (
    <ReactBreakpoints breakpoints={breakpoints}>
      <Media>
        {({ breakpoints, currentBreakpoint }) => (
          <Fragment>
            <PlanCompareHeader
              isAgentView={isAgentView}
              isHighlighting={isHighlighting}
              offset={offset}
              plans={filteredData}
              showPlanToggle={showPlanToggle}
              onHighlightChange={handleHighlightChange}
              onNextClick={() =>
                handleNextClick(breakpoints, currentBreakpoint)
              }
              onPrevClick={() =>
                handlePrevClick(breakpoints, currentBreakpoint)
              }
            />

            <PlanCompareStickyHeader
              isAgentView={isAgentView}
              isCurrentPlanHidden={isCurrentPlanHidden}
              isHighlighting={isHighlighting}
              offset={offset}
              plans={filteredData}
              showAutoEnroll={showAutoEnroll}
              onCurrentPlanChange={handleCurrentPlanChange}
              onHighlightChange={handleHighlightChange}
              onNextClick={() =>
                handleNextClick(breakpoints, currentBreakpoint)
              }
              onPrevClick={() =>
                handlePrevClick(breakpoints, currentBreakpoint)
              }
              onSwiped={(event) =>
                handleSwipe(event, breakpoints, currentBreakpoint)
              }
            />

            <div className="container container-lg">
              <div className="row">
                <div className="column column-12">
                  <SlideTable
                    offset={offset}
                    showNextPreview={false}
                    onSwiped={(event) =>
                      handleSwipe(event, breakpoints, currentBreakpoint)
                    }
                    style={calculateTableWidth(breakpoints, currentBreakpoint)}
                  >
                    {renderRows(0, 0)}
                  </SlideTable>
                </div>
              </div>
            </div>

            <div className="container container-lg my-20">
              {/* Iterate through plan sections */}
              {sections.map(
                (section, sectionIndex) =>
                  sectionIndex > 0 && (
                    <div className="row" key={sectionIndex}>
                      {section.name === 'Prescription Drug Benefits' &&
                        !isHighlighting && (
                          <div className="column column-12">
                            <div>
                              <HighlightBanner />
                            </div>
                          </div>
                        )}
                      <div className="column column-12">
                        <Accordion
                          className={
                            sectionIndex + 1 === sections.length
                              ? 'border-top border-bottom'
                              : 'border-top'
                          }
                          id={`plan-compare-section-${sectionIndex}`}
                          arrowColorOverride="#196ecf"
                          isExpanded={true}
                          isMinimal={true}
                          header={
                            <div
                              className="heading-2 text-blue-primary"
                              dangerouslySetInnerHTML={{ __html: section.name }}
                            />
                          }
                        >
                          {section.name === 'Prescription Drug Benefits' &&
                            showDrugPricingUnavailable && (
                              <div className="bg-teal-quaternary d-flex align-items-start align-items-lg-center border-rounded-sm p-20 mb-20">
                                <img
                                  className="d-block mr-15"
                                  src="/assets/icons/alert-icon@2x.png"
                                  alt=""
                                  style={{ width: 24, height: 24 }}
                                />
                                <p>
                                  REMEDI SENIORCARE provides Prescription Drug
                                  Coverage for this plan. For drug pricing at
                                  this Pharmacy, call UnitedHealthcare:
                                  1-877-699-5710 (TTY 711).{' '}
                                  <LinkButton>Change Pharmacy</LinkButton>
                                </p>
                              </div>
                            )}
                          {section.name === 'Prescription Drug Benefits' &&
                            showDetails && (
                              <div className="d-flex align-items-center mb-20">
                                <img
                                  className="d-block mr-10"
                                  src="/assets/icons/map-pin@2x.png"
                                  alt=""
                                  style={{ width: 21, height: 21 }}
                                />
                                <span className="text-lg-large">
                                  Pharmacy: Walgreens 333 7th Ave
                                </span>{' '}
                                <LinkButton className="ml-20 underline">
                                  Edit
                                </LinkButton>
                              </div>
                            )}
                          {isAgentView && section.name === 'Doctor Visits' && (
                            <div className="mb-20">
                              <LinkButton>PCP Recommendation</LinkButton>
                            </div>
                          )}
                          {isAgentView &&
                            section.name === 'Baseline Benefits' && (
                              <div className="mb-20">
                                <p>
                                  If no network is shown, there is no coverage.
                                </p>
                              </div>
                            )}
                          {/* Iterate through section tables */}
                          {section.tables.map((table, tableIndex) => (
                            <div className="pb-30" key={tableIndex}>
                              <SlideTable
                                offset={offset}
                                isRounded={true}
                                showNextPreview={false}
                                onSwiped={(event) =>
                                  handleSwipe(
                                    event,
                                    breakpoints,
                                    currentBreakpoint
                                  )
                                }
                                style={calculateTableWidth(
                                  breakpoints,
                                  currentBreakpoint
                                )}
                              >
                                {renderRows(sectionIndex, tableIndex)}
                              </SlideTable>
                            </div>
                          ))}

                          {section.name === 'Prescription Drug Benefits' && (
                            <div class="sub-accordions">
                              <Accordion
                                className={'sub-accordion'}
                                id={`plan-compare-additional-1`}
                                arrowColorOverride="#196ecf"
                                isExpanded={false}
                                isMinimal={true}
                                header={
                                  <div
                                    className="heading-2 text-blue-primary"
                                    dangerouslySetInnerHTML={{
                                      __html: `Preferred retail network pharmacy`
                                    }}
                                  />
                                }
                              >
                                {planSectionsPreferredPharmacy[0].tables.map(
                                  (table, tableIndex) => (
                                    <div className="" key={tableIndex}>
                                      <SlideTable
                                        offset={offset}
                                        isRounded={true}
                                        showNextPreview={false}
                                        onSwiped={(event) =>
                                          handleSwipe(
                                            event,
                                            breakpoints,
                                            currentBreakpoint
                                          )
                                        }
                                        style={calculateTableWidth(
                                          breakpoints,
                                          currentBreakpoint
                                        )}
                                      >
                                        {renderRows(
                                          0,
                                          tableIndex,
                                          planSectionsPreferredPharmacy,
                                          pharmacyPlans1
                                        )}
                                      </SlideTable>
                                    </div>
                                  )
                                )}
                              </Accordion>
                              <Accordion
                                className={'sub-accordion'}
                                id={`plan-compare-additional-1`}
                                arrowColorOverride="#196ecf"
                                isExpanded={false}
                                isMinimal={true}
                                header={
                                  <div
                                    className="heading-2 text-blue-primary"
                                    dangerouslySetInnerHTML={{
                                      __html: `Retail network pharmacy (30 day)`
                                    }}
                                  />
                                }
                              >
                                {planSectionsPreferredPharmacy[0].tables.map(
                                  (table, tableIndex) => (
                                    <div className="" key={tableIndex}>
                                      <SlideTable
                                        offset={offset}
                                        isRounded={true}
                                        showNextPreview={false}
                                        onSwiped={(event) =>
                                          handleSwipe(
                                            event,
                                            breakpoints,
                                            currentBreakpoint
                                          )
                                        }
                                        style={calculateTableWidth(
                                          breakpoints,
                                          currentBreakpoint
                                        )}
                                      >
                                        {renderRows(
                                          0,
                                          tableIndex,
                                          planSectionsPreferredPharmacy,
                                          pharmacyPlans2
                                        )}
                                      </SlideTable>
                                    </div>
                                  )
                                )}
                              </Accordion>
                              <Accordion
                                className={'sub-accordion'}
                                id={`plan-compare-additional-1`}
                                arrowColorOverride="#196ecf"
                                isExpanded={false}
                                isMinimal={true}
                                header={
                                  <div
                                    className="heading-2 text-blue-primary"
                                    dangerouslySetInnerHTML={{
                                      __html: `Preferred mail order pharmacy (100 day)`
                                    }}
                                  />
                                }
                              >
                                {planSectionsPreferredPharmacy[0].tables.map(
                                  (table, tableIndex) => (
                                    <div className="" key={tableIndex}>
                                      <SlideTable
                                        offset={offset}
                                        isRounded={true}
                                        showNextPreview={false}
                                        onSwiped={(event) =>
                                          handleSwipe(
                                            event,
                                            breakpoints,
                                            currentBreakpoint
                                          )
                                        }
                                        style={calculateTableWidth(
                                          breakpoints,
                                          currentBreakpoint
                                        )}
                                      >
                                        {renderRows(
                                          0,
                                          tableIndex,
                                          planSectionsPreferredPharmacy,
                                          pharmacyPlans3
                                        )}
                                      </SlideTable>
                                    </div>
                                  )
                                )}
                              </Accordion>
                            </div>
                          )}
                        </Accordion>
                      </div>
                    </div>
                  )
              )}
            </div>

            {/* <div className="container container-lg my-20 my-lg-40">
              <div className="row">
                <div className="column column-12">
                  <SlideTable
                    offset={offset}
                    header={renderHeader()}
                    isRounded={true}
                    showNextPreview={false}
                    onSwiped={(event) =>
                      handleSwipe(event, breakpoints, currentBreakpoint)
                    }
                    style={calculateTableWidth(breakpoints, currentBreakpoint)}
                  >
                    <Fragment>
                      <SlideTable.Row>
                        <SlideTable.Cell
                          isVCentered={true}
                          isHeader={true}
                          isFrozen={true}
                          isMobileHeading={true}
                          colSpan={2}
                        >
                          <span className="text-bold">More Information</span>
                        </SlideTable.Cell>
                      </SlideTable.Row>
                      <SlideTable.Row>
                        <SlideTable.Cell isVCentered={false} isFrozen={true}>
                          <span className="text-bold">More Information</span>
                        </SlideTable.Cell>
                        {filteredData &&
                          filteredData.map((plan, planIndex) => (
                            <SlideTable.Cell
                              key={planIndex}
                              isVCentered={true}
                              style={
                                isHighlighting && Math.random() < 0.2
                                  ? { backgroundColor: '#fffbe4' }
                                  : {}
                              }
                            >
                              <LinkButton>View plan details</LinkButton>
                            </SlideTable.Cell>
                          ))}
                        <SlideTable.Cell />
                      </SlideTable.Row>
                    </Fragment>
                  </SlideTable>
                </div>
              </div>
            </div> */}

            <PlanCompareDisclaimer />
          </Fragment>
        )}
      </Media>
    </ReactBreakpoints>
  );
};

export default PlanCompare;
