import { Tile } from '@bswing/uhc-core';
import LayeredTile from 'components/layered-tile/layered-tile';

const MSHomeMosaic = ({ useAlternateMosaic }) => {
  return (
    <div className="container container-lg mb-60 mb-lg-100">
      <div className="row">
        <div className="column column-12 column-lg-5 column-lg-shift-1">
          <div className="d-flex flex-column gap-20">
            {useAlternateMosaic && (
              <Tile
                id="tile-1"
                imageSrc="/assets/images/h-ocean@2x.jpg"
                showImage={true}
              >
                <div className="bg-teal-quaternary m-n30 p-30 text-center">
                  <div className="d-flex align-items-center justify-content-center gap-15 mb-20">
                    <img
                      src="/assets/icons/illustrated-icon-handshake-gol-rgb-1@2x.png"
                      alt=""
                      style={{ height: 61, width: 65 }}
                    />
                    <div className="d-flex align-items-start">
                      <span
                        className="text-serif text-blue-primary"
                        style={{ fontSize: 60 }}
                      >
                        94
                      </span>
                      <span className="heading-3 mt-10">%</span>
                    </div>
                  </div>
                  <p className="text-large">
                    of active members renew their plan from year to year
                    <sup>
                      <a className="text-bold no-underline" href="#footnote-1">
                        1
                      </a>
                    </sup>
                  </p>
                </div>
              </Tile>
            )}
            {!useAlternateMosaic && (
              <LayeredTile id="tile-1" imageSrc="/assets/images/h-ocean@2x.jpg">
                <div className="bg-teal-quaternary p-30 text-center border border-thick rounded-xl overflow-hidden">
                  <div className="d-flex align-items-center justify-content-center mb-10">
                    <img
                      src="/assets/icons/illustrated-icon-handshake-gol-rgb-1@2x.png"
                      alt=""
                      style={{ height: 61, width: 65 }}
                    />
                    <div className="d-flex align-items-start">
                      <span
                        className="text-serif text-blue-primary"
                        style={{ fontSize: 60 }}
                      >
                        94
                      </span>
                      <span className="heading-3 mt-10">%</span>
                    </div>
                  </div>
                  <p className="text-large">
                    of active members renew their plan from year to year
                    <sup>
                      <a className="text-bold no-underline" href="#footnote-1">
                        1
                      </a>
                    </sup>
                  </p>
                </div>
              </LayeredTile>
            )}
            <Tile
              id="tile-2"
              hiddenContent={
                <div className="mt-30">
                  <ul className="text-bold">
                    <li className="mb-10">
                      Like your doctor? Keep them.
                      <sup>
                        <a
                          className="text-bold no-underline"
                          href="#footnote-2"
                        >
                          2
                        </a>
                      </sup>
                    </li>
                    <li className="mb-10">No surprise out-of-pocket costs.</li>
                    <li className="mb-10">
                      Your plan goes with you when traveling in the U.S.
                    </li>
                    <li className="mb-10">
                      No networks. No referrals needed.
                      <sup>
                        <a
                          className="text-bold no-underline"
                          href="#footnote-2"
                        >
                          2
                        </a>
                      </sup>
                    </li>
                    <li>
                      Guaranteed coverage for life.
                      <sup>
                        <a
                          className="text-bold no-underline"
                          href="#footnote-3"
                        >
                          3
                        </a>
                      </sup>
                    </li>
                  </ul>
                </div>
              }
              isExpandable={true}
              isSolidColor={true}
            >
              <div>
                <h2 className="heading-2 mb-20 mb-lg-30">
                  The biggest benefit is peace of mind
                </h2>
                <p className="text-large">
                  A Medicare Supplement plan puts the control right where it
                  belongs...with you. Discover some of the features of Medicare
                  Supplement plans.
                </p>
              </div>
            </Tile>
            {useAlternateMosaic && (
              <Tile
                id="tile-3"
                imageSrc="/assets/images/h-running-watch@2x.jpg"
                showImage={true}
              >
                <div className="bg-teal-quaternary m-n30 p-30">
                  <div className="d-flex align-items-start gap-20">
                    <img
                      src="/assets/icons/illustrated-icon-face-happy-rgb@2x.png"
                      alt=""
                      style={{ height: 44, width: 44 }}
                    />
                    <div>
                      <div className="heading-1 mb-20">More than 4 million</div>
                      <p className="text-large">
                        insured members nationwide
                        <sup>
                          <a
                            className="text-bold no-underline"
                            href="#footnote-1"
                          >
                            1
                          </a>
                        </sup>
                      </p>
                    </div>
                  </div>
                </div>
              </Tile>
            )}
            {!useAlternateMosaic && (
              <LayeredTile
                id="tile-3"
                imageSrc="/assets/images/h-running-watch@2x.jpg"
                position="bottom-right"
              >
                <div className="bg-teal-quaternary p-30 border border-thick rounded-xl overflow-hidden">
                  <div className="d-flex align-items-center gap-20">
                    <img
                      src="/assets/icons/illustrated-icon-face-happy-rgb@2x.png"
                      alt=""
                      style={{ height: 36, width: 36 }}
                    />
                    <div className="heading-3">More than</div>
                  </div>
                  <div className="display-large mb-10">4 million</div>
                  <div className="text-large">
                    insured members nationwide
                    <sup>
                      <a className="text-bold no-underline" href="#footnote-1">
                        1
                      </a>
                    </sup>
                  </div>
                </div>
              </LayeredTile>
            )}
          </div>
        </div>
        <div className="column column-12 column-lg-5 column-lg-shift-1">
          <div className="d-flex flex-column gap-20 mt-20 mt-lg-50">
            <Tile
              id="tile-4"
              hiddenContent={
                <div className="mt-30">
                  <ul className="text-bold">
                    <li className="mb-10">Dental Discount</li>
                    <li className="mb-10">Hearing Discount</li>
                    <li className="mb-10">Vision Discount</li>
                    <li className="mb-10">Gym Membership at no cost</li>
                    <li>24/7 Nurse line</li>
                  </ul>
                </div>
              }
              imageSrc="/assets/images/h-hat@2x.jpg"
              isExpandable={true}
              showImage={true}
            >
              <div>
                <h2 className="heading-2 mb-20 mb-lg-30">
                  Even more reasons to choose an AARP Medicare Supplement Plan
                </h2>
                <p className="text-large">
                  An AARP Medicare Supplement Plan, insured by UnitedHealthcare
                  Insurance Company, comes along with wellness extras, insured
                  member services and discounts
                  <sup>
                    <a className="text-bold no-underline" href="#footnote-4">
                      4
                    </a>
                  </sup>{' '}
                  like:
                </p>
              </div>
            </Tile>
            <Tile
              id="tile-5"
              hiddenContent={
                <div className="mt-30">
                  <p className="text-large">
                    <b>Strength in numbers</b>
                    <br />
                    More than 4 million insured members nationwide
                    <sup>
                      <a className="text-bold no-underline" href="#footnote-1">
                        1
                      </a>
                    </sup>
                  </p>
                  <p className="text-large mt-20">
                    <b>Loyalty</b>
                    <br />
                    94% of members keep the same plan each year.
                    <sup>
                      <a className="text-bold no-underline" href="#footnote-1">
                        1
                      </a>
                    </sup>
                  </p>
                  <p className="text-large mt-20">
                    <b>Endorsed by AARP</b>
                    <br />
                    The only Medicare Supplement plans endorsed by AARP.
                  </p>
                </div>
              }
              isExpandable={true}
              isSolidColor={true}
            >
              <div>
                <h2 className="heading-2 mb-20 mb-lg-30">
                  Providing coverage and building relationships for over 40
                  years
                </h2>
                <p className="text-large">
                  In addition to the standard benefits of Medigap plans, an AARP
                  Medicare Supplement Insurance Plan from UnitedHealthcare has
                  many features that stand out.
                </p>
              </div>
            </Tile>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSHomeMosaic;
