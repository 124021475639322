import classNames from 'classnames';
import './arrow-panel.scss';

const ArrowPanel = (props) => {
  const { children, className, ...rest } = props;
  const componentClassName = classNames('uhc-arrow-panel', {}, className);
  return (
    <div className={componentClassName} {...rest}>
      <div className="uhc-arrow-panel__arrow" />
      <div className="uhc-arrow-panel__main">{children}</div>
    </div>
  );
};

export default ArrowPanel;
