import classNames from 'classnames';

import './filter-button.scss';

const FilterButtonDrop = (props) => {
  const {
    activeDrop,
    label,
    selections,
    checked = false,
    manageFilters = () => {}
  } = props;

  const componentClassName = classNames('filter-button-drop', {
    isActive: checked,
    isFocused: activeDrop === label
  });

  const handleClick = (event) => {
    manageFilters(event);
  };

  return (
    <>
      <button
        className={componentClassName}
        onClick={handleClick}
        data-label={label}
      >
        {label}
        {selections.length > 0 && 
          <span className="filter-button-drop__plus">
            {`(${selections.length})`}
          </span>
        }
        
      </button>
    </>
  );
};

export default FilterButtonDrop;
