import { Button, LinkButton, Switch } from '@bswing/uhc-core';
import ControlButton from 'components/control-button/control-button';
import SlideTable from 'components/slide-table/slide-table';
import { Fragment } from 'react';
import { withBreakpoints } from 'react-breakpoints';
import Sticky from 'react-stickynode';
import useScrollDirection from 'hooks/useScrollDirection';

const MSPlanCompareStickyHeader = (props) => {
  const {
    breakpoints,
    currentBreakpoint,
    isHighlighting,
    offset,
    plans,
    showInformationAdded,
    onHighlightChange,
    onNextClick,
    onPrevClick,
    onSwiped
  } = props;

  const calculateTableWidth = () => {
    const columnCount =
      breakpoints[currentBreakpoint] >= breakpoints.tablet ? 4 : 2;
    const nonPlanColumnCount =
      breakpoints[currentBreakpoint] >= breakpoints.tablet ? 2 : 1;

    const widthScale = (plans.length + nonPlanColumnCount) / columnCount;

    return {
      width: `calc(${widthScale * 100}%)`
    };
  };

  const direction = useScrollDirection();

  return (
    <Sticky innerZ={10}>
      {({ status }) => (
        <div
          className={
            status === Sticky.STATUS_FIXED ? 'd-flex bg-white shadow' : 'd-flex'
          }
        >
          <div className="container container-lg" style={{ marginBottom: -1 }}>
            {status === Sticky.STATUS_FIXED && (
              <div className="row py-lg-15">
                <div className="column column-12 column-lg-8">
                  <div className="heading-5 text-blue-primary d-none d-lg-block flex">
                    Comparing AARP® Medicare Supplement Insurance Plans insured
                    by UnitedHealthcare just got a little easier.
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="column column-12">
                <div className="position-relative">
                  <ControlButton
                    className="position-absolute"
                    disabled={offset === 0}
                    onClick={onPrevClick}
                    style={{
                      top: '30%',
                      left: currentBreakpoint === 'mobile' ? -15 : -25,
                      zIndex: 5
                    }}
                  />
                  <ControlButton
                    className="position-absolute"
                    direction="right"
                    disabled={
                      offset ===
                      plans.length -
                        (breakpoints[currentBreakpoint] < breakpoints.tablet
                          ? 1
                          : 2)
                    }
                    onClick={onNextClick}
                    style={{
                      top: '30%',
                      right: currentBreakpoint === 'mobile' ? -15 : -25,
                      zIndex: 5
                    }}
                  />
                  <div
                    className={
                      (direction === 'scrolling up' ? 'is-active ' : ' ') +
                      `plan-compare-checkbox plan-compare-checkbox__sticky`
                    }
                    data-banner-highlight-mobile
                  >
                    <Switch
                      checked={isHighlighting}
                      label="Highlight Differences"
                      onChange={onHighlightChange}
                    />
                  </div>
                  <SlideTable
                    offset={offset}
                    showNextPreview={false}
                    onSwiped={onSwiped}
                    style={calculateTableWidth()}
                    header={
                      <Fragment>
                        <SlideTable.Row>
                          <SlideTable.Cell
                            isFrozen={true}
                            isVCentered={false}
                            isHeader={true}
                            rowSpan={2}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%'
                              }}
                            >
                              <div
                                className="plan-compare-checkbox"
                                data-banner-highlight
                              >
                                <Switch
                                  checked={isHighlighting}
                                  label="Highlight Differences"
                                  onChange={onHighlightChange}
                                />
                              </div>

                              <div>
                                <div>Showing 3 of {plans.length} plans</div>
                                <div>
                                  <LinkButton className="mt-10" href="#link">
                                    Show all {plans.length} plans
                                  </LinkButton>
                                </div>
                              </div>
                            </div>
                          </SlideTable.Cell>
                          {plans.map((plan, index) => (
                            <SlideTable.Cell
                              className="text-blue-primary text-bold text-left bg-blue-primary text-white"
                              key={index}
                              isHeader={true}
                            >
                              <div className="d-flex align-items-start justify-content-between">
                                <div>
                                  <h2 className="text-lg-extra-large text-white text-serif text-bold">
                                    {plan.name}
                                  </h2>
                                  <div
                                    className={
                                      status === Sticky.STATUS_FIXED
                                        ? 'd-none'
                                        : 'text-regular mt-5'
                                    }
                                  >
                                    {plan.description}
                                  </div>
                                </div>
                                <LinkButton className="ml-15">
                                  <img
                                    className="d-block"
                                    src="/assets/icons/close-white-icon@2x.png"
                                    alt=""
                                    style={{
                                      height: 20,
                                      width: 20
                                    }}
                                  />
                                </LinkButton>
                              </div>
                            </SlideTable.Cell>
                          ))}
                          <SlideTable.Cell
                            isHCentered={true}
                            isVCentered={true}
                            isFrozen={false}
                            isHeader={true}
                            rowSpan="2"
                          >
                            <Button isOutlined={true} isSecondary={true}>
                              <span className="d-flex align-items-center">
                                <span>
                                  Add{' '}
                                  <span className="d-none d-lg-inline">
                                    Another Plan
                                  </span>
                                </span>
                                <img
                                  className="d-block mr-n10 ml-10"
                                  src="/assets/icons/full-icon-library-all-24-px-navigation-ic-expand-more@2x.png"
                                  alt=""
                                  style={{
                                    width: 24,
                                    height: 24
                                  }}
                                />
                              </span>
                            </Button>
                          </SlideTable.Cell>
                        </SlideTable.Row>
                        <SlideTable.Row className="d-table-row border-none">
                          {plans.map((plan, index) => (
                            <SlideTable.Cell
                              key={index}
                              className="text-left"
                              isHeader={true}
                            >
                              <div>
                                {/* NOT STICKY/FIXED */}
                                {status !== Sticky.STATUS_FIXED && (
                                  <Fragment>
                                    <div className="mb-20">
                                      {showInformationAdded && (
                                        <div>
                                          <div className="text-bold text-lg-regular">
                                            Your Estimated Monthly Premium:
                                          </div>
                                          <div className="text-bold heading-lg-2 text-dark">
                                            <span className="uhc-sup">$</span>
                                            {plan.rate}
                                          </div>
                                        </div>
                                      )}
                                      {!showInformationAdded && (
                                        <div style={{ textAlign: 'center' }}>
                                          <div className="text-regular">
                                            Monthly Premium
                                            <span className="d-none d-lg-inline">
                                              {' '}
                                              Starting at
                                            </span>
                                            :
                                          </div>
                                          <div className="text-bold heading-2 text-dark">
                                            <span className="uhc-sup">$</span>
                                            {plan.rate}
                                            <span className="uhc-sup text-sans-serif">
                                              <a
                                                className="no-underline"
                                                href="#link"
                                              >
                                                1
                                              </a>
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      <div className="d-none d-lg-block mt-10 text-small">
                                        <LinkButton className="text-small">
                                          <span className="d-flex align-items-center gap-5">
                                            <img
                                              src="/assets/icons/full-icon-library-all-24-px-content-ic-add-box@2x.png"
                                              alt=""
                                              style={{ width: 17, height: 17 }}
                                            />
                                            <span>
                                              {showInformationAdded
                                                ? 'Edit'
                                                : 'Add'}{' '}
                                              your information
                                            </span>
                                          </span>
                                        </LinkButton>
                                        <span style={{ verticalAlign: 4 }}>
                                          {' '}
                                          to update the estimated monthly
                                          premium.
                                        </span>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row align-items-center flex-wrap gap-10">
                                      <Button className="flex">
                                        <span className="d-inline d-lg-none">
                                          Apply
                                        </span>
                                        <span className="d-none d-lg-inline">
                                          Start Application
                                        </span>
                                      </Button>
                                      <Button
                                        className="flex"
                                        isOutlined={true}
                                        isSecondary={true}
                                      >
                                        <span className="d-flex align-items-center">
                                          <img
                                            className="d-block mr-lg-10 ml-lg-n5"
                                            src="/assets/icons/heart-icon-small@2x.png"
                                            alt=""
                                            style={{
                                              height: 16,
                                              width: 16
                                            }}
                                          />
                                          <span className="flex text-center">
                                            Save
                                          </span>
                                        </span>
                                      </Button>
                                    </div>
                                    <div className="mt-20 text-center text-lg-left">
                                      <LinkButton>View Plan Details</LinkButton>
                                      {plan.showEligibilityStatus && (
                                        <div className="d-none d-lg-block bg-teal-quaternary mt-20 p-15">
                                          {plan.name} is only available to those
                                          first eligible for Medicare before
                                          2020
                                        </div>
                                      )}
                                    </div>
                                  </Fragment>
                                )}

                                {/* STICKY/FIXED */}
                                {status === Sticky.STATUS_FIXED && (
                                  <Fragment>
                                    <div className="d-flex text-center justify-content-center flex-wrap gap-10">
                                      <div>
                                        <div
                                          className="text-bold text-dark text-serif"
                                          style={{ fontSize: '24px' }}
                                        >
                                          ${plan.rate}
                                          <sup
                                            className="ml-5"
                                            style={{
                                              fontSize: 10,
                                              top: '-1em',
                                              left: '0'
                                            }}
                                          >
                                            <a
                                              className="no-underline"
                                              href="#link"
                                            >
                                              1
                                            </a>
                                          </sup>
                                        </div>
                                        <div className="text-regular">
                                          Monthly Premium
                                        </div>
                                      </div>
                                    </div>
                                    <Button className="d-none d-lg-block width-100 mb-20">
                                      <span className="d-inline d-lg-none">
                                        Apply
                                      </span>
                                      <span className="d-none d-lg-inline">
                                        Start Application
                                      </span>
                                    </Button>
                                    <div className="d-none d-lg-flex align-items-center gap-20">
                                      <LinkButton>View Plan Details</LinkButton>
                                      <Button
                                        className="flex"
                                        isOutlined={true}
                                        isSecondary={true}
                                      >
                                        <span className="d-flex align-items-center">
                                          <img
                                            className="d-block mr-lg-10 ml-lg-n5"
                                            src="/assets/icons/heart-icon-small@2x.png"
                                            alt=""
                                            style={{
                                              height: 16,
                                              width: 16
                                            }}
                                          />
                                          <span className="flex text-center">
                                            Save
                                          </span>
                                        </span>
                                      </Button>
                                    </div>
                                  </Fragment>
                                )}
                              </div>
                            </SlideTable.Cell>
                          ))}
                        </SlideTable.Row>
                      </Fragment>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Sticky>
  );
};

export default withBreakpoints(MSPlanCompareStickyHeader);
