import './sam.scss';

import { useState, useRef } from 'react';
import PhoneModal from 'partials/phone-modal/phone-modal';
import useOutsideClick from 'partials/outside-detect/outside-detect';
import classNames from 'classnames';

const Sam = (props) => {
  const [buttonChatState, setButtonChatState] = useState(
    localStorage.getItem('sam1')
      ? JSON.parse(localStorage.getItem('sam1'))
      : true
  );
  const [buttonPhoneState, setButtonPhoneState] = useState(
    localStorage.getItem('sam1phone')
      ? JSON.parse(localStorage.getItem('sam1phone'))
      : true
  );
  const [isPhoneModalShown, setIsPhoneModalShown] = useState(false);
  const [isChatModalShown, setIsChatModalShown] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (isChatModalShown === false) {
      return;
    }

    setIsChatModalShown(false);
  });

  const handleButtonState = () => {
    localStorage.setItem('sam1', !buttonChatState);
    setButtonChatState(!buttonChatState);
  };

  const handleButtonPhoneState = () => {
    localStorage.setItem('sam1phone', !buttonPhoneState);
    setButtonPhoneState(!buttonPhoneState);
  };

  return (
    <div className="sam">
      <PhoneModal
        isShown={isPhoneModalShown}
        onCloseComplete={() => setIsPhoneModalShown(false)}
      />
      <div
        ref={ref}
        className={classNames('chat-modal', {
          isOpen: isChatModalShown,
          isClosed: !isChatModalShown
        })}
      >
        <img
          src="/assets/images/chat-modal.png"
          alt="Chat with us"
          style={{ maxWidth: '100%' }}
        />
      </div>
      <div
        className={classNames('samOne__contain samOne samOne--chat', {
          isOpen: buttonChatState,
          isClosed: !buttonChatState
        })}
      >
        <button className="samOne__toggle" onClick={handleButtonState}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.41 6L5 7.41L9.58 12L5 16.59L6.41 18L12.41 12L6.41 6Z"
              fill="white"
            />
            <path
              d="M13 6L11.59 7.41L16.17 12L11.59 16.59L13 18L19 12L13 6Z"
              fill="white"
            />
          </svg>
        </button>
        <button
          className="samOne__modal"
          data-type="chat"
          onClick={() => {
            setIsChatModalShown(true);
          }}
        >
          <span className="samOne__text">Chat now</span>
          <span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM5 7H15C15.55 7 16 7.45 16 8C16 8.55 15.55 9 15 9H5C4.45 9 4 8.55 4 8C4 7.45 4.45 7 5 7ZM11 12H5C4.45 12 4 11.55 4 11C4 10.45 4.45 10 5 10H11C11.55 10 12 10.45 12 11C12 11.55 11.55 12 11 12ZM15 6H5C4.45 6 4 5.55 4 5C4 4.45 4.45 4 5 4H15C15.55 4 16 4.45 16 5C16 5.55 15.55 6 15 6Z"
                fill="white"
              />
            </svg>
          </span>
        </button>
      </div>
      <div
        className={classNames('samOne__contain samOne samOne--phone', {
          isOpen: buttonPhoneState,
          isClosed: !buttonPhoneState
        })}
      >
        <button className="samOne__toggle" onClick={handleButtonPhoneState}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.41 6L5 7.41L9.58 12L5 16.59L6.41 18L12.41 12L6.41 6Z"
              fill="white"
            />
            <path
              d="M13 6L11.59 7.41L16.17 12L11.59 16.59L13 18L19 12L13 6Z"
              fill="white"
            />
          </svg>
        </button>
        <button
          className="samOne__modal"
          data-type="chat"
          onClick={() => {
            setIsPhoneModalShown(true);
          }}
        >
          <span className="samOne__text">1-855-888-1640</span>
          <span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.0265 12.7171L13.9098 12.4755C13.4015 12.4171 12.9015 12.5921 12.5432 12.9505L11.0098 14.4838C8.6515 13.2838 6.71817 11.3588 5.51817 8.99212L7.05983 7.45046C7.41817 7.09212 7.59317 6.59212 7.53483 6.08379L7.29317 3.98379C7.19317 3.14212 6.48483 2.50879 5.63483 2.50879H4.19317C3.2515 2.50879 2.46817 3.29212 2.5265 4.23379C2.96817 11.3505 8.65983 17.0338 15.7682 17.4755C16.7098 17.5338 17.4932 16.7505 17.4932 15.8088V14.3671C17.5015 13.5255 16.8682 12.8171 16.0265 12.7171Z"
                fill="white"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
};

export default Sam;
