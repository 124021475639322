const msPlans = [
  {
    name: 'Plan G',
    description: 'Comprehensive benefits plan plus wellness extras',
    summary:
      'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',
    rate: '110.96',
    sections: [
      {
        // Plan Summary
        id: 'plan-summary',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            (After $240 Annual Part B Deductible)
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $240
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            Yes
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            No
          </span>`,

          `<span class="hl-item">
            UnitedHealthcare Insurance Company (UnitedHealthcare)
          </span>`
        ]
      },
      {
        // Basic Costs
        id: 'basic-costs',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $110.96<sup>1</sup>
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $240
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $240
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            <span class="cell-covered">Covered</span>
          </span>`
        ]
      },
      {
        // Doctor Visits
        id: 'doctor-visits',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            No
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            Yes
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for Medicare covered services
          </div>`
        ]
      },
      {
        // Urgent/Emergency Care
        id: 'urgent-care',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            20%
          </span>
          <div class="text-regular mt-5">
            after $250 annual deductible with a $50,000 lifetime maximum
          </div>`
        ]
      },
      // Hospitalization and Skilled Nursing Facility Care
      {
        id: 'skilled-care',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for days 1-60
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for days 61-90
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            while using 60 lifetime reserve days for days 91 and later
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for an additional 365 days, after lifetime reserve days are used<sup>#</sup>
          </div>
          <br />
          <div class="text-regular mt-5">
            All costs beyond the additional 365 days
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for days 1-100
          </div>
          <br />
          <div class="text-regular mt-5">
            All costs beyond the additional 365 days
          </div>`
        ]
      },
      // Outpatient Care
      {
        id: 'outpatient-care',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`
        ]
      },
      {
        // Lab/X-Ray Services & Equipment
        id: 'lab-services',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`
        ]
      },
      {
        // Wellness Extras
        id: 'wellness-extras',
        values: [
          `
          <div class="text-regular mt-5 text-left">
            Discounts for dental services from in-network dentists through Dentegra
          </div>
          <br />
          <div class="text-regular mt-5 text-left">
            In-network discounts generally average 30-40%<sup>11</sup> off of contracted rates nationally for a range of dental services including:
          </div>
          <div class="compare-checklist-wrap">
            <ul class="compare-checklist">
              <li>Cleanings</li>
              <li>Exams</li>
              <li>Fillings</li>
              <li>Crowns</li>
            </ul>
          </div>`,

          `
          <div class="text-regular mt-5 text-left">
            Routine eye exams at participant providers: $50<sup>12</sup>
          </div>
          <br />
          <div class="text-regular mt-5 text-left">
            Take an additional $50 off the AARP Vision Discount or best instore offer on no-line progressive lenses with frame purchase at LensCrafters<sup>13</sup>
          </div>`,

          `
          <div class="text-regular mt-5 text-left">
            An additional $100 off the AARP member rate on select hearing aids
          </div>
          <br />
          <div class="text-regular mt-5 text-left">
            Plus a 15% discount on hearing aid accessories
          </div>`,

          `
          <div class="text-regular mt-5 text-left">
            A gym membership at no additional cost to you
          </div>`
        ]
      }
    ]
  },
  {
    name: 'Plan F',
    description:
      'The most comprehensive plan plus additional premium discounts',
    summary:
      'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',
    rate: '136.97',
    showEligibilityStatus: true,
    sections: [
      {
        // Plan Summary
        id: 'plan-summary',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            (After $240 Annual Part B Deductible)
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            Yes
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            No
          </span>`,

          `<span class="hl-item">
            UnitedHealthcare Insurance Company (UnitedHealthcare)
          </span>`
        ]
      },
      {
        // Basic Costs
        id: 'basic-costs',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $136.97<sup>1</sup>
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            <span class="cell-covered">Covered</span>
          </span>`
        ]
      },
      {
        // Doctor Visits
        id: 'doctor-visits',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            No
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            Yes
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for Medicare covered services
          </div>`
        ]
      },
      {
        // Urgent/Emergency Care
        id: 'urgent-care',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            20%
          </span>
          <div class="text-regular mt-5">
            after $250 annual deductible with a $50,000 lifetime maximum
          </div>`
        ]
      },
      // Hospitalization and Skilled Nursing Facility Care
      {
        id: 'skilled-care',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for days 1-60
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for days 61-90
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            while using 60 lifetime reserve days for days 91 and later
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for an additional 365 days, after lifetime reserve days are used<sup>#</sup>
          </div>
          <br />
          <div class="text-regular mt-5">
            All costs beyond the additional 365 days
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for days 1-100
          </div>
          <br />
          <div class="text-regular mt-5">
            All costs beyond the additional 365 days
          </div>`
        ]
      },
      // Outpatient Care
      {
        id: 'outpatient-care',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`
        ]
      },
      {
        // Lab/X-Ray Services & Equipment
        id: 'lab-services',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`
        ]
      },
      {
        // Wellness Extras
        id: 'wellness-extras',
        values: [
          `
          <div class="text-regular mt-5 text-left">
            Discounts for dental services from in-network dentists through Dentegra
          </div>
          <br />
          <div class="text-regular mt-5 text-left">
            In-network discounts generally average 30-40%<sup>11</sup> off of contracted rates nationally for a range of dental services including:
          </div>
          <div class="compare-checklist-wrap">
            <ul class="compare-checklist">
              <li>Cleanings</li>
              <li>Exams</li>
              <li>Fillings</li>
              <li>Crowns</li>
            </ul>
          </div>`,

          `
          <div class="text-regular mt-5 text-left">
            Routine eye exams at participant providers: $50<sup>12</sup>
          </div>
          <br />
          <div class="text-regular mt-5 text-left">
            Take an additional $50 off the AARP Vision Discount or best instore offer on no-line progressive lenses with frame purchase at LensCrafters<sup>13</sup>
          </div>`,

          `
          <div class="text-regular mt-5 text-left">
            An additional $100 off the AARP member rate on select hearing aids
          </div>
          <br />
          <div class="text-regular mt-5 text-left">
            Plus a 15% discount on hearing aid accessories
          </div>`,

          `
          <div class="text-regular mt-5 text-left">
            A gym membership at no additional cost to you
          </div>`
        ]
      }
    ]
  },
  {
    name: 'Plan N',
    description: 'copay plan plus wellness extras',
    summary:
      'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',
    rate: '92.09',
    showEligibilityStatus: false,
    sections: [
      {
        // Plan Summary
        id: 'plan-summary',
        values: [
          `<div class="text-regular mt-5">
            Up to
          </div>
          <span class="hl-item text-extra-large text-bold text-serif">
            $20
          </span>
          <div class="text-regular mt-10">
            copay
          </div>
          <div class="text-regular mt-5">
            (After $240 Annual Part B Deductible)
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            N/A
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            Yes
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            No
          </span>`,

          `<span class="hl-item">
            UnitedHealthcare Insurance Company (UnitedHealthcare)
          </span>`
        ]
      },
      {
        // Basic Costs
        id: 'basic-costs',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $92.09<sup>1</sup>
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $240
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            N/A<sup>4</sup>
          </span>`,

          '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>'
        ]
      },
      {
        // Doctor Visits
        id: 'doctor-visits',
        values: [
          `<div class="text-regular mt-5">
            Up to
          </div>
          <span class="hl-item text-extra-large text-bold text-serif">
            $20
          </span>
          <div class="text-regular mt-10">
            copay
          </div>`,

          `<div class="text-regular mt-5">
            Up to
          </div>
          <span class="hl-item text-extra-large text-bold text-serif">
            $20
          </span>
          <div class="text-regular mt-10">
            copay
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            No
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            Yes
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for Medicare covered services
          </div>`
        ]
      },
      {
        // Urgent/Emergency Care
        id: 'urgent-care',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<div class="text-regular mt-5">
            Up to
          </div>
          <span class="hl-item text-extra-large text-bold text-serif">
            $50
          </span>
          <div class="text-regular mt-10">
            copay
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            20%
          </span>
          <div class="text-regular mt-5">
            after $250 annual deductible with a $50,000 lifetime maximum
          </div>`
        ]
      },
      // Hospitalization and Skilled Nursing Facility Care
      {
        id: 'skilled-care',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for days 1-60
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for days 61-90
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            while using 60 lifetime reserve days for days 91 and later
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for an additional 365 days, after lifetime reserve days are used<sup>#</sup>
          </div>
          <br />
          <div class="text-regular mt-5">
            All costs beyond the additional 365 days
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for days 1-100
          </div>
          <br />
          <div class="text-regular mt-5">
            All costs beyond the additional 365 days
          </div>`
        ]
      },
      // Outpatient Care
      {
        id: 'outpatient-care',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`
        ]
      },
      {
        // Lab/X-Ray Services & Equipment
        id: 'lab-services',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`
        ]
      },
      {
        // Wellness Extras
        id: 'wellness-extras',
        values: [
          `
          <div class="text-regular mt-5 text-left">
            Discounts for dental services from in-network dentists through Dentegra
          </div>
          <br />
          <div class="text-regular mt-5 text-left">
            In-network discounts generally average 30-40%<sup>11</sup> off of contracted rates nationally for a range of dental services including:
          </div>
          <div class="compare-checklist-wrap">
            <ul class="compare-checklist">
              <li>Cleanings</li>
              <li>Exams</li>
              <li>Fillings</li>
              <li>Crowns</li>
            </ul>
          </div>`,

          `
          <div class="text-regular mt-5 text-left">
            Routine eye exams at participant providers: $50<sup>12</sup>
          </div>
          <br />
          <div class="text-regular mt-5 text-left">
            Take an additional $50 off the AARP Vision Discount or best instore offer on no-line progressive lenses with frame purchase at LensCrafters<sup>13</sup>
          </div>`,

          `
          <div class="text-regular mt-5 text-left">
            An additional $100 off the AARP member rate on select hearing aids
          </div>
          <br />
          <div class="text-regular mt-5 text-left">
            Plus a 15% discount on hearing aid accessories
          </div>`,

          `
          <div class="text-regular mt-5 text-left">
            A gym membership at no additional cost to you
          </div>`
        ]
      }
    ]
  },
  {
    name: 'Plan K',
    description: 'Budget-conscious 50% benefits plan plus wellness extras',
    summary:
      'This HMO plan gives you access to a local network of doctors, including a primary care provider to coordinate your care.',
    rate: '42.23',
    showEligibilityStatus: false,
    sections: [
      {
        // Plan Summary
        id: 'plan-summary',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>
          <div class="text-regular mt-5">
            (After $240 Annual Part B Deductible)
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $816
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $7,060
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            Yes
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            No
          </span>`,

          `<span class="hl-item">
            UnitedHealthcare Insurance Company (UnitedHealthcare)
          </span>`
        ]
      },
      {
        // Basic Costs
        id: 'basic-costs',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $42.23<sup>1</sup>
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $816
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $240
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $7,060<sup>5</sup>
          </span>`,

          '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>'
        ]
      },
      {
        // Doctor Visits
        id: 'doctor-visits',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            No
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            Yes
          </span>`,

          `<div class="hl-item text-regular mt-5">
            All costs above Medicare approved amounts
          </div>`
        ]
      },
      {
        // Urgent/Emergency Care
        id: 'urgent-care',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>`,

          '<span class="hl-item text-extra-large text-bold text-serif"><span class="cell-not-covered">Not covered</span></span>'
        ]
      },
      // Hospitalization and Skilled Nursing Facility Care
      {
        id: 'skilled-care',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $816
          </span>
          <div class="text-regular mt-5">
            for days 1-60
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for days 61-90
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            while using 60 lifetime reserve days for days 91 and later
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for an additional 365 days, after lifetime reserve days are used<sup>#</sup>
          </div>
          <br />
          <div class="text-regular mt-5">
            All costs beyond the additional 365 days
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>
          <div class="text-regular mt-5">
            for days 1-20
          </div>
          <br />
          <span class="hl-item text-extra-large text-bold text-serif">
            $102
          </span>
          <div class="text-regular mt-5">
            up to per day for days 21-100
          </div>
          <br />
          <div class="text-regular mt-5">
            All costs for days 101 and later
          </div>`
        ]
      },
      // Outpatient Care
      {
        id: 'outpatient-care',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>`
        ]
      },
      {
        // Lab/X-Ray Services & Equipment
        id: 'lab-services',
        values: [
          `<span class="hl-item text-extra-large text-bold text-serif">
            $0
          </span>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>`,

          `<span class="hl-item text-extra-large text-bold text-serif">
            10%
          </span>
          <div class="text-regular mt-10">
            coinsurance
          </div>`
        ]
      },
      {
        // Wellness Extras
        id: 'wellness-extras',
        values: [
          `
          <div class="text-regular mt-5 text-left">
            Discounts for dental services from in-network dentists through Dentegra
          </div>
          <br />
          <div class="text-regular mt-5 text-left">
            In-network discounts generally average 30-40%<sup>11</sup> off of contracted rates nationally for a range of dental services including:
          </div>
          <div class="compare-checklist-wrap">
            <ul class="compare-checklist">
              <li>Cleanings</li>
              <li>Exams</li>
              <li>Fillings</li>
              <li>Crowns</li>
            </ul>
          </div>`,

          `
          <div class="text-regular mt-5 text-left">
            Routine eye exams at participant providers: $50<sup>12</sup>
          </div>
          <br />
          <div class="text-regular mt-5 text-left">
            Take an additional $50 off the AARP Vision Discount or best instore offer on no-line progressive lenses with frame purchase at LensCrafters<sup>13</sup>
          </div>`,

          `
          <div class="text-regular mt-5 text-left">
            An additional $100 off the AARP member rate on select hearing aids
          </div>
          <br />
          <div class="text-regular mt-5 text-left">
            Plus a 15% discount on hearing aid accessories
          </div>`,

          `
          <div class="text-regular mt-5 text-left">
            A gym membership at no additional cost to you
          </div>`
        ]
      }
    ]
  }
];

export default msPlans;
