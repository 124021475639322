import { Tile } from '@bswing/uhc-core';
import classNames from 'classnames';
import './layered-tile.scss';

const LayeredTile = ({
  children,
  className,
  id,
  imageSrc,
  position = 'top-left'
}) => {
  const componentClassName = classNames(
    'uhc-layered-tile',
    {
      'uhc-layered-tile--top-left': position === 'top-left',
      'uhc-layered-tile--top-right': position === 'top-right',
      'uhc-layered-tile--bottom-left': position === 'bottom-left',
      'uhc-layered-tile--bottom-right': position === 'bottom-right'
    },
    className
  );

  return (
    <div className={componentClassName}>
      <Tile id={id} imageSrc={imageSrc} showImage={true} />
      <div className="uhc-layered-tile__inner">{children}</div>
    </div>
  );
};

export default LayeredTile;
