import { Button, LinkButton } from '@bswing/uhc-core';
import ApplicationCard from 'components/application-card/application-card';
import EmptyState from 'components/empty-state/empty-state';
import MSPlanCard from 'components/ms-plan-card/ms-plan-card';
import PlanCard from 'components/plan-card/plan-card';
import RecommendedPlanCard from 'components/recommended-plan-card/recommended-plan-card';
import PlanYearToggle from 'partials/plan-year-toggle/plan-year-toggle';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ShopperProfileFAQs from '../components/faqs';
import ShopperProfileHelpCTA from '../components/help-cta';
import ShopperProfilePreCTA from '../components/pre-cta';
import { savedPlansFaqData } from '../data/faq-data';
import ShopperProfileImportantInformation from '../partials/important-information';

const ShopperProfileSavedPlansPage = ({
  applications = [],
  recommendedPlan,
  savedDoctors = [],
  savedDrugs = [],
  savedPlans = [],
  showPlanYearToggle = false,
  showServiceOutage = false,
  showWarmWelcome = false,
  onAddDoctorsClick = () => {},
  onAddDrugsClick = () => {},
  onPlanSaveToggle = () => {}
}) => {
  return (
    <Fragment>
      {/* Service Outage */}
      {showServiceOutage && (
        <div className="d-flex align-items-start gap-20 bg-yellow-secondary mb-30 p-20">
          <img
            src="/assets/icons/warning-icon@2x.png"
            alt=""
            height="24"
            width="24"
          />
          <div>
            <p className="text-bold mb-10">Service outage</p>
            <p className="mb-20">
              HealthcareSafe ID will be unavailable from 5 p.m. CST Friday to 8
              a.m. CST Saturday for planned maintenance. If you have any
              questions or need assistance during that time, please call
              1-800-123-4567. Thank you for your patience.
            </p>
            <LinkButton>
              <span className="d-flex align-items-center gap-10">
                <span>Close</span>
                <img
                  src="/assets/icons/chevron-right-icon@2x.png"
                  alt=""
                  style={{ width: 8, height: 12 }}
                />
              </span>
            </LinkButton>
          </div>
        </div>
      )}

      {/* Telesales Warm Welcome */}
      {showWarmWelcome && (
        <div className="d-flex align-items-start border-rounded-sm gap-20 bg-gray-1 mb-30 p-30">
          <img
            className="d-none d-lg-block mt-lg-5"
            src="/assets/icons/check-circle@2x.png"
            alt=""
            height="20"
            width="20"
          />
          <div>
            <p className="heading-2 mb-10">Your account is set up</p>
            <ul className="mb-30">
              <li className="mb-10">
                Your saved plans, drugs and doctors have been added to your
                account
              </li>
              <li>View coverage and cost estimates when browsing for plans</li>
            </ul>
            <div className="d-flex align-items-center gap-20 flex-wrap">
              <Button isOutlined={true} isSecondary={true}>
                Browse all plans in your area
              </Button>
              <LinkButton>
                <span className="d-flex align-items-center gap-10">
                  <span>View your saved plans</span>
                  <img
                    src="/assets/icons/chevron-right-icon@2x.png"
                    alt=""
                    style={{ width: 8, height: 12 }}
                  />
                </span>
              </LinkButton>
            </div>
          </div>
        </div>
      )}

      {/* START Applications Section */}
      {applications.length > 0 && (
        <div className="border-bottom mb-30 pb-30">
          <div className="mb-30">
            <h2 className="mb-10">Your applications</h2>
            <p>
              Review your list of applications. If you are still working on
              completing your application, it will appear here. Once you have
              submitted your application, you will also be able to track its
              status.
            </p>
          </div>

          {applications.length > 0 && (
            <div>
              <div className="d-flex flex-column gap-30">
                {applications.map((application, index) => (
                  <ApplicationCard data={application} key={index} />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      {/* END Applications Section */}

      {/* START Recommended Plans Section */}
      {recommendedPlan && (
        <div className="border-bottom mb-30 pb-30">
          <div className="mb-30">
            <h2 className="mb-10">Your recommended plans</h2>
            <p>This plan recommendation is based on your personalized needs.</p>
          </div>
          <div className="d-flex flex-column flex-lg-row flex-lg-wrap align-items-lg-center gap-30 gap-lg-40">
            <div className="flex">
              <RecommendedPlanCard data={recommendedPlan} />
            </div>
            <div className="flex">
              <img
                className="d-none d-lg-block mb-lg-10"
                src="/assets/images/ic_Star_2C_onBlue@2x.png"
                alt=""
                style={{ width: 39, height: 39 }}
              />
              <h3 className="heading-2 mb-10">
                More recommended plans available
              </h3>
              <p className="mb-20">
                Since you have completed the plan recommendation questions, we
                have 5 ranked results for you based on your specific needs.
              </p>
              <Button isOutlined={true} isSecondary={true}>
                View all of your results
              </Button>
            </div>
          </div>
        </div>
      )}
      {/* END Recommended Plans Section */}

      {/* START Saved Plans Section */}
      <div className="mb-30">
        <h2 className="mb-10">Your saved plans</h2>
        <p>
          This is your list of plans to compare based on what's most important
          to you. You can add and remove plans as needed.
        </p>
      </div>
      {showPlanYearToggle && (
        <div className="mb-30">
          <PlanYearToggle />
        </div>
      )}

      {/* Populated State */}
      {savedPlans.length > 0 && (
        <Fragment>
          {/* MA Plans */}
          {savedPlans.filter((x) => x.type === 'MA').length > 0 && (
            <div className="mb-40">
              <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-10 gap-lg-20 mb-20">
                <h3 className="heading-3 text-gray-8">
                  Medicare Advantage plans (
                  {savedPlans.filter((x) => x.type === 'MA').length})
                </h3>
                <LinkButton>
                  <span className="d-flex align-items-center gap-10">
                    <span>Compare plans</span>
                    <img
                      src="/assets/icons/chevron-right-icon@2x.png"
                      alt=""
                      style={{ width: 8, height: 12 }}
                    />
                  </span>
                </LinkButton>
              </div>
              <div className="d-flex flex-column gap-30">
                {savedPlans
                  .filter((x) => x.type === 'MA')
                  .map((plan, index) => (
                    <PlanCard
                      cardNumber={index + 1}
                      cardTotal={
                        savedPlans.filter((x) => x.type === 'MA').length
                      }
                      key={index}
                      isExpanded={true}
                      isSaved={true}
                      planData={plan}
                      showCompare={true}
                      showCompareCheckbox={false}
                      showRemove={true}
                      savedDoctors={savedDoctors}
                      savedDrugs={savedDrugs}
                      onAddDoctorsClick={onAddDoctorsClick}
                      onAddDrugsClick={onAddDrugsClick}
                      onRemove={(event) => onPlanSaveToggle(event, false, plan)}
                      onSave={onPlanSaveToggle}
                    />
                  ))}
              </div>
            </div>
          )}
          {/* MS Plans */}
          {savedPlans.filter((x) => x.type === 'MS').length > 0 && (
            <Fragment>
              <div className="mb-40">
                <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-10 gap-lg-20 mb-20">
                  <h3 className="heading-3 text-gray-8">
                    Medicare Supplement Plans (
                    {savedPlans.filter((x) => x.type === 'MS').length})
                  </h3>
                  <LinkButton>
                    <span className="d-flex align-items-center gap-10">
                      <span>Compare plans</span>
                      <img
                        src="/assets/icons/chevron-right-icon@2x.png"
                        alt=""
                        style={{ width: 8, height: 12 }}
                      />
                    </span>
                  </LinkButton>
                </div>
                <div className="d-flex flex-column gap-30">
                  {savedPlans
                    .filter((x) => x.type === 'MS')
                    .map((plan, index) => (
                      <MSPlanCard
                        key={index}
                        isSaved={true}
                        planData={plan}
                        showCompare={true}
                        showCompareCheckbox={false}
                        showRemove={true}
                        showRestrictions={false}
                        onRemove={(event) =>
                          onPlanSaveToggle(event, false, plan)
                        }
                        onSave={onPlanSaveToggle}
                      />
                    ))}
                </div>
              </div>
              <ShopperProfileImportantInformation />
            </Fragment>
          )}
        </Fragment>
      )}

      {/* Empty State */}
      {savedPlans.length === 0 && (
        <Fragment>
          <div className="border-bottom mb-30 pb-30">
            <EmptyState>
              <svg
                className="d-block mb-10"
                width="48"
                height="49"
                viewBox="0 0 48 49"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden={true}
                focusable={false}
              >
                <circle cx="24" cy="24.5" r="24" fill="white" />
                <path
                  d="M36.9057 15.1968C35.4266 13.7177 33.4205 12.8867 31.3287 12.8867C29.2369 12.8867 27.2308 13.7177 25.7517 15.1968L12.8467 28.1028L23.9997 39.2518L36.8997 26.3518C39.9816 23.2731 39.9843 18.2789 36.9057 15.1968Z"
                  fill="#002677"
                />
                <path
                  d="M22.2472 15.1967C20.7683 13.7177 18.7623 12.8867 16.6707 12.8867C14.5791 12.8867 12.5731 13.7177 11.0942 15.1967C9.61512 16.6757 8.78418 18.6816 8.78418 20.7732C8.78418 22.8649 9.61512 24.8708 11.0942 26.3497L12.8472 28.1027L24.0002 16.9497L22.2472 15.1967Z"
                  fill="#00BED5"
                />
              </svg>
              <p className="mb-20">
                Looks like you don't have any saved plans yet
              </p>
              <Link
                to="/plan-summary"
                className="uhc-button uhc-button--secondary"
              >
                Browse all plans
              </Link>
            </EmptyState>
          </div>

          <ShopperProfilePreCTA />
        </Fragment>
      )}

      <ShopperProfileHelpCTA />
      <ShopperProfileFAQs faqs={savedPlansFaqData} />
    </Fragment>
  );
};

export default ShopperProfileSavedPlansPage;
