const PlanDetailBenefits = (props) => {
  const { data } = props;

  return (
    <div className="container container-lg">
      <div className="row mb-40 mb-lg-60">
        <div className="column column-12 column-lg-6 pt-40">
          <div className="heading-2 text-dark">Discover the Benefits</div>
          <div>
            {data?.benefits.map((benefit, index) => (
              <div className="d-flex align-items-start mt-30" key={index}>
                <div className="mr-20">
                  <img src={benefit.image} alt="" width="48" height="48" />
                </div>
                <div className="text-lg-large">
                  <div className="text-bold mb-10">{benefit.name}</div>
                  <div>{benefit.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="column column-12 column-lg-6">
          <img
            className="mt-30 mt-lg-n100 width-full"
            src="/assets/images/plan-details-benefits-placeholder@2x.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default PlanDetailBenefits;
