import { LinkButton } from '@bswing/uhc-core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './sp-banner.scss';

const SPBanner = ({ className, isAuthenticated = false }) => {
  const componentClassName = classNames('uhc-sp-banner', {}, className);

  return (
    <div className={componentClassName}>
      <div className="uhc-sp-banner__left">
        <img
          className="uhc-sp-banner__icon"
          src="/assets/images/ic_Heart_2C_onBlue@2x.png"
          alt=""
        />
        {!isAuthenticated && (
          <div>
            <h1 className="mb-lg-10">Your guest account</h1>
            <div className="d-none d-lg-block mb-10">
              Keep track of your plans, drugs, doctors and more. Enter your
              information once and view personalized coverage options as your
              browse for plans.
            </div>
          </div>
        )}
        {isAuthenticated && (
          <div>
            <div className="heading-3">Your account</div>
            <h1 className="display-large mb-lg-10">Welcome back</h1>
            <div className="d-none d-lg-block mb-10">
              Keep track of your plans, drugs, doctors and more. Enter your
              information once and view personalized coverage options as your
              browse for plans.
            </div>
          </div>
        )}
      </div>
      {!isAuthenticated && (
        <div className="uhc-sp-banner__right">
          <div className="d-flex align-items-center gap-10 heading-3 mb-10">
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden={true}
              focusable={false}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.323 0C4.80301 0 0.333008 4.48 0.333008 10C0.333008 15.52 4.80301 20 10.323 20C15.853 20 20.333 15.52 20.333 10C20.333 4.48 15.853 0 10.323 0ZM10.333 18C5.91301 18 2.33301 14.42 2.33301 10C2.33301 5.58 5.91301 2 10.333 2C14.753 2 18.333 5.58 18.333 10C18.333 14.42 14.753 18 10.333 18ZM10.833 5H9.33301V11L14.583 14.15L15.333 12.92L10.833 10.25V5Z"
                fill="#002677"
              />
            </svg>
            <span>Save your progress for future visits</span>
          </div>
          <div className="mb-10">
            Save yourself time in the future by creating an account now. The
            information you save to your account will be here for your next
            visit.
          </div>
          <div className="d-flex align-items-center gap-20">
            <a
              className="uhc-button uhc-button--secondary uhc-button--outlined"
              href="https://marvelapp.com/prototype/76b08c7/screen/91038397"
            >
              Create an account
            </a>
            <LinkButton href="https://marvelapp.com/prototype/76b08c7/screen/91038397">
              <span className="d-flex align-items-center gap-10">
                <span>Sign in</span>
                <img
                  src="/assets/icons/chevron-right-icon@2x.png"
                  alt=""
                  style={{ width: 8, height: 12 }}
                />
              </span>
            </LinkButton>
          </div>
        </div>
      )}
      {isAuthenticated && (
        <div className="uhc-sp-banner__right">
          <div className="d-flex align-items-center gap-10 heading-3 mb-10">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden={true}
              focusable={false}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 3.5C11.66 3.5 13 4.84 13 6.5C13 8.16 11.66 9.5 10 9.5C8.34 9.5 7 8.16 7 6.5C7 4.84 8.34 3.5 10 3.5ZM10 17.7C7.5 17.7 5.29 16.42 4 14.48C4.03 12.49 8 11.4 10 11.4C11.99 11.4 15.97 12.49 16 14.48C14.71 16.42 12.5 17.7 10 17.7Z"
                fill="#002677"
              />
            </svg>
            <span>Manage your account information</span>
          </div>
          <div className="mb-10">
            Manage your username, password, email address and all other personal
            information.
          </div>
          <div className="d-flex align-items-center gap-20">
            <a
              className="uhc-button uhc-button--secondary uhc-button--outlined"
              href="https://marvelapp.com/prototype/76b08c7/screen/91038397"
            >
              Manage account
            </a>
            <LinkButton as={Link} to="/auth/logout">
              <span className="d-flex align-items-center gap-10">
                <span>Sign out</span>
                <img
                  src="/assets/icons/chevron-right-icon@2x.png"
                  alt=""
                  style={{ width: 8, height: 12 }}
                />
              </span>
            </LinkButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default SPBanner;
